// Props for SVG components 
// We have a default theme if properties are not defined so everything is optional
export interface SVGPropsInterface {
    height? : string,
    width? : string, 
    fill? :string,
    stroke? :string
  }

// Return the default theme used if properties in SVG are not defined
export function SVGStyles() {
    const defaultTheme = {
        stroke : "#C4D9DB",
        fill : "#C4D9DB",
        height : "170",
        width : "160"
      };
    return defaultTheme;
}
