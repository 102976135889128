import {AppState} from 'redux/rootReducer';
import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getService} from 'api';
import {baseUrl} from 'api/config';
import {ErrorType} from 'types';
import {handleError} from '../../utils/httpErrorCodes';
import {PushNotificationsInfos, UserProfile} from "types/userProfile";

interface FetchProfileUserDataParams {
  token: string
}

export const FetchProfileUserData: AsyncThunk<UserProfile,
  FetchProfileUserDataParams,
  { rejectValue: ErrorType }> = createAsyncThunk('user/getProfileData',
  async ({token}: FetchProfileUserDataParams, {rejectWithValue}) => {
    try {
      const url = `user/getProfileData`;
      return await getService(token, url);
    } catch (error) {
      return rejectWithValue(handleError(error))
    }
  }
)

interface UserProfileState {
  profileData: UserProfile | null,
  isLoading: boolean,
  error: ErrorType | null
}

const initialState: UserProfileState = {
  profileData: null,
  isLoading: false,
  error: null
}

const userProfileSlice = createSlice({
  name: 'userProfile',
  initialState,
  reducers: {
    setProfileState(state, {payload}: PayloadAction<UserProfile>) {
      state.profileData = payload;
    },
    sendOptedInState: {
      reducer(state, {payload}: PayloadAction<{}>) {
        state.isLoading = true
      },
      prepare(token: string, optedInState: PushNotificationsInfos) {
        return {
          payload: optedInState,
          meta: {
            offline: {
              effect: {
                url: `${baseUrl}/user/updateOptedIn`,
                method: 'PUT',
                body: JSON.stringify(optedInState),
                headers: {
                  "Authorization": `Bearer ${token}`
                }
              },
            }
          }
        }
      }
    },
  },
  extraReducers: {
    [FetchProfileUserData.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [FetchProfileUserData.fulfilled.type]: (state, {payload}: PayloadAction<UserProfile>) => {
      state.profileData = payload
      state.isLoading = false
      state.error = null
    },
    [FetchProfileUserData.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    }
  }
})

export const {setProfileState, sendOptedInState} = userProfileSlice.actions

export default userProfileSlice.reducer

export const selectIsLoading = (state: AppState): boolean => state.userProfile.isLoading

export const selectError = (state: AppState): ErrorType | null => {
  const {error} = state.userProfile
  return error
}

export const getProfileState = (state: AppState) => state.userProfile.profileData