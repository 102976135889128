import {
  GoogleMap,
  Marker,
  useJsApiLoader
} from "@react-google-maps/api";
import { selectLanguageObject } from "pages/Login/LoginSlice";
import {useMemo} from "react";
import { useSelector } from "react-redux";
import useStyles from './Map.styles'
import { selectOnlineState } from "pages/Impact/Impact.slice";

export interface MapProps {
  gpsValues: number[]
}

const Map = (props:MapProps) => {
  const language: any = useSelector(selectLanguageObject);
  const isOnline = useSelector(selectOnlineState)

  const classes = useStyles()

  const googleApiKey = useMemo(
    () => process.env.REACT_APP_GOOGLE_MAPS_API_KEY as string,
    []
  );

  const containerStyle = {
    width: "100%",
    height: "200px",
    borderRadius: "0.5rem",
    borderWidth: "2px",
    borderColor:"#388087",
    borderStyle: "solid",
    marginTop:"10px"
  };

  const center = {
    lat: props.gpsValues[0],
    lng: props.gpsValues[1]
  }; 

  // Ensure the map is static
  const mapOptions = {
    disableDefaultUI: true,
    disableDoubleClickZoom:true,
    draggable:false
  }

  // Load the map
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: googleApiKey
  })

  return isOnline ?
  isLoaded ? 
  (
    <div>
      <GoogleMap
        mapContainerClassName="map-container"
        zoom={11}
        center={center}
        id="Map"
        mapContainerStyle={containerStyle}
        options={mapOptions}>
        <Marker position={center} />
        </GoogleMap>
    </div>
  ) : <></>
 : <p className={classes.text}> {language.mapError} </p> 
}

export default Map
