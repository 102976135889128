import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        doneButton: {
            width: "15.625rem"
        },
        title: {
            flex: 1
        },
        header:{
            display: "flex",
            alignItems: "center"
        },
        button: {
            background: theme.palette.secondary.contrastText,
            color: theme.palette.primary.main
        },
    })
})