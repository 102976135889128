import { Dialog as MUIDialog } from "@material-ui/core"
import { Close as CloseIcon } from "@material-ui/icons"
import IconButton from "components/IconButton"
import React from "react"
import clsx from "clsx"
import useStyles from "./Dialog_Styles"

export interface DialogProps {
    children: React.ReactNode
    open: boolean
    onClose: () => void
    className?: string
}



const Dialog: React.FC<DialogProps> = (props) => {
    const { children, open, onClose, className } = props
    const classes = useStyles()
    return (
        <MUIDialog open={open}
            className={classes.dialog}
            classes={{ paper: clsx(classes.paper, className) }}>
            <div className={classes.content}>
                {children}
            </div>
            <IconButton className={classes.iconButton} onClick={onClose}>
                <CloseIcon color={"secondary"} />
            </IconButton>
        </MUIDialog>
    )
}

export default Dialog