import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            background: theme.palette.secondary.contrastText
        },
        H1:{
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.contrastText,
            fontSize: "1.6875rem",
           // minHeight: '2.5rem',
           marginTop: '0.5rem',
        },
        sectionTitle:{
            width: "100%",
            marginTop: '0',
            padding: '5px 20px 10px',
            background: theme.palette.primary.main,
            borderRadius:  "0 0 30px 30px",
            boxSizing: "border-box",
            '&::first-letter': {
                fontSize: "140%",
                fontWeight: "bolder"
            },
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)"
        },
        button: {
            background: theme.palette.secondary.contrastText,
            color: theme.palette.primary.main,
            
        },
        buttonNext: {
            background: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText ,
            
        },
        smallH1:{
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.secondary.contrastText,
            fontSize: "1.2rem",
     //       minHeight: '2.5rem',
            marginTop: '0.5rem',
        },
        smallTitle:{
            width: "100%",
            marginTop: '0',
            padding: '5px 20px 10px',
            background: theme.palette.primary.main,
            borderRadius:  "0 0 30px 30px",
            boxSizing: "border-box",
            '&::first-letter': {
                fontSize: "140%",
                fontWeight: "bolder"
            }
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        title: {
            color: theme.palette.primary.main,
            
        },
        index: {
            color: theme.palette.secondary.dark,
            marginRight: '0.625rem'
        },
        counter: {
            display: "flex",
            alignItems: "center"
        },
        content:{
            alignItems: "flex-start",
            padding :0,
            width: "100%",
            "&>*:last-child": {
                marginTop: 0,
                width: "100%"
            }
        }
    })
})