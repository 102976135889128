import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {getService} from 'api';
import {baseUrl} from 'api/config';
import {AppState} from 'redux/rootReducer';
import {handleError} from '../../utils/httpErrorCodes';
import {AppState as OfflineAppState} from "@redux-offline/redux-offline/lib/types";
import {Collect, ErrorType, Survey, SurveyContent, Surveys, Value} from 'types';

interface FetchSurveysParams {
  userId: string
  token: string
}

export const fetchSurveysByUser: AsyncThunk<Surveys,
  FetchSurveysParams,
  { rejectValue: ErrorType }> = createAsyncThunk(
  'impact/fetchSurveysByUser',
  async ({userId, token}: FetchSurveysParams, {rejectWithValue}) => {
    try {
      const url = `collect/getSurveysByUser?user-id=${userId}`
      const fetchedSurveys: Surveys = await getService(token, url)
      return fetchedSurveys
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface FetchSurveyByCollectParams {
  token: string,
  collectId: string
}

export const fetchSurveyByCollect: AsyncThunk<Collect,
  FetchSurveyByCollectParams,
  { rejectValue: ErrorType }> = createAsyncThunk(
  'impact/fetchSurveyByCollect',
  async ({token, collectId}: FetchSurveyByCollectParams, {rejectWithValue}) => {
    try {
      const url = `collect/getSurveyByCollect?collect=${collectId}`
      const fetchedSurvey: Survey = await getService(token, url)
      return {
        collect: collectId,
        survey: fetchedSurvey
      }
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface CurrentQuestionPayload {
  currentQuestionId?: string
  collectId: string
}

interface AnsweredQuestionIdsPayload {
  questionId: string
  collectId: string
}

interface NetworkError {
  name: string,
  response: {
    message: string
  },
  status: number
}

export interface AnswerValue {
  questionId: string
  value: Value
  inputDate: string
}

export interface SurveyState extends Collect {
  answeredQuestionIds: Array<string>
  currentQuestionId?: string
  answerValues: Array<AnswerValue>
  submitted: boolean
  submissionDate: string
  pending: boolean
}

interface AnswerValuePayload {
  answerValues: Array<AnswerValue>
  collectId: string
}

interface ImpactState {
  surveys: {
    [byCollect: string]: SurveyState,
  },
  loading: boolean
  error: ErrorType | null
  showSuccessInfo: boolean
}

const initialState: ImpactState = {
  surveys: {},
  loading: false,
  error: null,
  showSuccessInfo: false
}

const impactSlice = createSlice({
  name: 'impact',
  initialState,
  reducers: {
    setCurrentQuestion(state, {payload}: PayloadAction<CurrentQuestionPayload>) {
      const {collectId, currentQuestionId} = payload
      state.surveys[collectId].currentQuestionId = currentQuestionId
    },
    addAnsweredQuestionId(state, {payload}: PayloadAction<AnsweredQuestionIdsPayload>) {
      const {questionId, collectId} = payload
      const surveyToUpdate = state.surveys[collectId]
      const answeredQuestionIds = surveyToUpdate.answeredQuestionIds || []
      const hasId = answeredQuestionIds.includes(questionId.toString())
      state.surveys[collectId].answeredQuestionIds = hasId
        ? answeredQuestionIds
        : answeredQuestionIds.concat(questionId.toString())
    },
    removeAnsweredQuestionId(state, {payload}: PayloadAction<AnsweredQuestionIdsPayload>) {
      const {questionId, collectId} = payload
      state.surveys[collectId].answeredQuestionIds = state
        .surveys[collectId].answeredQuestionIds
        .filter(id => id !== questionId)
    },
    setAnswerValues(state, {payload}: PayloadAction<AnswerValuePayload>) {
      const {collectId, answerValues} = payload
      state.surveys[collectId].answerValues = answerValues
    },
    sendSurveyResults: {
      reducer(state, {payload}: PayloadAction<string>) {
        state.surveys[payload].pending = true
      },
      prepare(token: string, surveyResults: SurveyContent) {
        return {
          payload: surveyResults.collect,
          meta: {
            offline: {
              // the network action to execute:
              effect: {
                url: `${baseUrl}/collect/postSurveyResults`,
                method: 'POST',
                body: JSON.stringify(surveyResults),
                headers: {
                  "Authorization": `Bearer ${token}`
                }
              },
              // action to dispatch when effect succeeds:
              commit: {
                type: 'impact/addSurveyResults',
                meta: {collectId: surveyResults.collect}
              },
              // action to dispatch if network action fails permanently:
              rollback: {
                type: 'impact/rollbackResults'
              },
            }
          }
        }
      }
    },
    addSurveyResults(state, action: any) {
      const collectId = action.meta.collectId
      state.loading = false
      state.surveys[collectId].pending = false
      state.surveys[collectId].submitted = true
      state.surveys[collectId].submissionDate = new Date().toLocaleString()
      state.error = null
      state.showSuccessInfo = true
    },
    rollbackResults(state, {payload}: PayloadAction<NetworkError>) {
      const message = payload.status >= 500
        ? "Error: Failed to Send Survey!"
        : payload.response.message
      state.loading = false
      state.error = {
        httpStatus: payload.status,
        payload: {
          message
        }
      }
    },
    setShowSuccessInfo(state, {payload}: PayloadAction<boolean>) {
      state.showSuccessInfo = payload
    }
  },
  extraReducers: {
    [fetchSurveysByUser.pending.type]: (state, _) => {
      state.loading = true
    },
    [fetchSurveysByUser.fulfilled.type]: (state, {payload}: PayloadAction<Surveys>) => {
      state.surveys = payload.reduce((acc, res) => {
        return {
          ...acc,
          [res.collect]: {
            ...state.surveys[res.collect],
            ...res
          }
        }
      }, {})
      state.loading = false
      state.error = null
    },
    [fetchSurveysByUser.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.loading = false
      state.error = payload
    },
    [fetchSurveyByCollect.pending.type]: (state, _) => {
      state.loading = true
    },
    [fetchSurveyByCollect.fulfilled.type]: (state, {payload}: PayloadAction<Collect>) => {
      const {survey, collect: collectId} = payload
      state.surveys[collectId] = {
        ...state.surveys[collectId],
        survey,
        collect: collectId
      }
      state.loading = false
      state.error = null
    },
    [fetchSurveyByCollect.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.loading = false
      state.error = payload
    }
  }
})

export const {
  setCurrentQuestion, addAnsweredQuestionId,
  removeAnsweredQuestionId, setAnswerValues, sendSurveyResults,
  addSurveyResults, rollbackResults, setShowSuccessInfo
} = impactSlice.actions

export default impactSlice.reducer

export const selectSurveys = (state: AppState) => {
  const collectIds = Object.keys(state.impact.surveys)
  return collectIds
    ? collectIds.map(collectId => state.impact.surveys[collectId])
    : []
}

export const selectSurveyByCollect =
  (state: AppState, collectId: string): SurveyState | undefined => {
    const surveys = selectSurveys(state)
    if (parseInt(collectId)) {
      return surveys.find(survey => survey.collect.toString() === collectId)
    }
    
    return surveys[0]
  }

export const selectBusyState = (state: OfflineAppState) => state.offline.busy

export const selectOnlineState = (state: OfflineAppState) => state.offline.online

export const selectShowSuccessInfo = (state: AppState) => state.impact.showSuccessInfo

export const selectLoading = (state: AppState): boolean => state.impact.loading

export const selectError = (state: AppState): ErrorType | null => state.impact.error