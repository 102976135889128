import React from 'react';
import { useSelector } from 'react-redux';
import { selectLanguageObject } from 'pages/Login/LoginSlice';
import {Title, Button, Container } from 'components';
import useStyles from './MessageTab.styles';
import { StyleProps } from 'types/styles';

interface TabProps {
  activeTab: 'messages' | 'archives';
  handleTabChange: (tab: 'messages' | 'archives') => void;
  style?: StyleProps;
}

const MessagesTab: React.FC = () => {
  const dictionary = useSelector(selectLanguageObject);
  return(
  <div>
    <Title title={dictionary.messages} variant={'subtitle'} style={{ align: 'left' }} />
  </div>);
};

const ArchivesTab: React.FC = () => {
  const dictionary = useSelector(selectLanguageObject);
  return <div><Title title={dictionary.archives} variant={'subtitle'} style={{ align: 'left' }} /></div>;
};

const TabComponent: React.FC<TabProps> = ({ activeTab, handleTabChange, style = { width: '90vw', maxWidth: '60rem' } }) => {
  const dictionary = useSelector(selectLanguageObject);
  const classes = useStyles(style);

  return (
      <Container variant='content' className={classes.tabContainer}>
      <Container variant="buttons" >   
      <Button
          label={dictionary.messages}
          onClick={() => handleTabChange('messages')}
          className={`${classes.tab} ${activeTab === 'messages' ? classes.activeTab : ''}`}
        ></Button>
        <Button
          label={dictionary.archives}
          onClick={() => handleTabChange('archives')}
          className={`${classes.tab} ${activeTab === 'archives' ? classes.activeTab : ''}`}
        ></Button>
      </Container>
      <div className="tab-content">
        {activeTab === 'messages' && <MessagesTab />}
        {activeTab === 'archives' && <ArchivesTab />}
      </div>
      </Container>
    
  );
};

export default TabComponent;
