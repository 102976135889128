import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    const maxWidth = "100%"
    return createStyles({
        content: {
            margin: "0 !important",
            width:"90vw",
            wordSpacing : "0.75rem",
            "& h1": {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.main,
                fontSize: "1.6875rem",
            },
            "& img": {
                height: "auto",
                maxWidth
            },
            "& figure": {
                margin: 0,
                maxWidth,
                textAlign: "center",
                marginTop: "0.625rem",
                marginBottom: "0.625rem",
            },
            "& video": {
                maxWidth,
                height: "auto",
            },
            "& h2": {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.primary.main,
                fontSize: "1.375rem",
            },
            "& p": {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.secondary.dark,
            },
            "& li": {
                fontFamily: theme.typography.fontFamily,
                color: theme.palette.secondary.dark,
            },
            "& a": {
                padding: "0.15rem",
            },
            "& pre": {
                maxWidth,
                textWrap:"wrap"
            },
        }

    })
})
