import React from 'react'
import clsx from "clsx"
import {StyleProps} from "types/styles"
import useStyles from "./Container.styles"

export interface ContainerProps {
    children: React.ReactNode
    variant?: "form" | "content" | "contentWithFixedHeader" | "buttons" | "title"
    className?: string
    style?: StyleProps
}

//Parent component to affect specific component
const Container: React.FC<ContainerProps> = (props) => {
  const {
    children,
    variant = "form",
    className,
    style={
      align:"center",
      margin: "0 auto"
    }
  } = props

  const classes = useStyles(style)

  return (
      <div className={clsx(classes[variant], className)} id={variant}>
          {children}
      </div>
  )
}

export default Container
