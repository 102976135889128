

import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"
import React from "react"

const ServicesSVG: React.FC<SVGPropsInterface> = ({width, height,fill}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 0 24 24" id="dashboard"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M4 13h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zm0 8h6c.55 0 1-.45 1-1v-4c0-.55-.45-1-1-1H4c-.55 0-1 .45-1 1v4c0 .55.45 1 1 1zm10 0h6c.55 0 1-.45 1-1v-8c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1v8c0 .55.45 1 1 1zM13 4v4c0 .55.45 1 1 1h6c.55 0 1-.45 1-1V4c0-.55-.45-1-1-1h-6c-.55 0-1 .45-1 1z"  fill={fill ? fill : defaultTheme.fill}></path></svg>
    
  )
}

export default ServicesSVG
