import React from "react"
import { Link as MUILink } from "@material-ui/core"
import useStyles from "./Link_Styles"
import { Speech } from "components"
interface LinkProps {
    children: string,
    variant?: string,
    onClick: () => void,
    className?: string
}

const Link: React.FC<LinkProps> = ({children, variant, onClick, className}) => {
    const classes = useStyles();
    let myClassName;
    
    if (className === undefined) {
        myClassName = (variant === "small" ? classes.smallLink : classes.link);
    }
    else {
        myClassName = className;
    }
    return (
        <Speech text={children} onClick={onClick}>
            <MUILink className={myClassName}>
                {children}
            </MUILink>
        </Speech>
    )
}

export default Link