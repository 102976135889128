import React, { useEffect } from 'react'
import {getSettingsPath, getWelcomePath } from "routes"
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "redux/store";
import { useServerErrorHandler } from "hooks";
import { useToken } from "cookies";
import { handleErrorCodes } from "utils/httpErrorCodes";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import { selectLanguage, selectLanguageObject, selectLoggedUser } from "pages/Login/LoginSlice";
import { fetchServices, selectError, selectServices, ServiceState, selectIsLoading } from './Services.slice'
import Card from "../../components/Card/Card";
import useStyles from "./Services.styles";
import back from "resources/icons/back.svg";
import cross from "resources/icons/cross.svg";
import {
  Container,
  ErrorComponent,
  HeaderContainer,
  Icon,
  Loader,
  Offline,
  Title
} from "components";
import { useProgram } from '../../program'
import { ErrorDetails } from 'types';


const Services: React.FC<{}> = () => {
  const program = useProgram()
  const {token} = useToken()
  const handleServerError = useServerErrorHandler()
  const dispatch = useDispatch<AppThunkDispatch>()

  const loggedUser = useSelector(selectLoggedUser)
  const isOnline = useSelector(selectOnlineState)
  const dictionary = useSelector(selectLanguageObject);
  const error = useSelector(selectError);
  const isLoading = useSelector(selectIsLoading);


  const services = useSelector(selectServices)
  const language = useSelector(selectLanguage)

  const classes = useStyles()

  const history = useHistory()
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

  useEffect(() => {
    if (loggedUser && isOnline && token) {
      handleServerError(dispatch(fetchServices({token, locale: language})))
    }
  }, [dispatch, handleServerError, language, loggedUser, isOnline, token])

  useEffect(() => {
    if(error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, dictionary.connectionError))
    }
  }, [error, dictionary.connectionError]);

  const buildServiceUrl = (service: ServiceState) => {
    const queryParams = service.connectors?.filter(connector => connector.type === 'QUERY')
      .map(connector => `${connector.field}=${connector.value}`)
      .join('&')
    return service.url + (queryParams ? `?${queryParams}` : '')
  }

  const goTo = (url: string) => {
    window.open(url, '_blank')
  }

  const handleBack = () => {
    history.goBack();
  }

  const handleClose = () => {
    history.push(getWelcomePath(program.name));
  }

  if (isOnline && isLoading) return <Loader/>

  return(
    errorDetails.message
    ? <ErrorComponent message={errorDetails.message} path={getSettingsPath(program.name)}/>
    :
    <Offline content={services}>
      <HeaderContainer className={classes.headContainer}>
        <Icon onClick={handleBack} className={classes.icon} imageUrl={back} />
        <Title title={dictionary.services} />
        <Icon onClick={handleClose} className={classes.icon} imageUrl={cross} />
      </HeaderContainer>
      <Container variant="contentWithFixedHeader">
        {
          services?.length > 0 ? services.map((service) => { return(
            <Container variant='buttons' key={service.id}>
              <Card onClick={() => goTo(buildServiceUrl(service))}
                    description={service.description}
                    icon={service.icon}
                    title={service.name}
                    style={{width: "75vw", maxWidth: "50rem"}}/>
            </Container>
          )})
          : <ErrorComponent message={dictionary.noServices} isError={false} snackMsg={errorDetails.slowConnexionError ? dictionary.slowConnection : undefined}/>
        }
      </Container>
    </Offline>
  )
}

export default Services
