import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        checkButtons: {
            display: "flex",
            flexDirection: "column",
            width: "calc(100% - 5rem)"
        },
        smallRoot: {
            width: "unset !important"
        }
    })
})

export const useOutlineStyles = makeStyles((theme: Theme) => {
    const border = `transparent`
    return createStyles({
        root: {
            borderRadius: "0.625rem",
            minHeight: "3rem",
            width: "18.125rem",
            height: "3rem",
            color: theme.palette.primary.main,
            background: theme.palette.secondary.contrastText,
            boxShadow: " 0px 4px 4px rgba(0, 0, 0, 0.25)",
            '&:hover $notchedOutline': {
                border
            },
            "& $notchedOutline": {
                border
            },
            "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: "none",
                "@media (hover: none)": {
                    border,
                },
            },
            "&$focused $notchedOutline": {
                border
            },
            "&$error $notchedOutline": {
                border: `0.125rem solid ${theme.palette.error.main}`,
            },
        },
        notchedOutline: {},
        focused: {},
        error: {},
        disabled: {}
    })
})