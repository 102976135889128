import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        title: {
            color: theme.palette.primary.main
        },
        index: {
            color: theme.palette.secondary.dark,
            marginRight: '0.625rem'
        },
        counter: {
            display: "flex",
            alignItems: "center"
        }
    })
})