import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {style} from "../../style";

export default makeStyles((theme: Theme) => {
  return createStyles({
    content: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      margin: "1.25rem auto",
      marginBottom: 0,
      padding: "0.625rem 0.625rem 2.5rem 0.625rem",
      width: "calc(100% - 40px)",
      "&>*": {
        marginBottom: "1.563rem"
      },
    },
    summary: {
      font: theme.typography.fontFamily,
      fontSize: "0.875rem",
      fontWeight: 700,
      "& p": {
        color: theme.palette.secondary.contrastText
      }
    },
    root: {
      height: "100%"
    },
    avatar: {
      height: "5.5rem",
      width: "5.5rem"
    },
    header: {
      display: "flex",
      alignItems: "center"
    },
    headerTitle: {
      flex: 1
    },
    divider: {
      height: "2px",
      width: "100%",
      background: theme.palette.secondary.contrastText,
      marginBottom: "1rem"
    },
    title: {
      fontSize: "1.6875rem",
      font: theme.typography.fontFamily,
      fontWeight: 700,
      color: theme.palette.secondary.contrastText
    },
    instructor: {
      alignSelf: "normal",
      font: theme.typography.fontFamily,
      fontWeight: 700,
      color: theme.palette.secondary.contrastText,
      fontSize: "1rem"
    },
    name: {
      alignSelf: "center",
      marginTop: "1rem !important"
    },
    button: {
      background: style.palette.mainDegraded(theme.palette.secondary.dark),
      maxWidth: "10.313rem",
      height: "2.188rem"
    },
    speech: {
      textAlign: "center",
      marginBottom: "1rem"
    },
    summaryContent: {
      width: "100%"
    }
  })
})
