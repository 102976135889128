import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {

    return createStyles({
        button: {
            margin: "1rem",
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "&:hover": {
                background: theme.palette.secondary.light,
            },
            alignSelf: "flex-start"
        },
        image: {
            width: "100%",
            height: "200px",
            marginBottom: "1rem"
        },
        text: {
            textAlign: "center"
        },
        smallScreenImage: {
            width: "100%",
            height: "100px",
            marginBottom: "1rem"
        }
    })
})