import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"
import {StyleProps} from "types/styles";

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      width: "100%",
      textAlign: (props: StyleProps) => props.align
    },
    title: {
      color: theme.palette.secondary.main,
      textTransform: "capitalize",
      textAlign: (props: StyleProps) => props.align,
      fontWeight: 700,
      fontSize: "1rem",
      fontFamily: theme.typography.fontFamily
    },
    subtitle: {
      color: theme.palette.secondary.main,
      textTransform: "uppercase",
      textAlign: (props: StyleProps) => props.align,
      fontSize: "1rem",
      fontFamily: theme.typography.fontFamily
    }
  })
})