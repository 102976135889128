import {
  KeyboardArrowLeftOutlined as PrevIcon,
  KeyboardArrowRightOutlined as NextIcon
} from "@material-ui/icons";
import { isRefreshSectionContentsWhenOnline } from "api/config";
import {
  Button,
  Container,
  ErrorComponent,

  Header,
  Loader,
  Offline, Option, ParsedContent,
  Snackbar, Speech
} from "components";
import { useToken } from "cookies";
import { useServerErrorHandler } from "hooks";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import { selectLanguageObject } from "pages/Login/LoginSlice";
import {
  fetchChapterById,
  selectChapterById, selectCurrentSectionId,
  selectError,
  selectIsLoading,
  selectSection,
  selectSectionIds,
  setChapterCompleted, setCurrentSection, setSuccessSnackbarShown, setMediaDownloadComplete, fetchChapterMediaListById
} from "pages/MyCourses/MyCourses.slice";
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps, useHistory } from "react-router-dom";
import { AppState } from "redux/rootReducer";
import { AppThunkDispatch } from "redux/store";
import { getChapterPath, getCoursePath } from "routes";
import useStyles from "./Chapter_Styles";
import { useSmallScreen } from "hooks";
import { useStore } from 'usestore-react';
import { useProgram } from '../../program'
import { handleErrorCodes } from "utils/httpErrorCodes";
import { ErrorDetails } from "types";

interface ChapterParams {
  id: string;
}

interface ChapterProps extends RouteChildrenProps<ChapterParams> {
  courseSlug: string;
  chapterId: string;
}

const Chapter: React.FC<ChapterProps> = (props) => {
  const program = useProgram();
  const { match, courseSlug, chapterId } = props;

  if (!match) {
    throw new Error("Undefined match");
  }
  const sectionId = match.params.id;
  const classes = useStyles();
  const history = useHistory();
  const { token } = useToken();
  const isSmallScreen = useSmallScreen();
  const handleServerError = useServerErrorHandler();
  const dispatch = useDispatch<AppThunkDispatch>();


  const language = useSelector(selectLanguageObject);
  const isLoading = useSelector(selectIsLoading);
  const section = useSelector((state: AppState) =>
    selectSection(state, courseSlug, chapterId)
  );
  const currentSectionId = useSelector((state: AppState) =>
    selectCurrentSectionId(state, courseSlug, chapterId)
  );
  const sectionIds = useSelector((state: AppState) =>
    selectSectionIds(state, courseSlug, chapterId)
  );
  const chapter = useSelector((state: AppState) =>
    selectChapterById(state, courseSlug, chapterId)
  );

  const error = useSelector(selectError)

  const isOnline = useSelector(selectOnlineState)

  function getSectionTitleAndOrder(){
    const sectionTitle = section ? section.order + 1 + ". " + section.title : ""
    return sectionTitle
  }

 const sectionTitleAndNb = getSectionTitleAndOrder()

  const { downloadFinished, allMediaDownloaded, successSnackbarShown } = chapter

  const [mediaList, setMediaList] = useStore('mediaList');

  const [snackbarMessage, setSnackbarMessage] = React.useState("")

  const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

  const handleCloseSnackbarSlowConnection = () => {
    setSnackbarState(false)
  }

  const fetchChapter = React.useCallback(async () => {
    if (isRefreshSectionContentsWhenOnline || !currentSectionId) {
      const res = (await handleServerError(
        dispatch(
          fetchChapterById({ id: chapterId, courseSlug, programId: program.id, token })
        )
      )) as any;

      if (res.meta.requestStatus === "fulfilled")
      {

        const sections = res.payload.sections
        const sectionId: string = sections.length > 0
          ? res.payload.sections[0].id
          : undefined;
        const sectionIdToUpdate = currentSectionId ? currentSectionId : sectionId;
        dispatch(
          setCurrentSection({
            courseSlug,
            chapterId,
            id: sectionIdToUpdate,
          })
        );
        history.push(getChapterPath(program.name, courseSlug, chapterId, sectionIdToUpdate));
        return;
      }
    }
  }, [program, currentSectionId, handleServerError, dispatch, chapterId, courseSlug, token, history]);

  const fetchChapterMedia = React.useCallback(async () => {
    if (isRefreshSectionContentsWhenOnline || (!downloadFinished && !allMediaDownloaded)) {

      const res = (await handleServerError(
        fetchChapterMediaListById( chapterId, courseSlug, program.id, token )
    )) as any;

    setMediaList(res.mediaList);

    }
  }, [downloadFinished, allMediaDownloaded, handleServerError, chapterId, courseSlug, token, setMediaList, program.id]);

  React.useEffect(() => {
    if (isOnline) {
      fetchChapter();
    }
  }, [fetchChapter, isOnline]);

  React.useEffect(() => {
    if (isOnline) {
      fetchChapterMedia();
    }
  }, [fetchChapterMedia, isOnline]);

  React.useEffect(() => {

    const checkDownloads = () => {
      Promise.all(mediaList as Array<Promise<void>>).then(() => {
        dispatch(setMediaDownloadComplete({
        chapterId,
        courseSlug,
        mediaDownloadComplete: true,
        allMediaDownloaded: true
      }))}).catch(() => {
        dispatch(setMediaDownloadComplete({
        chapterId,
        courseSlug,
        mediaDownloadComplete: true,
        allMediaDownloaded: false
      }))})

        }

             if (isOnline && mediaList && (!downloadFinished || !allMediaDownloaded)) {
                checkDownloads();
              }

    if (!successSnackbarShown) {
      const getSnackbarMessage = () => {
        if (isOnline && downloadFinished && allMediaDownloaded) {
          return language.downloadSuccessMessage
        }

        if (isOnline && downloadFinished && !allMediaDownloaded) {
          return language.errorWhileDownload
        }

        if (!isOnline && !downloadFinished && !allMediaDownloaded) {
          return language.downloadFailedMessage
        }

        return language.downloading
      }

      setSnackbarMessage(getSnackbarMessage())
    }
  }, [allMediaDownloaded, language, successSnackbarShown, dispatch, courseSlug, chapterId, isOnline, downloadFinished, mediaList])

  React.useEffect(() => {
      if(error && error.httpStatus !== 503) {
          setErrorDetails(handleErrorCodes(error, language.connectionError))
      }
  }, [error, language.connectionError])

   if (isOnline && isLoading && !errorDetails.slowConnexionError) {
     return <Loader />
   }


   if (isOnline && sectionIds.length === 0) {
     const textToDisplay = language.noSections.replace("$param", chapter.title)
     return <ErrorComponent message={textToDisplay} isError={false} snackMsg={errorDetails.slowConnexionError ? language.slowConnection : undefined}
       path={getCoursePath(program.name, courseSlug)} />
   }

  const currentIndex = sectionIds
    .findIndex(id => id.toString() === sectionId);

  const handleChangeSection = (id: string) => {
    dispatch(
      setCurrentSection({
        courseSlug,
        chapterId,
        id
      })
    );

    history.push(getChapterPath(program.name, courseSlug, chapterId, id));
  };

  const handleClose = () => {
    history.push(getCoursePath(program.name, courseSlug));
  };

  const handleCloseChapter = () => {
    dispatch(
      setCurrentSection({
        courseSlug,
        chapterId,
        id: sectionIds[0],
      })
    );

    dispatch(
      setChapterCompleted({
        courseSlug,
        chapterId,
      })
    );

    handleClose();
  };


  const handleScroll = () => {
  document.getElementById("form")!.scrollTo(0,0);
}


  const renderButtonContent = () => {
    const isDisabled = chapter.visitedSections?.length !== sectionIds.length;

    const handleNextClick = () => {
      if (currentIndex < sectionIds.length - 1) {
        const idToSet = sectionIds[currentIndex + 1];
        handleChangeSection(idToSet);
        handleScroll();
    }

    };

    const handlePrevClick = () => {
      if (currentIndex > 0 && currentIndex <= sectionIds.length - 1) {
        const idToSet = sectionIds[currentIndex - 1];
        handleChangeSection(idToSet);
        handleScroll();
      }

    };

    return (
      <Container variant="buttons">
        {currentIndex > 0 && (
          <Button
            label={language.previous}
            onClick={handlePrevClick}
            startIcon={<PrevIcon />}
            className={classes.button}
          />
        )}
        {currentIndex === sectionIds.length - 1
          ? (
            <Button
              label={language.finishChapter}
              onClick={handleCloseChapter}
              disabled={isDisabled}
            />
          ) : (
            <Button
              label={language.next}
              onClick={handleNextClick}
              endIcon={<NextIcon />}
              className={classes.buttonNext}
            />
          )}
      </Container>
    )
  }

  const renderHeaderContent = () => {
    if (currentSectionId) {
      const renderRouletteOptions = (): Array<Option> => {
        const sections = Object.keys(chapter.sections)
          .map(key => chapter.sections[key])
          .sort((a, b) => a.order - b.order);

        return sections.map(({ id, title }) => {
          const isChecked = chapter.visitedSections?.includes(id.toString())
          return { id, value: title, isChecked }
        })
      };

      return (
        <Header
          menuOptions={renderRouletteOptions()}
          selectedId={currentSectionId}
          onChange={handleChangeSection}
          onClose={handleClose}
          isSection={true}
        />
      )
    }
  }

  const openSnackbar = snackbarMessage.length > 0

  const handleCloseSnackbar = () => {
    if (!successSnackbarShown && allMediaDownloaded) {
      dispatch(setSuccessSnackbarShown({
        chapterId,
        courseSlug,
        successSnackbarShown: true
      }))
    }
    setSnackbarMessage("")
  }

  return (
    errorDetails.message 
    ? <ErrorComponent message={errorDetails.message} path={getCoursePath(program.name, courseSlug)}/>
    :
    <Offline content={section} path={getCoursePath(program.name, courseSlug)}>
      {renderHeaderContent()}
      <Container className={classes.container}>
        {currentSectionId &&
          <React.Fragment>
            <Container variant='content' className={classes.content}>
              <Speech text={sectionTitleAndNb} className={isSmallScreen ? classes.smallTitle : classes.sectionTitle}>
                <h1 className={isSmallScreen ? classes.smallH1 : classes.H1}>{sectionTitleAndNb}</h1>
              </Speech>
              {section!.content
                ? <ParsedContent htmlString={section!.content} />
                : null}
              {renderButtonContent()}
            </Container>
            <Snackbar open={openSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar} />
          </React.Fragment>
        }
      </Container>
      {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
    </Offline >
  );
};

export default Chapter;
