import { makeStyles, Theme, createStyles } from '@material-ui/core'



export default makeStyles((theme: Theme) => {
    return createStyles({
        headContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        titleContainer: {
            display: "flex",
            alignItems: "center",

            
        },
        link: {
            display: "flex",
            textAlign: 'left',
            marginLeft: "auto",
            marginRight: "auto",
            padding: "0.625rem",
            paddingTop: 0,
            paddingBottom: '0',
            color: theme.palette.secondary.main
        },
        icon: {
            "&:hover": {
                cursor: "pointer"
            }
        },
        smallLogo: {
            filter: 'invert(37%) sepia(32%) saturate(389%) hue-rotate(129deg) brightness(95%) contrast(83%)',
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: "5.875rem",
            height: "3.875rem"
        },
        logo: {
            filter: 'invert(37%) sepia(32%) saturate(389%) hue-rotate(129deg) brightness(95%) contrast(83%)',
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            width: '9.875rem',
            height: '10.875rem',
        },
        content:{
            color: theme.palette.primary.main,
            textAlign: 'left',
            alignItems: 'flex-start',
            paddingTop: 10,
            paddingBottom: '0',
            "&>*:last-child": {
                marginTop: 0,
                width: "100%"
            }
        },
        divider:{
            width: '50%',
            marginTop: 30,
            marginBottom: 20,
            textAlign: 'left',
            alignSelf: 'center',
        },
        root:{
            alignItems: 'flex-start',
            flexGrow: 1,
            padding: "1.563rem 0.625rem 1.563rem 0.625rem",
            overflowX: "hidden",
            margin: 0,
            height: "calc(100% - 122px)"
        }
        
    })
})