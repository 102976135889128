import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            "&>*": {
                color: `${theme.palette.secondary.dark} !important`,
                fontWeight: `${700} !important`,
            }
        }
    })
})