import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      position: "absolute",
      top: "50px",
      left: "50%",
      transform: "translateX(-50%)",
      margin: "auto",
      backgroundColor: theme.palette.secondary.dark,
      padding: "10px",
      borderRadius: "5px",
      maxWidth: "90%",
      minWidth: "300px"
    },
    smallContainer: {
      position: "absolute",
      top: "30px",
      left: "50%",
      transform: "translateX(-50%)",
      margin: "auto",
      backgroundColor: theme.palette.secondary.dark,
      padding: "10px",
      borderRadius: "5px",
      maxWidth: "90%",
      minWidth: "200px"
    },
    closeContainer: {
      display: "flex",
      justifyContent: "flex-end"
    },
    textContainer: {
      padding: "0 30px"
    },
    headerContainer: {
      paddingBottom: "15px"
    },
    titleText: {
      font: theme.typography.fontFamily,
      fontWeight: 300,
      fontSize: "1.1rem",
      color: theme.palette.secondary.contrastText
    },
    button: {
      background: theme.palette.primary.light,
      maxWidth: "110px",
      margin: "15px auto 1rem",
      color: theme.palette.secondary.contrastText,
      fontSize: "14px",
      borderRadius: "5px",
      height: "unset"
    }
  })
})