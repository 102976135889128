// eslint-disable-next-line no-undef
//This page set the config declared in the env file
const env = process.env

const {
    NODE_ENV,
    REACT_APP_PARTICIPANTSUITE_APP_DEFAULT_LANGUAGE,
    REACT_APP_PARTICIPANTSUITE_BACKEND_URL,
    REACT_APP_REFRESH_SECTION_CONTENTS_WHEN_ONLINE,
    REACT_APP_MICROSOFT_VOICE_SYNTHESIS_KEY,
    REACT_APP_MICROSOFT_VOICE_SYNTHESIS_SERVER,
    REACT_APP_PUBLIC_VAPID_KEY,
    REACT_APP_PULL_NOTIFICATIONS_INTERVAL,
    REACT_APP_FORCE_SERVICEWORKER_REGISTRATION
} = env

const isDevelopment = NODE_ENV === "development"
const isProduction = NODE_ENV === "production"

const isRefreshSectionContentsWhenOnline = REACT_APP_REFRESH_SECTION_CONTENTS_WHEN_ONLINE === "true"

const baseUrl = REACT_APP_PARTICIPANTSUITE_BACKEND_URL

const speechSynthMsKey = REACT_APP_MICROSOFT_VOICE_SYNTHESIS_KEY

const speechSynthMsServer = REACT_APP_MICROSOFT_VOICE_SYNTHESIS_SERVER

const podDefaultLanguage = REACT_APP_PARTICIPANTSUITE_APP_DEFAULT_LANGUAGE

const publicVAPKey = REACT_APP_PUBLIC_VAPID_KEY

const pullNotificationsInterval = REACT_APP_PULL_NOTIFICATIONS_INTERVAL

const forceServiceWorkerRegistration = REACT_APP_FORCE_SERVICEWORKER_REGISTRATION === "true"

export {
    baseUrl,
    podDefaultLanguage,
    isDevelopment,
    isProduction,
    isRefreshSectionContentsWhenOnline,
    speechSynthMsKey,
    speechSynthMsServer,
    publicVAPKey,
    pullNotificationsInterval,
    forceServiceWorkerRegistration
};