import React from "react"
import { Icon as MUIIcon } from '@material-ui/core';
import clsx from "clsx"
import useStyles from "./Icon_Styles"

export interface IconProps {
  imageUrl: string
  className?: string
  onClick?: () => void
}

const Icon: React.FC<IconProps> = ({ imageUrl, onClick, className }) => {
  const classes = useStyles()
  return (
    <MUIIcon onClick={onClick}
      classes={{
        root: clsx(classes.root, className)
      }}>
      <img src={imageUrl} alt={"icon"} className={classes.icon}/>
    </MUIIcon>

  )
}

export default Icon