import React from 'react';
import useStyles from "./Welcome_Styles"
import { SVGPropsInterface } from 'components/SVG/SVG';
import { Link } from 'react-router-dom';
import { Speech } from 'components';
import { useSmallScreen, useMediumWidthScreen } from 'hooks';


export interface propsTypes {
    title: string
    path?: string
    iconUrl: React.FC<SVGPropsInterface>
  }




const WelcomeButton = (props : propsTypes) => {
  const classes = useStyles()
  const {path, iconUrl, title} = props
  const isSmallScreen = useSmallScreen()
  const isMediumScreen = useMediumWidthScreen()

  return (

    <div className={classes.welcomeButton}>
      { path ?
          <Link to={path} role='button' aria-label={title}>
          <div className={isMediumScreen ? classes.mediumButton : isSmallScreen ? classes.smallButton : classes.button}>
            
        { isMediumScreen ? 
        iconUrl({ height: "40px", width: "40px", fill: "white", stroke:'white' })
        :
        isSmallScreen ? 
          iconUrl({ height: "30px", width: "30px", fill: "white", stroke:'white' })
          :
          iconUrl({ height: "48px", width: "48px", fill: "white", stroke:'white' })}
        </div>
        </Link>
        :
        <div className={isMediumScreen ? classes.mediumButton : isSmallScreen ? classes.smallButton : classes.button} aria-label={title} role='button'>
          
      { isMediumScreen ? 
        iconUrl({ height: "40px", width: "40px", fill: "white", stroke:'white' })
        :
        isSmallScreen ? 
        iconUrl({ height: "30px", width: "30px", fill: "white", stroke:'white' })
        :
        iconUrl({ height: "48px", width: "48px", fill: "white", stroke:'white' })}
      </div>
    }


      <Speech text={title} className={isSmallScreen ? classes.smallTitle : classes.title}>
        <p aria-hidden="true">
        {title}
        </p>
        </Speech>
      </div>
      
  );
};

export default WelcomeButton;