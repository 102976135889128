import { ClickAwayListener, MenuItem as MUIMenuItem, Paper, Typography } from "@material-ui/core";
import MenuIcon from '@material-ui/icons/Menu';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import clsx from "clsx";
import { IconButton, Speech } from "components";
import { useSmallScreen } from "hooks";
import { selectLanguageObject } from "pages/Login/LoginSlice";
import React from "react";
import { useSelector } from "react-redux";
import useStyles from "./Roulette_Styles";

interface MenuItemProps {
  onClick: () => void
  title: string
  className?: string
  isChecked?: boolean
}

// Enable to navigate between the page of a chapter and also between the questions of a survey
const MenuItem: React.FC<MenuItemProps> = (props) => {
  const { onClick, title, className, isChecked } = props
  return (
    <Speech text={title} onClick={onClick}>
      <MUIMenuItem
        className={className}
        dense
        divider>
        {title}
        {isChecked && <CheckCircleIcon color="action" />}
      </MUIMenuItem>
    </Speech>
  )
}

export interface Option {
  id: string;
  value: string;
  isChecked?: boolean
}

export interface RouletteProps {
  options: Array<Option>;
  selectedId?: string;
  onChange: (id: string) => void;
  className?: string;
  showValue?: boolean;
}

const Roulette: React.FC<RouletteProps> = (props) => {
  const { options, onChange, selectedId, className, showValue } = props;
  const classes = useStyles()
  const language = useSelector(selectLanguageObject)
  const [openDialog, setOpenDialog] = React.useState(false)

  const selectedValue = React.useMemo(() => {
    const selectedItem = options
      .find(option => option.id === selectedId)
    return selectedItem ? selectedItem.value : undefined
  }, [options, selectedId])

  const handleOpenDialog = () => {
    setOpenDialog(() => !openDialog);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleChange = (id: string) => {
    handleCloseDialog()
    onChange(id)
  }

  const renderOptions = () => {
    return options.map(item => {
      return (
        <MenuItem
          key={item.id}
          isChecked={item.isChecked}
          className={selectedId === item.id
            ? clsx(classes.menuItem, classes.selected)
            : classes.menuItem}
          onClick={() => handleChange(item.id)}
          title={item.value} />)
    })
  }

  const isSmallScreen = useSmallScreen()

  /**
   * Returns roulette with textfield if showValue is true
   * else returns roulette with icon button
   */
  const renderSelectField = () => {
    const selectClass = isSmallScreen 
    ? clsx(classes.selectField, classes.smallSelectField)
    : classes.selectField 

    return (
      <div className={
        showValue
          ? openDialog
            ? clsx(selectClass, classes.selectWithMenu)
            : selectClass
          : undefined}
        onClick={handleOpenDialog} >
        { showValue && (selectedValue
          ? <Speech text={selectedValue}><Typography>{selectedValue}</Typography></Speech>
          : <Speech text={selectedValue || ""}>
            <Typography className={classes.placeholder}>
              {language.selectValue}
            </Typography>
          </Speech>
        )}
        <IconButton onClick={handleOpenDialog}>
            <MenuIcon />
        </IconButton>
      </div >
    )
  }

  const paperClass = isSmallScreen
    ? clsx(classes.paper, classes.smallPaper)
    : classes.paper

  return (
    <ClickAwayListener onClickAway={handleCloseDialog}>
      <div aria-label={language.myCourses}>
        {renderSelectField()}
        {openDialog && <Paper className={clsx(paperClass, className)}>
          {renderOptions()}
        </Paper>}
      </div>
    </ClickAwayListener>
  );
};

export default Roulette;
