import { ErrorComponent } from "components"
import { selectLanguageObject } from "pages/Login/LoginSlice"
import React from "react"
import { useSelector } from "react-redux"

interface OfflineProps {
    content: any
    children?: React.ReactNode
    path?: string
}

//This component check the state of the connexion and if you have dowload the content
const Offline: React.FC<OfflineProps> = ({ content, children, path }) => {
    const language = useSelector(selectLanguageObject)

    const isContentEmpty = () => {
        if (!content) {
            return true
        }
        const isArray = Array.isArray(content)
        const isString = typeof content === "string"

        if (isArray || isString) {
            return content.length === 0
        }

        if (typeof content === "object") {
            return Object.keys(content).length === 0
        }

        return false
    }

    const showOfflineMessage = isContentEmpty() && navigator.onLine === false

    return (
        <>
            {showOfflineMessage
                ? <ErrorComponent message={language.offlineMessage} path={path} />
                : children}
        </>
    )
}

export default Offline