import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";


export default makeStyles((theme: Theme) => {

    return createStyles({
        tabContainer: {
            display: "flex",
            width: "100%",
            background:"white"
        },
        tab: {
        flex: "1",
        background: theme.palette.primary.contrastText,
        border: "none",
        outline: "none",
        cursor: "pointer",
        backgroundColor: theme.palette.primary.light ,
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
    },
    },
    activeTab:{
        background: theme.palette.primary.main
    }
    })
})