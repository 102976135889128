import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {style} from "../../style";

export default makeStyles((theme: Theme) => {
  
  
  return createStyles({
    button: {
      borderRadius: "0.625rem",
      height: "3rem",
      textTransform: "capitalize",
      textAlign: "center",
      fontSize: "1.125rem",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      background: style.palette.mainDegraded(theme.palette.primary.main),
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      lineHeight: "unset"
    },
    disabled: {
      background: theme.palette.secondary.light
    },
    speech: {
      display: "flex",
      justifyContent: "space-evenly",
      width: "100%",
    },
    smallButton: {
      fontSize: "0.75rem",
      textAlign: "center",
    }
  })
})
