import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {

    const smallScreenWidth = "11.125rem"
    return createStyles({
        root: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "100vh",
            rowGap: "1.5rem",
            paddingRight: "24px",
            paddingLeft: "24px",

        },
        smallScreenRoot: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            minHeight: "100vh",
            rowGap: "1rem",
            paddingRight: "24px",
            paddingLeft: "24px",
        },
        container: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width:"60%",
            rowGap: "0.5rem",
        },
        smallContainer: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            width:"100%",
            rowGap: "0.5rem",
        },
        textfield: {
            width: "100%",
            height: "3rem",
            fontFamily: theme.typography.fontFamily,
            fontSize: "1.125rem",
        },
        termsAndConditionsConsent: {
            font: theme.typography.fontFamily,
            color: theme.palette.secondary.main,
            fontWeight: 400,
            fontSize: "0.70rem",
            textAlign: "center"
        },
        button: {
            width:"100%",
        },
        smallButton: {
            width: "100%",
        },
        error: {
            color: theme.palette.error.main,
            fontFamily: "inherit",
            textAlign: "center",
            padding: "0.5rem",
        },
        info: {
            fontFamily: theme.typography.fontFamily,
            fontSize: "1.125rem",
            color: theme.palette.secondary.main,
            textAlign: "center",
            overflowWrap: "break-word",
            maxWidth: "90%",
            padding: "0.5rem",
        },
        dialogButton: {
            marginTop: "0.938rem",
            background: theme.palette.secondary.dark
        },
        dialogHeader:{
            fontSize: "1.50rem",
            fontWeight: 700
        },
        select: {
            width:"100%",
            display: "flex",
            justifyContent:"flex-end"
        },
        smallScreenDialog: {
            width: smallScreenWidth
        },
        smallLink: {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            textDecoration :"underline",

        },
        link: {
            color: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontSize: "1em",
            textDecoration :"underline",
        },
        smallLogo:{
            width: "80px"
        },
        logo:{
            width: "100px"
        },
        flex:{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexWrap: "wrap",
            textAlign: "center",
            color: theme.palette.primary.main,
            columnGap: "0.5rem",
        },
        title:{
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
            fontSize: "1.125rem",
            padding: "0.5rem 0",
            color: theme.palette.primary.main,
            textAlign: "center",
          },
        suttiLogo:{
            width:"50px",
            borderRadius: "50%",
            borderWidth: "2px",
            padding: "2px",
            borderColor: theme.palette.primary.main,
            borderStyle: "solid",
          }
    })
})

export const useOutlineStyles = makeStyles((theme: Theme) => {
    const border = `2px solid ${theme.palette.secondary.main}`
    return createStyles({
        root: {
            borderRadius: 10,
            minHeight: "3rem",
            height: "3rem",
            color: theme.palette.secondary.main,
            '&:hover $notchedOutline': {
                border
            },
            "& $notchedOutline": {
                border
            },
            "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
                borderColor: "none",
                "@media (hover: none)": {
                    border,
                },
            },
            "&$focused $notchedOutline": {
                border
            },
            "&$error $notchedOutline": {
                border: `2px solid ${theme.palette.error.main}`,
            },
        },
        notchedOutline: {},
        focused: {},
        error: {},
        disabled: {}
    })
})