import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            width: "18.5rem",
            background: "transparent",
            borderRadius: "0.5rem",
            color: "white",
            boxShadow: "none"
        },
        dialog: {
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center"
        },
        iconButton: {
            background: `${theme.palette.primary.main} !important`,
            height: "3rem",
            width: "3rem",
            margin: "1rem auto",
            padding: 0
        },
        content: {
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            flexWrap: "wrap",
            padding: "0.938rem",
            background: theme.palette.primary.main,
            borderRadius: "0.5rem",
            "&>*:not(:first-child)": {
                marginBottom: "0.625rem"
            }
        }
    })
})