import React from "react"
import { useSmallScreen } from "hooks"
import { Button as MUIButton } from "@material-ui/core"
import clsx from "clsx"
import { StyleProps } from "types"
import { ChapterProgression, Speech } from "components"
import useStyles from "./Card.styles"

export interface CardProps {
  title: string
  description: string
  icon?: string
  disabled?: boolean
  isChapter?: boolean
  percentContainer?: string
  percentFiller?: string
  percentageCompletion?: number
  checkIconClass?: string
  completed?: boolean
  percentageCompletionClass?: string
  chapterProgression?: number
  totalSections?:number
  sectionProgression?: string
  style?: StyleProps
  className?: string
  rootClassName?: string
  onClick?: () => void
}

const Card: React.FC<CardProps> = (props) => {
  const {
    title,
    description,
    completed,
    percentContainer,
    percentFiller,
    checkIconClass,
    percentageCompletion,
    percentageCompletionClass,
    isChapter,
    chapterProgression,
    totalSections,
    sectionProgression,
    icon,
    className,
    disabled,
    onClick,
    rootClassName,
    style={
      maxWidth: '90%',
      width: 'auto !important',
      height: '100%'
    },
    
    // ...children
  } = props

  const classes = useStyles(style)
  const isSmallScreen = useSmallScreen()

  const buttonClass = isSmallScreen
    ? clsx(classes.button, classes.smallButton)
    : classes.button

  return (
    <Speech text={title}
            onClick={disabled ? undefined : onClick}
            className={clsx(classes.speech, rootClassName)}>
      <MUIButton variant={"contained"}
                 color="primary"
                 className={disabled
                   ? clsx(classes.disabled, buttonClass)
                   : clsx(buttonClass, className)}
                 disableRipple
                //  disabled={disabled} {...children} >
                disabled={disabled} >
        <div className={clsx(classes.divImg)}>
          <img src={icon} className={clsx(classes.image)} alt="icon"  aria-hidden="true" />
        </div>
        <div className={clsx(classes.text)}>
          <p className={clsx(classes.title)}>{title}</p>
          <p className={clsx(classes.description)} dangerouslySetInnerHTML={{__html: description}} />
          {
            isChapter && !disabled &&
            <ChapterProgression sectionProgression={sectionProgression}
                                totalSections={totalSections}
                                percentContainer={percentContainer}
                                percentageCompletionClass={percentageCompletionClass}
                                percentageCompletion={percentageCompletion}
                                percentFiller={percentFiller}
                                chapterProgression={chapterProgression}
                                completed={completed}
                                checkIconClass={checkIconClass}
                                title={title}
                                description={description} />
          }
        </div>
      </MUIButton>
    </Speech>
  )
}

export default Card