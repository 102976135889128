import {  Button, Container, Speech, Title } from 'components';
import { selectLanguageObject } from 'pages/Login/LoginSlice';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { CloudDownload } from '@material-ui/icons';
import { useQuery, useSmallScreen } from 'hooks';
import useStyles from "./PWAInstall_Styles"
import { KeyboardArrowRightOutlined as NextIcon } from '@material-ui/icons';
import { IsSafariIOS, isFullScreen, isKaios, isStandalone } from 'utils/utils';
import ShareSVG from "components/SVG/ShareSVG"
import Add2HomeSVG from "components/SVG/Add2HomeSVG"
import { useProgram } from 'program';

import {Popup} from 'components';
import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import { Link } from 'react-router-dom';

let deferredPrompt: any;

interface PWAInstallProps {
  variant: "button" | "dialog" | "safari" | "kaios"
}

const PWAinstall: React.FC<PWAInstallProps> = ({variant}) => {
  const [installable, setInstallable] = useState(false);
  const [showInstructions, setShowInstructions] = useState(false);

  const [DialogSafari, setDialogSafari] = React.useState(false);
  const [DialogKaiOS, setDialogKaiOS] = React.useState(false);


  const language: any = useSelector(selectLanguageObject);

  const isSmallScreen = useSmallScreen()

  const program = useProgram();

  const query = useQuery();

  const style = {
    width:'60vw',
    maxWidth:'30rem',
    height:'100%'
  }

  const classes = useStyles(style)

  const isDialog = variant === "dialog" ? true : false;

  const IsSafariDialog = variant === "safari" ? true : false;

  const isKaiOSDialog = variant === "kaios" ? true : false;

  const isButton = variant === "button" ? true : false;

  const buttonClass = isSmallScreen ? classes.smallButton : classes.button;

  const isMounted = useRef(true)
  
  // Automatically detect if app is in standalone mode, or use installed query param to override (set to true)
  const isInstalled = isStandalone() || isFullScreen() || query.get("installed") === "true"


  useEffect(() => {
    if(isMounted.current){
      window.addEventListener("beforeinstallprompt", (e) => {
        e.preventDefault();
        deferredPrompt = e;
        // display the button if PWA installation API is compatible
        setInstallable(true);
      });
  
      window.addEventListener('appinstalled', () => {
        // If the app was installed
        console.log('installation successful');
      });
      isMounted.current = false
    }
  }, []);

  useEffect(() => {
      // If in Safari, show the dialog for IOS
      if (IsSafariIOS()) {
        setDialogSafari(true)
      } else 
       // If in KaiOS, show the dialog for KaiOS as we cannot detect on this platform
      if (isKaios() && !isInstalled) {
        setDialogKaiOS(true)
      }
  }
  ,[isInstalled])


  const handleInstallClick = () => {
    setInstallable(false);
    // show the prompt to install the pwa
    if (!isKaios() && !IsSafariIOS()) {
      deferredPrompt.prompt();
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === 'accepted') {
          console.log('User accepted the install prompt');
        } else {
          console.log('User dismissed the install prompt');
        }
      });
    }
  };

  const handleClose = () => {
    setInstallable(false);
    setDialogSafari(false);
    setDialogKaiOS(false);
    setShowInstructions(false);
  };
  

  return (
    <>
    {
      isDialog && !isInstalled ?
  <Popup title={language.downloadApp}
        buttonLabel={language.downloadApp }
        explanationLabel={language.downloadAppPlease}
        open={installable}
        onClose={handleClose}
        onSubmit={handleInstallClick}
        onSubmitWithExtraAction={() => { }} />
    : 
    installable && isButton && !isInstalled ?
    <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
    <Button label={ language.downloadApp }
            onClick={ handleInstallClick }
            startIcon={<CloudDownload />}
            endIcon={<NextIcon />}
            className={ buttonClass }
            />
  </Container> : 
  IsSafariDialog ? 
  <>
  <Popup title={language.downloadApp}
        buttonLabel={language.instructions}
        explanationLabel={language.downloadAppIOS}
        open={DialogSafari}
        onClose={handleClose}
        onSubmit={() => setShowInstructions(true)}
        onSubmitWithExtraAction={() => { }} />

  <Dialog onClose={handleClose} open={showInstructions} className={classes.dialogInstructions} >
      <DialogTitle>
         <Title title={language.downloadApp}/>
         </DialogTitle>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.instructions}>
        <ShareSVG height ={"50px"} width={"50px"} stroke={"black" }/>
        <Speech text={language.downloadAppIOS1}>
        {language.downloadAppIOS1}
          </Speech>

        </div>
        <div className={classes.instructions}>
        <Add2HomeSVG height ={"50px"} width={"50px"} stroke={"black" }/>
        <Speech text={language.downloadAppIOS2}>
        {language.downloadAppIOS2}
          </Speech>

        </div>
       

      </DialogContent>
    </Dialog>

  </>

: isKaiOSDialog ?   <>
<Popup title={language.downloadApp}
      buttonLabel={language.instructions}
      explanationLabel={language.downloadAppIOS}
      open={DialogKaiOS}
      onClose={handleClose}
      onSubmit={() => setShowInstructions(true)}
      onSubmitWithExtraAction={() => { }} />

<Dialog onClose={handleClose} open={showInstructions} className={classes.dialogInstructions} >
    <DialogTitle>
       <Title title={language.downloadApp}/>
       </DialogTitle>
    <DialogContent className={classes.dialogContent}>
      <Link style={{ textDecoration: 'none' }} to={{pathname: `https://www.kaiostech.com/store/apps/?manifest_url=https://sutti.app/${program.name}/manifest.webapp`}} target='_blank'>
      <Button label={ language.downloadApp }
            startIcon={<CloudDownload />}
            className={ buttonClass }
            />
      </Link>
    </DialogContent>
  </Dialog>

</> : null}
  </>
  );
}

export default PWAinstall;
