import React, { useState } from "react"
import { Typography } from "@material-ui/core"
import useStyles from "./Loader_Styles"
import { selectLanguageObject } from "pages/Login/LoginSlice"
import { useSelector } from "react-redux"
import BeatLoader from "react-spinners/BeatLoader";
import { useProgram } from '../../program'
import { style } from '../../style'



//this is a loader to show the app is charging
const Loader: React.FC<{}> = () => {
    const program = useProgram()
    const classes = useStyles()
    const language = useSelector(selectLanguageObject)
    let [loading] = useState(true);
    let [color] = useState(program.configs?.palette?.primary?.main || style.palette.black);

    return (
        <>
        <Typography className={classes.loader} style={{ color: color}}>
            {language.loadingInProgress}
        </Typography>
        <BeatLoader className={classes.spinner} color={color} loading={loading} size={20} />
        </>
    )
}

export default Loader
