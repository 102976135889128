
const internal: {
    initialized: boolean;
    speechSynthesis: SpeechSynthesis;
    speechSynthesisUtterance: {
        new (text?: string | undefined): SpeechSynthesisUtterance;
        prototype: SpeechSynthesisUtterance;
    };
    [key: string]: any;
} = {
    initialized: false,
    speechSynthesis: window.speechSynthesis,
    speechSynthesisUtterance: window.SpeechSynthesisUtterance,
};

type Features = {
    speechSynthesis: boolean;
    speechSynthesisUtterance: boolean;
    onvoiceschanged: boolean;
};


  const hasProperty = (target = {}, prop : any) => Object.hasOwnProperty.call(target, prop) || prop in target || !!target[prop]


  const detectFeatures = (): Features => {
    return {
        speechSynthesis: 'speechSynthesis' in window,
        speechSynthesisUtterance: 'SpeechSynthesisUtterance' in window,
        onvoiceschanged: 'onvoiceschanged' in window.speechSynthesis,
    };
};
   export function TtsInitalization({ maxTimeout , interval } :{ maxTimeout : number , interval : number}) {

    return new Promise((resolve, reject) => {
      // If initialized, return directly
      if (internal.initialized) { return resolve(false); }
      
      internal.initialized = false;
  
      let timer : any ;
      let voicesChangedListener: (() => void) | null = null;
      let completeCalled = false;
  
  
      //Fail on init
      const fail = (errorMessage : string) => {
        clearInterval(timer);
        internal.initialized = false;
  
        // False if quiet is activated
        reject(new Error(`New error: ${errorMessage}`));
      };
  
      // If init is complete, return true
      const complete = () => {
        if (completeCalled) { return; }
  
        completeCalled = true;
        internal.initialized = true;
  
        clearInterval(timer);
        
        // Remove listener
        if (voicesChangedListener) {
          internal.speechSynthesis.removeEventListener('voiceschanged', voicesChangedListener);
        }
  
        return resolve(true);
      };
  
      // Dectect all browser features
      const features = detectFeatures();
      const hasAllFeatures = !!features.speechSynthesis && !!features.speechSynthesisUtterance;
  
      // Fail if browser doesn't have all features
      if (!hasAllFeatures) {
        return fail('browser misses features');
      }
  
      // Assign features to internal object
      (Object.keys(features) as (keyof Features)[]).forEach((feature) => {
        internal[feature] = features[feature];
    });
  
      // Verify if voices are loaded
      const voicesLoaded = () => window.speechSynthesis.getVoices().length > 0;
  
  
      // If true, return directly
      if (voicesLoaded()) { return complete(); }
  
      // Load voices through timeout 
      // see ( https://dev.to/jankapunkt/cross-browser-speech-synthesis-the-hard-way-and-the-easy-way-353)
      const loadViaTimeout = () => {
        let timeout = 0;
        timer = setInterval(() => {
          if (voicesLoaded()) {
            return complete();
          }
  
          if (timeout > maxTimeout) {
            return fail('browser has no voices (timeout)');
          }
  
          timeout += interval;
        }, interval);
      };
  
      // Does browser support onvoiceschanged
      if (features.onvoiceschanged) {
  
        // Listener for onvoiceschanged event
        speechSynthesis.onvoiceschanged = () => {
          if (voicesLoaded()) { return complete(); }
  
          // If voices are not loaded, load them via timeout
          return loadViaTimeout();
        };
  
        // If timeout is reached, fail
        setTimeout(() => {
          if (voicesLoaded()) {
            return complete();
          }
          return fail('browser has no voices (timeout)');
        }, maxTimeout);
      } else {
        // If brower support onvoiceschanged, use it
        if (hasProperty(speechSynthesis, 'addEventListener')) {
  
          // Listener for onvoiceschanged event
          voicesChangedListener = () => {
            if (voicesLoaded()) { return complete(); }
          };
  
          speechSynthesis.addEventListener('voiceschanged', voicesChangedListener);
        }
  
        // Load voices through timeout
        loadViaTimeout();
      }
    });
  };
