import { NewSessionBody } from "types/notifications";

export function capitalize(word: string) {
  return word[0].toUpperCase() + word.substring(1).toLowerCase();
}

export const fillTemplateString = (templateString: string, templateVars: any) => {
  return templateString?.replace(/\${(.*?)}/g, (_, g) => templateVars[g]);
}

export const waitToUpdateState = (setState: React.Dispatch<React.SetStateAction<any>>, value: any, delay: number) => {
  setTimeout(() => {
      setState(value)
  }, delay)
}

export const IsSafariIOS = () => {
  // Detect if IOS
  const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  // Detect if the app is installed
  const isStandalone =  navigator.standalone || window.matchMedia("(display-mode: standalone)").matches
  // Check if the user in chrome using IOS 
  const isChromeIOS = window.navigator.userAgent.match(/CriOS/i);
  // If its iOS and not installed and not in Chrome it means that it is in safari
  const res = isIos && !isStandalone && !isChromeIOS ? true : false 
  return res
  
}

export const isIos = () => {
  // Detect if IOS
  const isIos = navigator.userAgent.match(/iPhone|iPad|iPod/i);
  const res = isIos ? true : false
  return res
  
}

export const isAndroid = () => {
  // Detect if Android
  const isAndroid = navigator.userAgent.match(/android/i);
  const res = isAndroid ? true : false
  return res
  
}

export const isStandalone = () => {
  // Detect if app is installed
  const isStandalone =  navigator.standalone || window.matchMedia("(display-mode: standalone)").matches
  const res = isStandalone ? true : false
  return res
}

export const isFullScreen = () => {
  // Detect if app is installed
  const isFullScreen =  document.fullscreen || window.matchMedia("(display-mode: fullscreen)").matches || document.fullscreenElement || document.webkitFullscreenElement || document.mozFullScreenElement
  const res = isFullScreen ? true : false
  return res
}

export const isKaios = () => {
  // Detect if Kaios
  const isKaios = navigator.userAgent.match(/KAIOS/i);
  return isKaios ? true : false
  
  
}

  export function getProgram(){
      // For V1 the program name is derived from the sutti.app subdomain (e.g cascade extracted from https://frontend.live.cascade.sutti.app)
      // for V2 the program can be extracted from the path (e.g /cascade/)
      let program
      // V1 case
      if (window.location.hostname.endsWith("cascade.sutti.app")) {
        program = "cascade"
      }
      else
      {
        // V2 case
        program = window.location.pathname.split("/")[1];
        if(program.length === 0){
            program = "unknown"
        }
      }
      return program
    }

    // Format push notif for alert session
    export function formatMessage (body: NewSessionBody) {
      let message : string = ""
      if(body.message){
        message += "\n" + body.message
      }
      if(body.date){
        message += "\nDate: " + new Date(body.date).toLocaleString()
      }
      return message
    } 

