import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { CSSProperties } from "@material-ui/core/styles/withStyles";

export default makeStyles((theme: Theme) => {
    const padding = 20
    const paddindInRem = `calc(${padding * 0.063}rem)`
    const flexStyles = {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        flexDirection: "column",
    } as CSSProperties

    return createStyles({
        buttons: {
            width: "18.75rem",
            display: "flex",
            alignItems: "center",
            margin: "1.25rem auto",
            "&>*:not(:last-child)": {
                marginRight: "0.313rem",
            }
        },
        button: {
            background: theme.palette.secondary.contrastText,
            color: theme.palette.primary.main,
            marginBottom:"0.75rem"
            
        },
        buttonNext: {
            background: theme.palette.primary.main,
            color: theme.palette.secondary.contrastText ,
            
        },
        content: {
            paddingTop: paddindInRem,
            paddingBottom: paddindInRem,
            ...flexStyles,
            height: `calc(100% - ${1 * padding}px)`
        }
    })
})