import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {

    return createStyles({
        loader: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
            position: "absolute",
            fontSize: "1.6875rem",
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.secondary.main
        },
        spinner: {
            position: "fixed", 
            top: "50%", 
            left: "50%", 
            transform: "translate(-50%, -50%)",
            marginTop: "3em"
        },
    })
})