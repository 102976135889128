import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        paper: {
            overflowX: "hidden",
            maxHeight: "28.125rem",
            minWidth: "20rem",
            maxWidth: "28.125rem",
            position: "absolute",
            left: 0,
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
            padding: "0.625rem 1.25rem 1.25rem 1.25rem",
            borderRadius: "0 0 10px 10px"
        },
        smallPaper: {
            minWidth: "10rem"
        },
        menuItem: {
            whiteSpace: "unset",
            font: theme.typography.fontFamily,
            color: theme.palette.secondary.dark,
            fontWeight: 500,
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center"
        },
        selected: {
            fontWeight: 700,
            background: theme.palette.primary.main,
            color: "white",
            "&:hover": {
                background: theme.palette.primary.main
            }
        },
        selectField: {
            display: "flex",
            alignItems: "center",
            width: "18.125rem",
            justifyContent: "space-between",
            borderRadius: "0.625rem",
            boxShadow: "0px 4px 4px rgb(0 0 0 / 25%)",
            height: "2.5rem",
            padding: "0.313rem 0 0.313rem 0.625rem",
            color: theme.palette.primary.main,
            background: theme.palette.secondary.contrastText
        },
        smallSelectField: {
            width: "unset"
        },
        selectWithMenu: {
            borderRadius: "10px 10px 0 0"
        },
        placeholder: {
            color: theme.palette.secondary.light
        }
    })
})
