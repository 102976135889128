import { Typography } from "@material-ui/core";
import {
  Container,
  CourseItemButton,
  HeaderContainer,
  Loader,
  Offline,
  Title,
  Snackbar,
  ErrorComponent,
  Icon
} from "components";
import { useToken } from "cookies";
import { useServerErrorHandler } from "hooks";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import { selectLanguageObject, selectLoggedUser } from "pages/Login/LoginSlice";
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppThunkDispatch } from "redux/store";
import { getAddCoursePath, getCoursePath, getCourseSummaryPath, getWelcomePath, appTutorial } from "routes";
import { Details, ErrorDetails } from "types";
import {
  fetchMyCourses,
  selectError,
  selectIsLoading,
  selectMyCourses
} from "./MyCourses.slice";
import useStyles from "./MyCourses.styles";
import { handleErrorCodes } from "utils/httpErrorCodes";
import { useProgram } from '../../program'
import cross from "resources/icons/cross.svg";
import back from "resources/icons/back.svg";


export interface Course extends Details {
  type: "item";
}

//this page display the courses the candidate have access and a add courses button
const MyCourses: React.FC<{}> = () => {
  const program = useProgram();
  const classes = useStyles();
  const history = useHistory();
  const { token } = useToken();
  const dispatch = useDispatch<AppThunkDispatch>();
  const loggedUser = useSelector(selectLoggedUser);
  const language = useSelector(selectLanguageObject);
  const handleServerError = useServerErrorHandler();
  const courses = useSelector(selectMyCourses);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const isOnline = useSelector(selectOnlineState)
  const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

  const handleCloseSnackbar = () => {
    setSnackbarState(false)
  }

  useEffect(() => {
    if (loggedUser && isOnline && token) {
      handleServerError(dispatch(fetchMyCourses({ token })));
    }
  }, [dispatch, token, handleServerError, loggedUser, isOnline]);


  React.useEffect(() => {
    if (error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, language.connectionError))
    }
  }, [error, language.connectionError]);

  if (isOnline && isLoading) {
    return <Loader />;
  }

  const handleAddCourseClick = () => {
    history.push(getAddCoursePath(program.name));
  };

  const handleCourseClick = (slug: string) => {
    const hasAccessed = courses.find((course) => course.slug === slug)
      ?.accessed;
    hasAccessed
      ? history.push(getCoursePath(program.name, slug))
      : history.push(getCourseSummaryPath(program.name, slug));
  };

  const renderNoCourseContent = () => {
    if (loggedUser) {
      const welcomeTitle = `${language.hello} ${loggedUser.firstName}, ${language.congratulations}`
      return (
        <Container variant="content" className={classes.welcomeContainer}>
          <Title title={welcomeTitle} className={classes.title} isSection={true} />
          <Typography className={classes.welcomeMessage}>{language.welcomeMessage}</Typography>
        </Container>
      )
    }
  }

  const handleClose = () => {
    history.push(getWelcomePath(program.name));
  }

  const allowAdd = program.configs.addCourses === "ON"

  const filteredCourses = courses.filter((course) => !course.slug.startsWith(appTutorial))

  const hasCourses = filteredCourses.length > 0

  return (
    errorDetails.message
      ? <ErrorComponent message={errorDetails.message} />
      :
      <Offline content={filteredCourses}>
        <HeaderContainer>
        <Icon onClick={handleClose} imageUrl={back} />
          <Title title={language.myCourses} />
          <Icon onClick={handleClose} imageUrl={cross} />
        </HeaderContainer>
        <Container variant={"contentWithFixedHeader"}>
          {(!allowAdd && !hasCourses)
            ? renderNoCourseContent()
            : <>
              {
                allowAdd && <CourseItemButton title={language.courses}
                  type="add"
                  speechText={language.addCourse}
                  onClick={handleAddCourseClick} />
              }
              {hasCourses && filteredCourses.map((course) => (
                <CourseItemButton key={course.id}
                  {...course}
                  onClick={() => handleCourseClick(course.slug)} />
              ))}
            </>
          }
          {
            errorDetails.slowConnexionError
              ?
              (<Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbar} />)
              : null
          }
        </Container>
      </Offline>

  );
};

export default MyCourses;
