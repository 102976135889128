import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";


export default makeStyles((theme: Theme) => {

    return createStyles({
        searchBar: {
            display: "flex",
            width: "100%",
            flexDirection:"row",
            paddingTop: "15px",
            paddingBottom:"15px",
            alignItems:"center"

        },
        button:{
            backgroundColor : "#388087",
            padding: "5px",
            borderRadius:" 10px 0px 0px 10px"
        }   
    })
})