import React, { useMemo } from 'react'
import { useCookies } from "react-cookie"
import { CookieSetOptions } from "universal-cookie/cjs"
import { getProgram } from 'utils/utils'

const TOKEN = "tokenfrontend"
const TOKEN_V1 = "token"
// The token cookie is the same for the whole application,
// no matter what the route

// eslint-disable-next-line no-restricted-globals
const options: CookieSetOptions = { path: `/${getProgram()}`, sameSite: 'strict', maxAge: 60 * 60 * 24 * 365 * 10 }
//const options: CookieSetOptions = { path: "/" + getProgram(), sameSite: 'strict', maxAge: 60 * 60 * 24 * 365 * 10, domain: self.location.hostname }

/**
 * Hook to manage token. It returns a tuple containing the value of the
 * token and functions to change and remove it.
 * It can be used like:
 *      const {token, setToken} = useToken()
 */

export const useToken = (): {
    token: string,
    tokenPayload: any,
    setToken: (sessionId: string) => void,
    removeToken: (tokenName ? : string) => void,
    migrateCookie: () => void
} => {
    const [cookies, setCookie, removeCookie] = useCookies()
    
    const tokenPayload = useMemo(() =>{
        if (cookies?.tokenfrontend !== undefined){
            return  cookies?.tokenfrontend && JSON.parse(atob(cookies.tokenfrontend.split('.')[1]))
        }else if(cookies?.token !== undefined){
            return cookies?.token && JSON.parse(atob(cookies?.token.split('.')[1]))
        }
    }
      , [cookies])

    const setToken = React.useCallback((token: string) => {
        setCookie(TOKEN, token, options)
    }, [setCookie])

    // Remove all the token cookies including from V1 app
    // If no token name is provided we remove V2 token by default
    const removeToken = React.useCallback((tokenName: string = TOKEN) => {
            removeCookie(tokenName, {...options})
            removeCookie(tokenName, {...options, path: `/`})
            removeCookie(tokenName, {...options, domain : window.location.hostname})
            removeCookie(tokenName, {...options, path: `/`, domain :  window.location.hostname})
    }, [removeCookie])

    // Change V1 token to V2
    const migrateCookie = React.useCallback(() => {
        if (cookies.token) {
            setToken(cookies.token)
            removeToken(TOKEN_V1)
        }
}, [cookies, setToken, removeToken])

    
    return { token: cookies.tokenfrontend ? cookies.tokenfrontend : cookies?.token, tokenPayload, setToken, removeToken, migrateCookie }
}
