import React, { useEffect, useState } from 'react'
import clsx from 'clsx'
import { Typography } from '@material-ui/core'
import { Speech } from 'components'
import { StyleProps } from 'types/styles'
import { useSmallScreen } from '../../hooks'
import useStyles from './Title.styles'

interface TitleProps {
    title: string,
    variant?: 'title' | 'subtitle'
    className?: string,
    style?: StyleProps,
    isSection?: boolean,
}

const Title: React.FC<TitleProps> = (props) => {
  const {
    title,
    variant = 'title',
    className,
    isSection,
    style={ align: 'center' }
  } = props

  const classes = useStyles(style)

  const [displayTitle,setDisplayTitle] = useState(title);

  const isSmallScreen = useSmallScreen()

  useEffect(() => {
    if(!isSection && isSmallScreen && title.length>10){
      setDisplayTitle(title.substring(0, 7)+'...')
    } else {
      setDisplayTitle(title)
    }
  }, [isSection, isSmallScreen, title])

  return (
    <div className={classes.container}>
      <Speech text={title} className={classes.title}>
        <Typography className={clsx(classes[variant], className)} id={variant}>
          {displayTitle}
        </Typography>
      </Speech>
    </div>
  )
}

export default Title
