import { makeStyles, Theme, createStyles } from '@material-ui/core'


export default makeStyles((theme: Theme) => {
    return createStyles({
        headerContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        icon: {
            "&:hover": {
                cursor: "pointer"
            }
        },
        profileHeader: {
            display: "flex",
            justifyContent: "flex-start",
            flexWrap: "wrap"
        },
        sectionContainer: {
            padding: "15px 0",
            textAlign: "left"
        },
        avatarContainer: {
            marginRight: "15px"
        },
        avatar: {
            height: "5.35rem",
            width: "5.45rem",
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: theme.palette.primary.light
        },
        nameAndPhone: {
            display: "flex",
            flexDirection: "column",
            margin: "15px 0 0 15px"
        },
        phoneNumber: {
            fontFamily: theme.typography.fontFamily,
            fontSize: ".9rem",
            color: theme.palette.primary.main,
            letterSpacing: ".5px"
        },
        editButton: {
            background: theme.palette.primary.light,
            color: theme.palette.primary.main,
            fontSize: "10px",
            height: "22px",
            width: "unset",
            borderRadius: "4px",
            margin: "18px 0 0"
        },
        sectionTitleContainer: {
            marginTop: "5px"
        },
        sectionTitle: {
            fontFamily: theme.typography.fontFamily,
            fontSize: ".95rem",
            color: theme.palette.primary.main,
            fontWeight: 600,
            lineHeight: "20px",
            '&::first-letter': {
                textTransform: "capitalize"
            }
        },
        formContainer: {
            display: "flex",
            flexDirection: "column",
            paddingTop: "15px"
        },
        formRow: {
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            fontFamily: theme.typography.fontFamily,
            fontWeight: 500,
            marginBottom: "10px"
        },
        formCol: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
        },
        inputLabel: {
            flex: 1,
            color: theme.palette.secondary.dark,
            fontSize: "14px",
            lineHeight: "20px",
            margin: "5px 15px 5px 0",
            '&::first-letter': {
                textTransform: "capitalize"
            }
        },
        input: {
            flex: 1,
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
            fontSize: "15px",
            borderRadius: "4px",
            padding: "8px 10px",
            marginBottom: "5px",
            minHeight: "19px"
        },
    })
})