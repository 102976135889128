import React, { useEffect, useState } from "react"
import { useSelector } from 'react-redux'
import { selectLanguage, selectLanguageObject } from 'pages/Login/LoginSlice'
import { NotificationTypes } from "constants/notifications"
import {  NewSessionBody, PriceMessageBody } from "types/notifications"
import { Button, Speech } from "components"
import { StyleProps } from "types/styles"
import useStyles from './MessageBody.styles'
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import DescriptionIcon from '@material-ui/icons/Description';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import Map from "components/Map/Map"

export interface MessageBodyProps {
  type: string,
  body: string,
  style?: StyleProps,
}

const MessageBody: React.FC<MessageBodyProps> = (props) => {
  const {
    type,
    body,
    style={},
  } = props

  const dictionary = useSelector(selectLanguageObject)
  const language = useSelector(selectLanguage)

  const classes = useStyles(style)

  const [headers, setHeaders] = useState([''])
  const [content, setContent] = useState<JSX.Element | null>(null)

  // If a body contains a url, make it clickable
  const renderBody = (body : string)=>{
    const urlPattern = new RegExp(/^(?:(?:https?|http):\/\/)?(?:www\.)?[a-z0-9-]+(?:\.[a-z0-9-]+)+[^\s]*$/i)
    const arrayWords = body.split(" ")
    return arrayWords.map((word, index) => {
      if (urlPattern.test(word)) {
        return <a href={word} key={index}>{word}</a>;
      } else {
        if(index === 0){
          return word + " "
        }else{
          return " "+word+ " "
        }
      }
    });
  }
  useEffect(() => {
    let content: JSX.Element;

    switch (type) {
      case NotificationTypes.priceChange:
        setHeaders([`${dictionary.product}`, `${dictionary.price} (${JSON.parse(body)[0].price.currency})`])
        content = JSON.parse(body).map((messageBody: PriceMessageBody, index: number) => {
            return (
              <div className={classes.mainText} key={index}>
                <div className={classes.text}>
                  <Speech text={messageBody.product}>
                     {messageBody.product}
                  </Speech>
                </div>
                <div className={`${classes.contentBoldText} ${classes.boldText}`}>
                  <Speech text={Intl.NumberFormat(language, { maximumFractionDigits: 6 }).format(parseFloat(messageBody.price.value))}>
                    {Intl.NumberFormat(language, { maximumFractionDigits: 6 }).format(parseFloat(messageBody.price.value))}
                  </Speech>
                </div>
              </div>
            )
          }
        )
        setContent(content)
        break
      case NotificationTypes.simpleMessage:
        setHeaders([])
        
        content = (
          <div className={classes.mainText}>
            <div className={classes.text}>
              <Speech text={body}>
              {renderBody(JSON.parse(body))}
              </Speech>
            </div>
          </div>
        )
        setContent(content)
        break
        case NotificationTypes.newSession:
          const message : NewSessionBody= JSON.parse(body)
          const date = new Date(message.date)
          setHeaders([dictionary.newSessionTitle, message.name])  
          content = (
            <div className={classes.mainText}>
              <div className={classes.text}>
                {message.date && 
                <div className={classes.flex}> 
                  <EventAvailableIcon/>
                  {date.toLocaleString("en-US")}
                 </div>
                }
                {message.description && 
                <div className={classes.flex}> 
                  <DescriptionIcon/>
                  {message.description}
                 </div>
                }
                {message.message && 
                <div className={classes.flex}> 
                  <HelpOutlineIcon/>
                  {renderBody(message.message)}
                 </div>
                }
                {message.gpsPoint && 
                <div className={classes.map}>
                <Map gpsValues={[message.gpsPoint?.coordinates[1], message.gpsPoint?.coordinates[0]]} />
                <Button className={classes.button} label={dictionary.seeLocation} onClick={()=>{window.open(`https://maps.google.com/?q=${message.gpsPoint?.coordinates[1]},${message.gpsPoint?.coordinates[0]}`)}}/>
                </div>}
              </div>
            </div>
          )
          setContent(content)
          break
      default:
        setHeaders([])
    }
  }, [body, classes, dictionary, language, type])

  return (
    <div>
      <div className={classes.mainText}>
        { headers.map((header, index) => {
            return (
              <div key={index} className={`${classes.header} ${classes.boldText}`}>
                <Speech text={header}>
                  { header }
                </Speech>
              </div>
            )
        })}
      </div>

      { content }
    </div>
  )
}

export default MessageBody