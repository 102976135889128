import { Typography } from "@material-ui/core"
import { Speech } from "components"
import React, { useState } from "react"
import clsx from "clsx"
import useStyles from './ErrorText_Styles'
import { useSmallScreen } from "hooks"
import { useProgram } from "program"
import { style } from '../../style'

interface ErrorTextProps {
    text: string,
    className?: string
}

//component that return the out of an error
const ErrorText: React.FC<ErrorTextProps> = ({ text, className }) => {
    const program = useProgram()
    const classes = useStyles()
    const isSmallScreen = useSmallScreen()
    const textClass = isSmallScreen 
    ? clsx(classes.errorText, classes.smallText)
    : classes.errorText

    let [color] = useState(program.configs?.palette?.primary?.main || style.palette.black);

    return (
        <Speech text={text}>
            <Typography className={clsx(textClass, className)} style={{ color: color}}>
                {text}
            </Typography>
        </Speech>
    )
}

export default ErrorText