
export const LanguagesList = [
  'ci-LK',
  'en-GB',
  'en-IN',
  'en-US',
  'en-ZM',
  'fr-CI',
  'fr-FR',
  'fr-GH',
  'fr-RE',
  'id-ID',
  'ma-MY',
  'si-LK',
  'ta-LK',
  'th-TH',
  'vi-VN'
]
