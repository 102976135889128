import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {

    return createStyles({
        errorText: {
            padding: "1.25rem",
            fontSize: "1.6875rem",
            fontWeight: 700,
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.secondary.main
        },
        smallText: {
            fontSize: "1rem"
        }
    })
})