import { makeStyles, Theme, createStyles } from '@material-ui/core'


export default makeStyles((theme: Theme) => {
    return createStyles({
        inputContainer: {
            width: "100%",
            margin: "5px 0"
        },
        inputLabel: {
            flex: 1,
            color: theme.palette.primary.main,
            textAlign: "left",
            lineHeight: "20px",
            '&::first-letter': {
                textTransform: "capitalize"
            }
        },
        input: {
            width: "100%",
            color: theme.palette.primary.main,
            fontSize: "15px"
        },
        inputAdornment: {
            marginRight: "8px",
            color: theme.palette.primary.main,
            fontSize: "15px"
        },
        inputErrorContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },
        inputError: {
            color: "#dc3545",
            fontSize: "13px",
            padding: "5px",
            textAlign: "left"
        }
    })
})