import {
    Close as CloseIcon
} from "@material-ui/icons";
import clsx from "clsx";
import {
    Button, Container, HeaderContainer,
    IconButton, Speech, Text, Title
} from 'components';
import { selectLanguageObject } from "pages/Login/LoginSlice";
import React from 'react';
import { useSelector } from "react-redux";
import completed from "resources/icons/completed.svg";
import useStyles from "./SuccessPage_Styles"

//This page will appear at the end of a survey be it measurement impact and transaction

interface SurveySuccesPageProps {
    onDone: () => void
    message: string
}

const SurveySuccess: React.FC<SurveySuccesPageProps> = (props) => {
    const {onDone, message} = props
    const classes = useStyles()
    const language = useSelector(selectLanguageObject)
    return (
        <>
            <HeaderContainer className={classes.header}>
                <IconButton onClick={onDone}>
                    <CloseIcon fontSize="large" />
                </IconButton>
                <Speech text={language.surveyCompleted} className={classes.title}>
                    <Title title={language.surveyCompleted} />
                </Speech>
            </HeaderContainer>
            <Container>
                <Container variant="content">
                    <Text variant="title">{language.thankyou}</Text>
                    <Text>{message}</Text>
                    <img src={completed} alt="completed" />
                </Container>
                <Button label={language.done}
                    onClick={onDone}
                    className={clsx(classes.button, classes.doneButton)} />
            </Container>
        </>
    )
}

export default SurveySuccess
