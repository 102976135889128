import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
  return createStyles({
    main: {
      display: "flex",
      flexDirection: "column",
      height: "100%"
    }
  })
})