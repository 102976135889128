import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"

  

const TransactionsSVG : React.FC<SVGPropsInterface> = ({width, height, stroke, fill}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg  aria-hidden="true" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 0 195 174"  xmlns="http://www.w3.org/2000/svg">
<path d="M3.19781 150.088C3.52704 150.088 3.85184 150.01 4.14814 149.866L48.6133 128.226C50.604 127.033 52.8713 126.395 55.1847 126.373H94.2964C96.6844 126.319 98.6642 128.235 98.7191 130.652C98.7717 133.07 96.8799 135.074 94.4919 135.13C94.426 135.132 94.3623 135.132 94.2964 135.13H64.9625C63.751 135.13 62.7677 136.125 62.7677 137.352C62.7677 138.578 63.751 139.574 64.9625 139.574H94.2964C99.1097 139.651 103.074 135.763 103.148 130.89C103.225 126.019 99.3863 122.006 94.5731 121.929C94.4809 121.926 94.3886 121.926 94.2964 121.929H55.1847C52.1602 121.951 49.1949 122.764 46.5743 124.293L2.23865 145.864C1.14782 146.4 0.691314 147.728 1.22027 148.833C1.58461 149.597 2.35068 150.084 3.18911 150.086L3.19781 150.088Z" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : defaultTheme.fill}/>
<path d="M96.4431 153.861C95.6837 154.443 94.7618 154.761 93.8093 154.768H63.3319C47.1296 154.768 33.8312 159.609 23.8008 169.157C22.9163 169.997 22.8746 171.404 23.7042 172.299C24.5339 173.195 25.9232 173.237 26.8077 172.397C35.9953 163.647 48.2841 159.223 63.3319 159.223H93.8093C95.6837 159.216 97.5076 158.603 99.0154 157.474L143.832 124.293L144.086 124.071C145.904 122.326 146.957 119.92 147.012 117.385C147.008 112.514 143.11 108.568 138.299 108.561C136.701 108.528 135.129 108.974 133.784 109.845L105.909 126.017C104.858 126.628 104.494 127.986 105.097 129.05C105.699 130.117 107.042 130.486 108.093 129.874L136.086 113.623C136.745 113.194 137.517 112.978 138.299 113.005C140.687 113.009 142.62 114.967 142.622 117.385C142.556 118.684 142.008 119.909 141.086 120.815L96.4431 153.861Z" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : defaultTheme.fill}/>
<path d="M69.385 70.0936C72.798 69.9803 76.02 68.4761 78.3246 65.9252C82.734 61.259 83.3179 54.5929 79.6415 51.0377C78.1008 49.5845 76.0596 48.8002 73.9547 48.8468C70.5418 48.9579 67.3198 50.46 65.0152 53.0108C60.608 57.6771 60.0219 64.332 63.6982 67.8983C65.2368 69.3538 67.2802 70.1425 69.385 70.0936ZM68.191 56.0816C69.6703 54.4129 71.7445 53.4086 73.9569 53.2908C74.9292 53.2531 75.8796 53.5953 76.6082 54.2485C78.498 56.0795 77.833 60.0257 75.1553 62.8521C73.676 64.523 71.5996 65.5296 69.3872 65.6496C68.4149 65.6852 67.4668 65.343 66.7381 64.6898C64.8461 62.8566 65.5111 58.9147 68.191 56.0816Z" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : "none"}/>
<path d="M50.8518 35.2523C48.6372 35.2523 46.8418 37.0699 46.8418 39.3119C46.8418 41.5539 48.6372 43.3715 50.8518 43.3715C53.0664 43.3715 54.8617 41.5539 54.8617 39.3119C54.8617 38.2054 54.414 37.1455 53.6239 36.3789C52.8776 35.6545 51.8856 35.2523 50.8518 35.2523Z" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : defaultTheme.fill}/>
<path d="M130.331 77.7705L118.132 66.7871L130.935 59.6967L143.77 55.837L192.162 47.7667C193.358 47.5667 194.168 46.4246 193.97 45.2136C193.775 44.0004 192.645 43.1804 191.449 43.3804L142.921 51.4752C142.831 51.4907 142.741 51.5108 142.653 51.5374L129.46 55.5037C129.309 55.5482 129.159 55.6082 129.021 55.6837L94.7202 74.6975C92.6088 75.8529 89.975 75.0574 88.8337 72.9198C88.6976 72.6643 88.5856 72.3977 88.5022 72.1199C87.9777 69.9601 89.0179 67.7226 90.9977 66.7582L114.82 50.3864C115.819 49.6887 116.069 48.3066 115.38 47.2956C114.93 46.6357 114.164 46.2713 113.376 46.3446L99.2217 47.7333L78.0679 27.2353L99.3929 19.4405C102.378 18.0673 105.71 17.6629 108.93 18.2828L136.012 26.2998C148.261 29.9262 161.586 27.6997 177.946 19.2872C179.024 18.7272 179.449 17.3873 178.896 16.2963C178.345 15.2098 177.033 14.7765 175.958 15.3253C160.66 23.1935 148.36 25.3244 137.247 22.0336L110.139 14.001C106.043 13.09 101.77 13.5233 97.9334 15.2364L74.5212 23.7934L51.0035 1L13.0593 41.1273L92.3344 117.958L130.331 77.7705ZM88.7107 62.9763C84.9729 64.9717 83.138 69.3535 84.321 73.4598C85.7477 78.1193 90.6355 80.7235 95.2381 79.2792C95.7912 79.1059 96.3246 78.8771 96.8316 78.5971L106.5 73.2421L109.219 75.8773L109.162 80.8813L94.4063 96.4865L89.4591 96.7086L34.1493 43.1116L34.1669 38.0832L48.9141 22.4935L53.9336 22.298L92.9204 60.0833L88.7107 62.9763ZM19.2662 40.9895L51.1396 7.28385L100.346 54.9749L96.6318 57.5302L55.6236 17.7829L46.9716 18.1206L29.7817 36.2922L29.751 45.0025L87.7758 101.242L96.3531 100.846L113.528 82.6856L113.613 74.9619L113.589 73.9664L110.534 71.0089L114.046 69.0669L124.015 78.0326L92.194 111.672L19.2662 40.9895Z" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : defaultTheme.fill}/>
</svg>

  )
}

export default TransactionsSVG
