import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {StyleProps} from "types/styles";

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: (props: StyleProps) => props.width,
      maxWidth: (props: StyleProps) => props.maxWidth,
    }
  })
})