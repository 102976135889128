import React from 'react'
import { Container, InputAdornment, TextField} from '@material-ui/core';
import useStyles from './MessageSearchBar.styles';
import { Search } from '@material-ui/icons';



interface SearchBarProps {
  handleInputChange : (e : React.ChangeEvent<HTMLInputElement>) => void;
}

const MessageSearchBar: React.FC<SearchBarProps> = ({handleInputChange}) => {

  const classes = useStyles(); 

  return (
    <Container className={classes.searchBar}>
      <TextField 
        id="search-bar"  
        type="search" 
        variant="outlined"
        onChange={handleInputChange}
        size="small"
        fullWidth
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search fontSize='default' style={{ color: '#388087' }}/>
            </InputAdornment>
          ),
        }}
      /> 
    </Container>
  )
}

export default MessageSearchBar
