import React, { useEffect, useState } from 'react'
import { Typography } from "@material-ui/core";
import { AppState } from "redux/rootReducer";
import { AppThunkDispatch } from "redux/store";
import {
  ErrorComponent,
  ChapterButton,
  Container,
  Dialog,
  HeaderContainer,
  Icon,
  Loader,
  Offline,
  Popup,
  Snackbar,
  Speech,
  Title
} from "components";
import { useToken } from "cookies";
import { useServerErrorHandler } from "hooks";
import { selectLanguageObject, selectLoggedUser } from "pages/Login/LoginSlice";
import {
  fetchAllowedChapters,
  fetchChatGroupDetails,
  fetchCourseBySlug,
  selectCourseBySlug,
  selectChatGroupBySlug,
  //selectError,
  selectIsFetchingAllowedChapters,
  selectIsLoading,
  setCourseCompletion
} from "pages/MyCourses/MyCourses.slice";
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps, useHistory } from "react-router";
import back from "resources/icons/back.svg";
import chat from "resources/icons/chat.svg";
import manDancing from "resources/icons/manDancing.svg";
import peacock from "resources/icons/peacock.svg";
import { getChapterPath, getMyCoursesPath, getCourseSummaryPath, getWelcomePath, appTutorial } from "routes";
import useStyles from "./Course.styles";
import { selectError, selectOnlineState } from "pages/Impact/Impact.slice";
import { handleErrorCodes } from "../../utils/httpErrorCodes"
import { useProgram } from '../../program'
import { ErrorDetails, ErrorType } from "types";
import placeholderSrc from "../../resources/icons/suttiLogo192.png";

interface CourseParams {
  courseSlug: string;
}

type CourseProps = RouteChildrenProps<CourseParams>;

//this page dispay the content of chapter : text, video, image and audio
const Course: React.FC<CourseProps> = (props) => {
  const { match } = props

  if (!match) {
    throw new Error("Undefined match");
  }

  const program = useProgram()

  const { courseSlug } = match.params
  const classes = useStyles()
  const { token } = useToken()
  const dispatch = useDispatch<AppThunkDispatch>()

  const isLoading = useSelector(selectIsLoading)
  const isFetchingAllowedChapters = useSelector(selectIsFetchingAllowedChapters)
  const course = useSelector((state: AppState) => selectCourseBySlug(state, courseSlug))
  const chatGroup = useSelector((state: AppState) => selectChatGroupBySlug(state, courseSlug))
  //const error = useSelector(selectError)
  const isOnline = useSelector(selectOnlineState)
  const language = useSelector(selectLanguageObject)
  const loggedUser = useSelector(selectLoggedUser)
  const error = useSelector(selectError)
  const handleServerError = useServerErrorHandler()

  const history = useHistory()

  const [snackbarState, setSnackbarState] = useState(true)
  const [errorDetails, setErrorDetails] = useState({} as ErrorDetails)
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [openNoChatDialog, setOpenNoChatDialog] = useState(false);
  
  const handleCloseSnackbarSlowConnection = () => {
    setSnackbarState(false)
  }
  const { imageUrl, title, chapters } = course
  const [imgSrc, setImgSrc] = useState(placeholderSrc || imageUrl);

  useEffect(() => {
    if (imageUrl !== undefined) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImgSrc(imageUrl);
      };
    }
  }, [imageUrl]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch the data
        await handleServerError(dispatch(fetchCourseBySlug({ courseSlug, programId: program.id, token })));
        await handleServerError(dispatch(fetchAllowedChapters({ courseSlug, token, userId: loggedUser?.userId || "" })));
        await handleServerError(dispatch(fetchChatGroupDetails({ courseSlug, token, userId: loggedUser?.userId || "" })));
      } catch (error) {
        // Handle errors
        if ((error as ErrorType).httpStatus !== 503) {
          setErrorDetails(handleErrorCodes(error as ErrorType, language.connectionError))
      } 
    };
  }
    // Fetch the data
    if (isOnline) {
      fetchData();
    }
  
  }, [dispatch, courseSlug, token, handleServerError, isOnline, language, loggedUser, program] );
  

  useEffect(() => {
    if (error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, language.connectionError))
    }
  }, [error, language.connectionError]);






  const handleClick = async (id: string) => {
    const currentSectionId = course.chapters![id].currentSection
    history.push(getChapterPath(program.name, courseSlug, id, currentSectionId))
  }

  const handleClose = () => {
    const path = window.location.pathname
    const isTutorial = path.includes(appTutorial)
    if(isTutorial){
      history.push(getWelcomePath(program.name))
    }else{
      history.push(getMyCoursesPath(program.name))
    }
  }

  const handleChatClick = () => {
    if ((chatGroup !== undefined) && chatGroup.url) {
      setOpenChatDialog(true)
    } else {
      setOpenNoChatDialog(true)
    }
  }

  const renderChapterContent = () => {
    if (isLoading || isFetchingAllowedChapters) {
      // Display loading indicator while data is being fetched
      return <Loader />;
    }
    const chaptersToMap = chapters
      ? Object.keys(chapters)
        .map(key => chapters[key])
        .sort((a, b) => a.order - b.order)
      : []

    if (chaptersToMap.length > 0) {
      return chaptersToMap.map(({ title, imageUrl, id,
        visitedSections, sections, completed, content }, index) => {
        const totalSections = sections ? Object.keys(sections).length : 0
        const percentageCompletion = visitedSections
          ? (visitedSections.length / totalSections) * 100
          : 0

        const isDisabled = course.allowedChapters && !course.allowedChapters.includes(id.toString())

        const chapterProgression = visitedSections === undefined ? 0
          : (visitedSections?.length)

        return (
          <ChapterButton title={title}
            imageUrl={imageUrl}
            percentageCompletion={percentageCompletion}
            completed={completed}
            onClick={() => handleClick(id)}
            disabled={isDisabled}
            chapterProgression={chapterProgression}
            totalSections={totalSections}
            description={content} />
        )
      })
    }
    
    else {
      const textToDisplay = language.noChapters.replace("$param", course.title)
      return (errorDetails.message
      ? <ErrorComponent message={errorDetails.message} path={getWelcomePath(program.name)} />
      : <Title title={textToDisplay} />)
    }
  }

  const handleCloseDialog = () => {
    dispatch(setCourseCompletion(courseSlug))
  }

  const allChapterCompleted = () => {
    const chapterKeys = chapters ? Object.keys(chapters) : []
    return (chapterKeys.length > 0)
      ? chapterKeys.every(key => chapters![key].completed)
      : false
  }

  const showDialog = !course.shownCompletionDialog && allChapterCompleted()

  const handleCloseChatDialog = () => {
    setOpenChatDialog(false);
  }

  const handleCloseNoChatDialog = () => {
    setOpenNoChatDialog(false);
  }



  const gotoSummary = () => {
    const path = getCourseSummaryPath(program.name, courseSlug)
    history.push(path)
  }


  const renderDialogContent = () => {
    const customMessage = language.congratsMessage.replace("$param", course.title)
    const congratsMessage = language.congratulations
    const congratsInfo = language.congratsInfo
    const speechText = `${congratsMessage} ${customMessage} ${congratsInfo}`
    return (
      <Dialog open={showDialog} onClose={handleCloseDialog} className={classes.dialogContent}>
        <Speech text={speechText}>
          <>
            <Typography>{congratsMessage}</Typography>
            <Typography>{customMessage}</Typography>
            <Typography>{congratsInfo}</Typography>
          </>
        </Speech>
        <div className={classes.icons}>
          <Icon imageUrl={manDancing} />
          <Icon imageUrl={peacock} />
        </div>
      </Dialog>
    )
  }

  const renderChatDialogContent = () => {
    const title = `${language.redirectToChat} ${chatGroup.provider}`
    const explanation = `${language.chatRedirectDisclaimer} ${chatGroup.provider}. ${language.chatRedirectConsent}`

    return (
      <Popup title={title}
        buttonLabel={language.goChat}
        explanationLabel={explanation}
        open={openChatDialog}
        onClose={handleCloseChatDialog}
        onSubmit={() => {
          window.open(chatGroup.url)
          handleCloseChatDialog()
        }}
        onSubmitWithExtraAction={() => { }} />
    )
  }


  const renderNoChatDialogContent = () => {
    return (
      <Dialog open={openNoChatDialog} onClose={handleCloseNoChatDialog} className={classes.dialogContent}>
        <Speech text={language.noChat}>
          <>
            <Typography>{language.noChat}</Typography>
          </>
        </Speech>
      </Dialog>
    )
  }

  return (
    errorDetails.message
      ? <ErrorComponent message={errorDetails.message} path={getWelcomePath(program.name)} />
      :
      <Offline content={chapters}>
        <HeaderContainer className={classes.headContainer} >
          <Icon onClick={handleClose} className={classes.icon} imageUrl={back} />
          <div className={classes.titleContainer} onClick={gotoSummary}>
            <img src={imgSrc} alt={title} aria-hidden="true" className={classes.image} />
            <Title title={title} />
          </div>
          <Icon onClick={handleChatClick} className={classes.icon} imageUrl={chat} />
        </HeaderContainer>
        <Container variant="contentWithFixedHeader">
            {renderChapterContent()}
          {renderDialogContent()}
          {
            (chatGroup !== undefined && chatGroup.provider)
              ? renderChatDialogContent()
              : renderNoChatDialogContent()
          }
          {
            errorDetails.slowConnexionError &&
            <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection} />
          }
        </Container>
      </Offline>
  )
};


export default Course;

