import { TextField } from '@material-ui/core';
import { CheckButton, Select, SelectorButton } from 'components';
import { Option } from 'components/Roulette';
import { useSmallScreen } from 'hooks';
import React from 'react';
import { useSelector } from "react-redux"
import { Answer, AnswerType, Value } from 'types';
import clsx from "clsx"
import useStyles, { useOutlineStyles } from "./Answers_Styles";
import { selectLanguage } from "pages/Login/LoginSlice"

export interface AnswersProps {
    answers?: Array<Answer>
    initialValue?: Value
    type: AnswerType
    onChange: (value: Value) => void
}

//Answer component is used for survey and impact module
//handle  5 type different of question : text, number, list, checkbox and radio
const Answers: React.FC<AnswersProps> = (props: AnswersProps) => {
    const { answers, type, onChange, initialValue } = props
    const classes = useStyles()
    const outlineClasses = useOutlineStyles()
    const isSmallScreen = useSmallScreen()
    const language = useSelector(selectLanguage)

    switch (type) {
        case "text":{
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.target.value)
            }
            return(
                <TextField
                value={initialValue}
                onChange={handleChange}
                variant={"outlined"}
                type={type}
               /* autoFocus*/
                inputProps={{
                    min: 0
                }}
                InputProps={{
                    classes: {
                        ...outlineClasses,
                        root: isSmallScreen
                        ? clsx(outlineClasses.root, classes.smallRoot)
                        : outlineClasses.root
                    }
                }} />
            )
            }
        case "number": {
            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

                let thousandSeparator = Intl.NumberFormat(language).format(11111).replace(/\p{Number}/gu, '');
                let decimalSeparator = Intl.NumberFormat(language).format(1.1).replace(/\p{Number}/gu, '');

                /**
                 * Parse a localized number to a float.
                 * @param {string} stringNumber - the localized number
                 * @param {string} locale - [optional] the locale that the number is represented in. Omit this parameter to use the current locale.
                 */
                function parseLocaleNumber(stringNumber: string) {
                    if (stringNumber !== "")
                    {
                        const parsedValue = parseFloat(stringNumber
                        .replace(new RegExp('\\' + thousandSeparator, 'g'), '')
                        .replace(new RegExp('\\' + decimalSeparator), '.'));
                        if (isNaN(parsedValue)) return null; else return parsedValue;
                    }
                    else return null;
                }
                if (e.target.value === "")
                {
                    e.target.value = ""
                }
                else
                {
                    let numVal = Intl.NumberFormat(language, { maximumFractionDigits: 6 }).format(Number(parseLocaleNumber(e.target.value)));
                    if (e.target.value.endsWith(decimalSeparator)) numVal += decimalSeparator;
                    e.target.value = numVal
                }
                onChange(e.target.value)
            }
            return (
                <TextField
                value={initialValue}
                onChange={handleChange}
                variant={"outlined"}
           //     type={type}
               /* autoFocus*/
                inputProps={{
                    min: 0
                }}
                InputProps={{
                    classes: {
                        ...outlineClasses,
                        root: isSmallScreen
                        ? clsx(outlineClasses.root, classes.smallRoot)
                        : outlineClasses.root
                    }
                }} />
            )
        }
        case "checkbox": {
            const values = initialValue as string[] || []
            const handleChange = (label: string) => {
                const hasValue = values.includes(label)
                const updatedSelectedValues = hasValue
                    ? values.filter(val => val !== label)
                    : values.concat(label)

                onChange(updatedSelectedValues)
            }
            return answers
                ? (
                    <div className={classes.checkButtons}>
                        {answers.map(({ label }) =>
                            <CheckButton key={label}
                                checked={values.includes(label)}
                                label={label}
                                onChange={() => handleChange(label)} />)}
                    </div>
                ) : <div />
        }
        case "dropdown": {
            const menuOptions: Array<Option> = answers
                ? answers.map(({ cmsId, label }) => ({ id: cmsId, value: label }))
                : []

            const handleChange = (newId: string) => {
                const selectedValue = menuOptions
                    .find(option => option.id === newId)!.value
                onChange(selectedValue)
            }

            const selectedId = menuOptions
                .find(option => option.value === initialValue)?.id

            return <Select
                menuOptions={menuOptions}
                selectedId={selectedId}
                onChange={handleChange} />
        }
        case "radio": {
            const handleClick = (newValue: string) => {
                onChange(newValue)
            }

            return answers
                ? (
                    <div>
                        {answers.map(({ label }) => <SelectorButton
                            key={label}
                            value={label}
                            selected={(initialValue as string) === label}
                            onClick={() => handleClick(label)} />)}
                    </div>
                ) : <div />
        }
        default:
            throw new Error("Undefined answer type")
    }
}

export default Answers
