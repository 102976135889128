import { ErrorType, ErrorDetails } from "types/common";

export const handleError = (error: any) => {
  if (error instanceof TypeError) {
    return {
      httpStatus: 503,
      payload: {
        message: error.message
      }
    }
  }
  if (error.httpStatus === 408) {
    return {
      httpStatus: error.httpStatus,
      payload: {
        message: error.payload.statusText
      }
    }
  }
  if (error.httpStatus === 500) {
    return {
      httpStatus: error.httpStatus,
      payload: {
        message: error.payload.message.name
      }
    }
  }
  return error
}

export const handleErrorCodes = (error:ErrorType | null, connectionError: string): ErrorDetails => {
  switch (error?.httpStatus) {
    case 500:
      return { slowConnexionError: false, message: connectionError };
    case 408:
      navigator.serviceWorker.controller?.postMessage({
        name: 'offlineMode',
        value: true
      });
      return { slowConnexionError: true,  message: null };
    default:
      return { slowConnexionError: false, message: error?.payload.message ?? null };
  }
}
