import React from "react"
import { Snackbar as MUISnackbar } from "@material-ui/core"

interface SnackbarProps {
    open: boolean
    message: string
    onClose: () => void
}

//popup window to inform participant ans set a specific message
const Snackbar: React.FC<SnackbarProps> = ({ open, message, onClose }) => {
    return (
        <MUISnackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
            }}
            open={open}
            autoHideDuration={5000}
            message={message}
            key={message}
            onClose={onClose}
        />
    )
}

export default Snackbar