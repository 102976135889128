import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";


export default makeStyles((theme: Theme) => {
 
  
  return createStyles({
    text: {
        fontFamily: theme.typography.fontFamily,
        fontSize: ".9rem",
        color: theme.palette.primary.main,
        letterSpacing: ".5px",
        textAlign:"center"
    },

  })
})
