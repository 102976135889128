import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getService} from 'api'
import {handleError} from '../utils/httpErrorCodes'
import { ErrorType,ProgramConfig } from 'types'
import { AppState } from '../redux/rootReducer'


interface ProgramsParams {
}
export const fetchPrograms : AsyncThunk<ProgramConfig, ProgramsParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'programs/all',
  async (_, {rejectWithValue}) => {
    try {
      const url = `programs`
      return await getService(null, url)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface ProgramsState {
  programs: Array<ProgramConfig>;
  isLoading: boolean;
  error: ErrorType | null;
}

const initialState: ProgramsState = {
  programs: [],
  isLoading: true,
  error: null
}

const ProgramsSlice = createSlice({
  name: 'programs',
  initialState,
  reducers: {
    setPrograms(state, {payload}: PayloadAction<Array<ProgramConfig>>) {
    state.programs = payload
  },},
  extraReducers: {
    [fetchPrograms.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchPrograms.fulfilled.type]: (state, {payload}: PayloadAction<Array<ProgramConfig>>) => {
      state.programs = payload
      state.isLoading = false
      state.error = null
    },
    [fetchPrograms.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    },
  }
})
export default ProgramsSlice.reducer

export const { setPrograms } = ProgramsSlice.actions

export const selectPrograms = (state: AppState): Array<ProgramConfig> => {
  return state.programs.programs
}

export const selectIsLoading = (state: AppState): boolean => {
  return state.programs.isLoading
}