import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        header:{
            display: "flex",
            alignItems: "center"
        },
        title: {
            flex: 1
        },
        button:{
            width: "15.625rem",
            background: theme.palette.secondary.contrastText,
            color: theme.palette.primary.main,
            marginBottom: "0.75rem"
            
        },
        survey: {
            fontFamily: "cursive",
            fontSize: "2.25rem",
            color: theme.palette.secondary.contrastText,
            transform: "rotate(-20deg)",
            fontWeight: 600
        },
        text: {
            minWidth: "auto"
        }
    })
})