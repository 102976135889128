

import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"
import React from "react"

const ChatSVG: React.FC<SVGPropsInterface> = ({width, height,stroke, fill}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg aria-hidden="true" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 0 30 30"  fill="none" >
        <path d="M14 23C14.7886 23.0782 15.157 23 16 23C22.0751 23 27 20.0938 27 13.9914C27 7.88911 22.0751 5 16 5C9.92487 5 5 7.88911 5 13.9914C5 18.6669 7.05723 21.0618 10.1526 22.171" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : "none"} stroke-width="2" stroke-linecap="round"/>
        <path d="M14 23.1631C11.5815 28.1157 8 27.082 8 27.082C8 27.082 9.74194 24.6766 10.1526 22.171" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : "none"} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>

    
  )
}

export default ChatSVG
