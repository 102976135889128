import { createOffline } from "@redux-offline/redux-offline";
import offlineConfig from "@redux-offline/redux-offline/lib/defaults";
import { OfflineAction } from "@redux-offline/redux-offline/lib/types";
import { Action, configureStore, getDefaultMiddleware, StoreEnhancer } from '@reduxjs/toolkit';
import { isDevelopment } from 'api/config';
import localForage from "localforage";
import {
    FLUSH, PAUSE,
    PERSIST, persistReducer, persistStore,
    PURGE, REGISTER, REHYDRATE
} from 'redux-persist';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import appReducer, { AppState } from './rootReducer';
//import { getProgram } from "utils/utils";

//This page handle the store off the app

const discard = (error: any, _action: OfflineAction, _retries: number) => {
    const { request, response } = error;
    if (!request) throw error; // There was an error creating the request
    if (!response) return false; // There was no response
    return 400 <= response.status && response.status < 500;
  };

export const {
    middleware: offlineMiddleware,
    enhanceReducer: offlineEnhanceReducer,
    enhanceStore: offlineEnhanceStore
} = createOffline({
    ...offlineConfig,
    discard,
    persist: undefined
});


const persistConfig = {
    key: "root",
    version: 1,
    storage: localForage,
    whitelist: ["programs", "myCourses", "user", "impact", "transactions", "myTermsAndConditions", "userProfile", "notifications", "offline"],
    stateReconciler: autoMergeLevel2
}

 const persistedReducer = persistReducer(
    persistConfig,
    offlineEnhanceReducer(appReducer));

const store = configureStore({
    reducer: persistedReducer,
    devTools: isDevelopment,
    middleware: getDefaultMiddleware({
        serializableCheck: {
            ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
   //         ignoredActionPaths: ['payload.mediaList'],

        }
    }).prepend(offlineMiddleware),
    preloadedState: {},
    enhancers: (defaultEnhancers) => [
        offlineEnhanceStore as StoreEnhancer,
        ...defaultEnhancers],
})

export const persistor = persistStore(store)

if (isDevelopment && module.hot) {
    module.hot.accept('./rootReducer', () => {
        const newRootReducer = require('./rootReducer').default
        store.replaceReducer(newRootReducer)
    })
}

export type AppDispatch = typeof store.dispatch

/**
 * This is the ThunkAction specific to the application. This type should 
 * be used everywhere an async action is defined.
 * 
 * @template A the final action type of the returned object
 */
export type AppThunk<A> = ThunkAction<Promise<A>, AppState, unknown, Action<string>>

/**
 * This is the ThunkDispatch specific to the application. This type should
 * be used everywhere an async dispatch is defined.
 */
export type AppThunkDispatch = ThunkDispatch<AppState, null, Action<string>>

export default store