import {
    KeyboardArrowLeftOutlined as PrevIcon,
    KeyboardArrowRightOutlined as NextIcon
} from "@material-ui/icons";
import {
    Answers, Button, Container, ErrorComponent, Header,
    Loader, Offline, Question, Snackbar, SuccessPage
} from 'components';
import { useToken } from "cookies";
import { selectBusyState } from "pages/Impact/Impact.slice";
import { selectLanguageObject, selectLoggedUser } from 'pages/Login/LoginSlice';
import {
    addAnsweredQuestionId, AnswerValue,
    removeAnsweredQuestionId,
    setAnswerValues, setCurrentQuestion
} from "pages/Transactions/Transactions.slice";
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AppThunkDispatch } from "redux/store";
import { getTransactionPath, getWelcomePath, transactionsCollectIdParam } from "routes";
import { ErrorDetails, Value } from "types";
import { selectTransactionSurveyByCollect, sendTransactionResults } from "./Transactions.slice";
import useStyles from "./Transaction_Styles";
import { selectLanguage } from "pages/Login/LoginSlice" ;
import { handleErrorCodes } from "utils/httpErrorCodes";
import { useProgram } from '../../program'

//this component will build the survey for the transactions page
const Transaction: React.FC<{}> = (props) => {
    const program = useProgram()
    const classes = useStyles()
    const language = useSelector(selectLanguageObject)
    const dispatch = useDispatch<AppThunkDispatch>()
    const history = useHistory()
    const { token } = useToken();
    const loggedUser = useSelector(selectLoggedUser)
    const transactionSurveyState = useSelector(selectTransactionSurveyByCollect)
    const isBusy = useSelector(selectBusyState)
    const [showCompletion, setShowCompletion] = React.useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const locale = useSelector(selectLanguage)
    const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

    const handleCloseSnackbarSlowConnection = () => {
      setSnackbarState(false)
    }

    const { loading, error, survey, answerValues,
        currentQuestionId, answeredQuestionIds, pending } = transactionSurveyState

    React.useEffect(() => {
        if (survey && answerValues.length === 0) {
            const initialAnswerValues: Array<AnswerValue> = survey.questions
                .map(({ cmsId, answerType, order }) => {
                    return {
                        questionId: cmsId,
                        order,
                        inputDate: new Date().toUTCString(),
                        value: answerType === "checkbox"
                            ? []
                            : ""
                    }
                })

            dispatch(setAnswerValues(initialAnswerValues))
        }
    }, [dispatch, survey, answerValues])

    
    React.useEffect(() => {
        if(error && error.httpStatus !== 503) {
        setErrorDetails(handleErrorCodes(error, language.connectionError))
        }
    }, [error, language.connectionError]);


    if (loading || isBusy) {
        return <Loader />
    }
    
    if (!survey) {
        const message = language.surveyNotFound.replace("$param", transactionsCollectIdParam)
        return <ErrorComponent message={message} snackMsg={errorDetails.slowConnexionError ? language.slowConnection : undefined} />
    }

    const { questions, cmsId } = survey

    const renderOptions = () => {
        return questions
            .map(({ cmsId, title, optional }) => (
                {
                    id: cmsId,
                    value: title,
                    isChecked: answeredQuestionIds?.includes(cmsId) || optional
                }))
    }

    const questionIds = questions.map(question => question.cmsId)

    const currentQuestionIndex = questionIds.findIndex(questionId =>
        questionId === currentQuestionId?.toString())
    const currentIndex = currentQuestionIndex !== -1 ? currentQuestionIndex : 0

    const message = pending ? language.dataSaved : language.dataSent

    let thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    let decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    const handleSubmit = () => {
        if (loggedUser) {
            const surveyAnswers = {
                collect: program.id+"9999",
                userId: loggedUser.userId.toString(),
                surveyCmsId: survey.cmsId,
                inputs: answerValues.map(({ questionId, inputDate, value, order }, index) => ({
                    questionCmsId: questionId,
                    inputDate,
                    answer: (value ?   ( survey.questions[index].answerType === "number"  ? value.toString().replace(new RegExp('\\' + thousandSeparator, 'g'), '').replace(new RegExp('\\' + decimalSeparator), '.') : value.toString()) : ""),
                    order
                }))
            }
            dispatch(sendTransactionResults(token, surveyAnswers))
            setShowCompletion(true)
            setOpenSnackbar(true)
        } else {
            throw new Error(language.userNotFound)
        }
    }

    const handleChangeQuestion = (currentQuestionId: string | undefined) => {
        dispatch(setCurrentQuestion(currentQuestionId))
        history.push(getTransactionPath(program.name, transactionsCollectIdParam, cmsId, currentQuestionId))
    }

    const handleScroll = () => {
        document.getElementById("form")!.scrollTo(0,0);
}

    const renderButtonContent = () => {

        const isDisabled = !renderOptions().every(option => option.isChecked)

        const handleNextClick = () => {
            if (currentIndex < questions.length - 1) {
                const idToSet = questionIds[currentIndex + 1]
                handleChangeQuestion(idToSet);
                handleScroll();
            }
        }

        const handlePrevClick = () => {
            if (currentIndex > 0 && currentIndex <= questionIds.length - 1) {
                const idToSet = questionIds[currentIndex - 1];
                handleChangeQuestion(idToSet);
                handleScroll();
            }
        }

        return (
            <Container variant="buttons">
                {currentIndex > 0 && (
                    <Button
                        label={language.previous}
                        onClick={handlePrevClick}
                        className={classes.button}
                        startIcon={<PrevIcon />}
                    />
                )}
                {currentIndex === renderOptions().length - 1
                    ? (
                        <Button
                            label={language.submit}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                            className={classes.button}
                        />
                    ) : (
                        <Button
                            label={language.next}
                            onClick={handleNextClick}
                            className={classes.buttonNext}
                            endIcon={<NextIcon />}
                        />
                    )}
            </Container>
        )
    }

    const handleCloseSurvey = () => {
        history.push(getWelcomePath(program.name))
    }

    const handleChange = (newValue: Value) => {
        const updatedValues = answerValues.map(answer => {
            if (answer.questionId === currentQuestionId) {
                return {
                    ...answer,
                    value: newValue,
                    inputDate: new Date().toUTCString()
                }
            }
            return answer
        })

        dispatch(setAnswerValues(updatedValues))

        if (newValue && newValue.toString().length > 0) {
            dispatch(addAnsweredQuestionId(currentQuestionId!))
        } else {
            dispatch(removeAnsweredQuestionId(currentQuestionId!))
        }
    }

    const { answerType, answers, label, title } = questions[currentIndex]

    const selectedId = renderOptions()[currentIndex].id

    const renderAnswerValue = () => {
        return answerValues?.find(answer =>
            answer.questionId === currentQuestionId)?.value
    }

    const handleDone = () => {
        setShowCompletion(false)
        handleChangeQuestion(undefined)
        history.push(getWelcomePath(program.name))
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    return (
        errorDetails.message 
        ? <ErrorComponent message={errorDetails.message} />
        :
        <Offline content={survey}>
                {showCompletion
                    ? <SuccessPage onDone={handleDone} message={message} />
                    : (
                        <>
                            <Header menuOptions={renderOptions()}
                                selectedId={selectedId}
                                onChange={handleChangeQuestion}
                                onClose={handleCloseSurvey} />
                            <Container>
                                <Container variant="content">
                                    <Question title={title} question={label} />
                                    <Answers answers={answers}
                                        type={answerType}
                                        onChange={handleChange}
                                        initialValue={renderAnswerValue()}
                                    />
                                </Container>
                                {renderButtonContent()}
                            </Container >
                        </>
                    )
                }
                <Snackbar open={openSnackbar}
                    message={message}
                    onClose={handleCloseSnackbar} />
                {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
            </Offline >
    )
}

export default Transaction
