import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    const width = "18.125rem"
    return createStyles({
        select: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width
        },
        paper:{
            minWidth: `${width} !important`,
            maxWidth: width,
            position: "relative",
            marginTop: 0,
            borderRadius: "0 0 10px 10px",
            padding: "0.625rem 0.313rem 1.25rem 0.313rem",
            height: "7.5rem"
        },
        smallPaper: {
            minWidth: "unset !important",
            maxWidth: "unset !important"
        },
        smallSelect: {
            width: "unset"
        }
    })
})