import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {IChapterButton} from "./ChapterButton"

export default makeStyles((theme: Theme) => {
  const height = "4.5rem"
  const width = "3.5rem"
  
  return createStyles({
    item: (props: IChapterButton) => {
      return props.disabled
        ? ({
          pointerEvents: "none",
          opacity: 0.2
        }) : ({
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          "&:hover": {
            cursor: "pointer"
          }
        })
    },
    title: {
      color: theme.palette.primary.main,
      fontSize: "0.9375rem",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 700,
      textAlign: "center",
      width: "5rem",
      marginTop: "0.313rem",
      "&:hover": {
        cursor: "pointer"
      }
    },
    image: (props: IChapterButton) => ({
      background: "none",
      borderRadius: "8px",
      height: height,
      width: width,
      border: getBorderStyles(props, theme)
    }),
    text: (props: IChapterButton) => ({
      fontSize: "3rem",
      fontFamily: theme.typography.fontFamily,
      color: theme.palette.primary.main,
      textAlign: "center",
      borderRadius: "0.5rem",
      height: height,
      width: width,
      border: getBorderStyles(props, theme)
    }),
    percentageContainer: {
      width: "95%",
      height: "1rem",
      borderRadius: "1.25rem",
      marginLeft: "0.125rem",
      position: "relative",
      backgroundColor: "#E0E0E0"
    },
    percentageFiller: (props: IChapterButton) => ({
      height: "100%",
      width: `${props.percentageCompletion}%`,
      position: "absolute",
      bottom: 0,
      background: `${theme.palette.primary.light}`,
      borderRadius: "1.25rem",
    }),
    iconBox: {
      display: "flex",
      flexDirection: "row",
      position: 'relative'
    },
    checkIcon: {
      position: "absolute",
      bottom: "-3px",
      right: "-28px",
    },
    percentageCompletion: {
      position: "absolute",
      bottom: "-3px",
      right: "-28px",
      color: `${theme.palette.primary.light}`,
      fontWeight: "bold"
    },
    sectionProgression: {
      marginTop: "20px",
      "& p": {
        marginBottom: "5px"
      }
    },
    container: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    }
  })
})

const getBorderStyles = (props: IChapterButton, theme: Theme) => {
  return `solid 0.25rem ${props.percentageCompletion === 100
    ? theme.palette.primary.main
    : theme.palette.secondary.main}`
}
