import { AsyncThunk, createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { AppState } from 'redux/rootReducer'
import { getService } from 'api'
import { handleError } from '../../utils/httpErrorCodes'
import { Details, Services, ErrorType } from 'types'


interface UserParams {
  token: string | null,
  locale: string | null
}

interface ServiceConnector {
  type: string
  field: string,
  value: string
}

export interface ServiceState extends Details {
  id: string,
  name: string,
  description: string,
  icon: string,
  url: string,
  locale: string,
  enabled: boolean
  connectors: Array<ServiceConnector>
}

interface ServicesState {
  services: {
    [id: string]: ServiceState
  },
  isLoading: boolean,
  error: ErrorType | null,
  showSuccessInfo: boolean
}

const initialState: ServicesState = {
  services: {},
  isLoading: false,
  error: null,
  showSuccessInfo: false,
}

export const fetchServices: AsyncThunk<Services, UserParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'services/fetchServices',
  async ({token, locale}: UserParams, {rejectWithValue}) => {
    try {
      const response: Array<ServiceState> = await getService(token, `bo/services`)
      const services: Services = response.filter((service) => service.locale ===  locale)
      return services
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export const selectServices = (state: AppState): Array<ServiceState> => {
  return Object.keys(state.services.services).map((id) => {
    return state.services.services[id]
  })
}

export const selectError = (state: AppState): ErrorType | null => {
  const {error} = state.services
  return error
}

const servicesSlice = createSlice({
  name: 'services',
  initialState,
  reducers: {
  },
  extraReducers: {
    [fetchServices.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchServices.fulfilled.type]: (state, {payload}: PayloadAction<Services>) => {
      state.services = payload.reduce((acc, res) => {
        return {
          ...acc,
          [res.id]: {
            ...res
          }
        }
      }, {})
      state.isLoading = false
      state.error = null
    },
    [fetchServices.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    },
  }
})

export const selectIsLoading = (state: AppState): boolean => {
  return state.services.isLoading
}

export default servicesSlice.reducer
