import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"
import {style} from "../../style"

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      display: "flex",
      alignItems: "center",
      iconSize: "large",
      background: style.palette.white,
      height: style.size.headerHeight,
      width: `calc(100% - 2 * ${style.size.headerPadding})`,
      padding: `0 ${style.size.headerPadding}`,
      borderBottom: `2px solid ${theme.palette.secondary.light}`,
    },
    mediumContainer: {
      height: style.size.headerHeight,
    },
    smallContainer: {
      height: style.size.headerHeight,
    },
  })
})