import { useMediaQuery } from "@material-ui/core";
import { useToken } from "cookies";
import { useCallback, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom';
import { getLoginPath } from "routes";
import { Language } from "types";
import { ErrorType } from "types/common";
import { speechSynthMsKey, speechSynthMsServer } from 'api/config';
import { useProgram } from '../program'
import { useGetVoice } from "./useGetVoice";


/**
 * Hook use for long press event
 * @param text for speechSynthesis
 * @param onClick default onCLick action
 * @param shouldPreventDefault To prevent default action by default
 *
 * To use this hook just import and use as follows
 * const longPress = useLongPress()
 * <Component {...longPress}/>
 */

export const useLongPress = (
    language: Language,
    text: string,
    onClick: () => void,
    shouldPreventDefault: boolean = true) => {

    const timeout: any = useRef();
    const target: any = useRef();
    const voice = useGetVoice(language)
    const [longPressTriggered, setLongPressTriggered] = useState(false);
    const [touchMove, setTouchMove] = useState(false)
    
    // isSpeaking used to not call Microsoft Text-To-Speech (TTS) if already speaking
    const [isSpeaking, setIsSpeaking] = useState(false)
    const userAgent = navigator.userAgent || navigator.vendor;
    const handleLong = useCallback(async () => {
        if (((language === "si-LK") && !(/android/i.test(userAgent))) || (language === "ta-LK"))  {
            // If TTS is already speaking, do not speak on top
            if (!isSpeaking){
                await synthesizeSpeech(text, language)
            }
        } else {

            const speech = new window.SpeechSynthesisUtterance(text);
            // Android required
            speech.lang = voice?.lang ?? "en-US";
            // IOS required
            speech.voice = voice ?? null;
            speech.volume =1 
            speech.rate = 1
            speech.pitch = 1
            speech.voiceURI = voice?.voiceURI;
            window.speechSynthesis.speak(speech);

            speech.onstart = () => setIsSpeaking(true);
            speech.onend = () => setIsSpeaking(false);
        }
    }, [isSpeaking, text, voice, language, userAgent])


    async function synthesizeSpeech(txt: string, language:string) {

        let sdk = require("microsoft-cognitiveservices-speech-sdk");
        const speechConfig = await sdk.SpeechConfig.fromSubscription(speechSynthMsKey, speechSynthMsServer);
        speechConfig.speechSynthesisLanguage =  language; // e.g. "de-DE"
            // Change default voice 
            if(language === "si-LK"){
                speechConfig.speechSynthesisVoiceName = "si-LK-ThiliniNeural";
            }

        const player = new sdk.SpeakerAudioDestination()
            
        // When audio start, future TTS will not be played on top of it
        player.onAudioStart = function () {
            setIsSpeaking(true)
          };

        // When audio end, future TTS can be played
        player.onAudioEnd = function () {
            setIsSpeaking(false)
          };
        const synthesizer = new sdk.SpeechSynthesizer(speechConfig, 
            sdk.AudioConfig.fromSpeakerOutput(player));
        await synthesizer.speakTextAsync(txt, (result:boolean) => {
            if (result) {
                synthesizer.close();
                return result.valueOf;
            }
        },
            (error:boolean) => {
                synthesizer.close();
            }
        );
    }
    

    const start = useCallback((event: any) => {
        if (shouldPreventDefault && event.target) {
            event.target.addEventListener("touchend", preventDefault, {
                passive: false
            });
            target.current = event.target;
        }
        timeout.current = setTimeout(() => {
            handleLong()
            setLongPressTriggered(true);
        }, 1000);
    }, [handleLong, shouldPreventDefault]);

    const clear = useCallback((event: any, shouldTriggerClick: boolean = true) => {
        timeout.current && clearTimeout(timeout.current);
        shouldTriggerClick && !longPressTriggered && !touchMove && onClick();
        setLongPressTriggered(false)
        setTouchMove(false)
        if (shouldPreventDefault && target.current) {
            target.current.removeEventListener("touchend", preventDefault);
        }
    }, [shouldPreventDefault, longPressTriggered, onClick, touchMove]);

    const handleTouchMove = useCallback((e: React.TouchEvent<Element>) => {
        e.preventDefault()
        setTouchMove(true)
    }, [])

    return {
        isSpeaking :isSpeaking || undefined,
        onMouseDown: (e: React.MouseEvent<Element, MouseEvent>) => start(e),
        onTouchStart: (e: React.TouchEvent<Element>) => start(e),
        onMouseUp: (e: React.MouseEvent<Element, MouseEvent>) => clear(e),
        onMouseLeave: (e: React.MouseEvent<Element, MouseEvent>) => clear(e, false),
        onTouchEnd: (e: React.TouchEvent<Element>) => clear(e),
        onContextMenu: (e: any) => e.preventDefault(),
        onTouchMove: (e: React.TouchEvent<Element>) => handleTouchMove(e)
    };
};

const isTouchEvent = (event: React.TouchEvent<Element>) => {
    return "touches" in event;
};

const preventDefault = (event: React.TouchEvent<Element>) => {
    if (!isTouchEvent(event)) return;

    if ((event).touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};

/**
 * This hook is used get chapter params, which are
 * used for routing and api calls
 */

export const useGetChapterParams = () => {
    const location = useLocation()
    if (location.pathname.includes("/chapters")) {
        const splittedPath = location.pathname.split("/")
        return {
            courseSlug: splittedPath[3],
            chapterId: splittedPath[5]
        }
    }

    return {
        courseSlug: ":slug",
        chapterId: ":chapterId"
    }
}

/**
 * This hook is used get survey params, which are
 * used for routing and api calls
 */

export const useGetSurveyParams = () => {
    const location = useLocation()
    if (location.pathname.includes("/surveys")) {
        const splittedPath = location.pathname.split("/")
        return {
            collectId: splittedPath[3],
            surveyId: splittedPath[5],
            questionId: splittedPath[7]
        }
    }

    return {
        collectId: ":collectId",
        surveyId: ":surveyId",
        questionId: ":questionId"
    }
}

/**
 * This hook is used get survey params, which are
 * used for routing and api calls
 */

export const useGetMessagesParams = () => {
    const location = useLocation()
    if (location.pathname.includes("/messages")) {
        const splittedPath = location.pathname.split("/")
        return {
            messageId: splittedPath[3]
        }
    }

    return {
        messageId: ":messageId"
    }
}



type ServerErrorHandler = <T> (promise: Promise<T>) => Promise<T>

/**
 * Hook returning a function that handles errors thrown by sutti
 * server when a service is called.
 */
export const useServerErrorHandler = (): ServerErrorHandler => {
    const program = useProgram()
    const history = useHistory()
    const { removeToken } = useToken()

    return useCallback(<T>(promise: Promise<T>) =>
        promise.catch((httpError: ErrorType) => {
            const { httpStatus } = httpError
            // case of expired client session id
            if (httpStatus === 401) {
                const location = history.location
                 // remove V1 and V2 cookies
                 removeToken()
                history.push(
                    getLoginPath(program.name),
                    {
                        from: location,
                        reason: "expired"
                    }
                )
            }

            return Promise.reject(httpError)
        }),
        [program, history, removeToken])
}

/**
 * Hook that provide the search part of the url.
 * "Search part" means everything after '?' in the url.
 */
export const useQuery = (): URLSearchParams =>
    new URLSearchParams(useLocation().search.substring(1))

/**
 * MEDIA QUERIES FOR RESPONSIVE DESIGN
 */

/**
 * Example -> Returns true if width is less than 300px
 * else false
 */
export const useSmallScreen = () => {
    const isSmallScreen = useMediaQuery('(max-width:360px)')
    return isSmallScreen
}

export const useMediumWidthScreen = () => {
    const  isMediumScreen = useMediaQuery('(max-width:450px)')
    return isMediumScreen
}

export const useH360Screen = () => {
    const isSmallScreen = useMediaQuery('(max-height:360px)')
    return isSmallScreen
}

export const useMediumScreen = () => {
    const isMediumScreen = useMediaQuery('(min-height:360px)'&&'(max-height:640px)')
    return isMediumScreen
}
