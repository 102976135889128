import { Checkbox, FormControlLabel } from '@material-ui/core'
import { Speech } from 'components'
import React from 'react'
import useStyles from "./CheckButton_Styles"

export interface CheckButtonProps {
    label: string
    checked?: boolean
    onChange: () => void
}

//custom checkbox for answers
const CheckButton: React.FC<CheckButtonProps> = ({ label, onChange, checked=false }) => {
    const classes = useStyles()

    const handleChange = () => {
        onChange()
    }

    return (
        <Speech text={label} onClick={handleChange}>
            <FormControlLabel
                label={label}
                control={<Checkbox classes={{
                    checked: classes.checkedIcon,
                    root: classes.unCheckedIcon
                }}
                    checked={checked}
                />}
                labelPlacement="start"
                className={classes.root}
            />
        </Speech>
    )
}

export default CheckButton
