import { Divider, Typography } from '@material-ui/core'
import { ErrorText, IconButton, Text } from 'components'
import CheckIcon from '@material-ui/icons/CheckCircleOutline';
import CancelIcon from '@material-ui/icons/CancelOutlined';
import { Close as CloseIcon } from "@material-ui/icons";
import useStyles from "./CompatibilityChecks_Styles"
import { useSelector } from 'react-redux';
import { selectLanguageObject, selectLoggedUser } from 'pages/Login/LoginSlice';
import { getWelcomePath } from 'routes';
import { useHistory } from 'react-router-dom';
import packageJson from "../../../package.json";
import UAParser from 'ua-parser-js'
import { useProgram } from '../../program'
interface LocationState {
    from: string;
}
interface Checks {
    serviceWorkerSupported: boolean
    cacheSupported: boolean
    pushSupported: boolean
    bgSyncSupported: boolean
    indexedDbSupported: boolean
    storageSupported: boolean
    persistentStorageSupported: boolean
    fileApiSupported: boolean
    mediaDevicesSupported: boolean
    geoSupported: boolean
}


//Check the compatibilty of the browser to see if SUTTI will fonction
const CompatibilityCheck = () => {
    const program = useProgram()
    const classes = useStyles()
    const language = useSelector(selectLanguageObject)
    const history = useHistory<LocationState | undefined>();
    const { from } = history.location.state || { from: getWelcomePath(program.name) }
    const loggedUser = useSelector(selectLoggedUser);
    const checks: Checks = {
        serviceWorkerSupported: 'serviceWorker' in navigator,
        cacheSupported: 'caches' in window,
        pushSupported: 'serviceWorker' in navigator && 'PushManager' in window,
        bgSyncSupported: 'serviceWorker' in navigator && 'SyncManager' in window,
        indexedDbSupported: window.indexedDB !== undefined,
        storageSupported: 'storage' in navigator && 'StorageManager' in window,
        persistentStorageSupported: navigator.storage && navigator.storage.persist !== undefined,
        fileApiSupported: ('File' in window && 'FileReader' in window && 'FileList' in window && 'Blob' in window),
        mediaDevicesSupported: 'mediaDevices' in navigator,
        geoSupported: "geolocation" in navigator
    }

    const compatibilityPercentage = () => {
        const allKeys = Object.keys(checks)
        const passedChecks = allKeys
            .map(key => (checks as any)[key])
            .filter(check => check === true)

        return passedChecks.length / allKeys.length * 100
    }

    const handleClick = () => {
        history.push(from)
    }

    const userAgent = navigator.userAgent;
    const parser = new UAParser();
    parser.setUA(userAgent);
    const result = parser.getResult();

    const suttiVersion = "SUTTI™ Participant App (" + packageJson.name + ") v" + packageJson.version + " for " + program.name + " Program";
    const browserVersion = "Running within " + result.browser.name + "™ v" + result.browser.version + " on " + result.os.name + " " + result.os.version + " / " + result.device.vendor + " " + result.device.model;
    const participantDetail = loggedUser ? "Participant: " + loggedUser.firstName + " " + loggedUser.lastName : "";
    return (
        <div>
            <IconButton className={classes.button} onClick={handleClick}>
                <CloseIcon fontSize={"large"} />
            </IconButton>
            <ErrorText text={`${language.deviceCompatibility}:`} />
            <ErrorText text={`${compatibilityPercentage()}%`} />
            {Object.keys(checks).map(key => {
                return (
                    <div className={classes.item}>
                        <Typography>{key}</Typography>
                        {(checks as any)[key]
                            ? <CheckIcon color="primary" />
                            : <CancelIcon color="error" />}
                    </div>
                )
            })}
            <Divider className={classes.divider} variant="middle"></Divider>
            <Text className={classes.content}>{suttiVersion}</Text>
            <Text className={classes.content}>{browserVersion}</Text>
            <Text className={classes.content}>{participantDetail}</Text>
        </div>
    )
}

export default CompatibilityCheck
