import React,{useState} from "react";
//import PropTypes from 'prop-types';
import DialogTitle from '@material-ui/core/DialogTitle';
import Dialog from '@material-ui/core/Dialog';
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'
import useStyles from "./Popup_Styles";
import { Button, Container, Speech, Text } from "components";
import {ExitToApp as LogIcon} from "@material-ui/icons";
import {KeyboardArrowRightOutlined as NextIcon} from "@material-ui/icons";
import { useSmallScreen } from "hooks";



export interface PopupProps {
  title: string
  explanationLabel?: string
  buttonLabel: string
  open: boolean
  onClose: () => void
  onSubmit: () => void
  url?: string
  extraActionLabel?: string
  onSubmitWithExtraAction: () => void
}

//Popup winwow fo logout
const Popup: React.FC<PopupProps> = ({ title, buttonLabel, explanationLabel, open, onClose, onSubmit, url, extraActionLabel, onSubmitWithExtraAction }) => {

  const [ checked, onChecked] = useState(false);
  const classes = useStyles();
  const isSmallScreen = useSmallScreen();

 

const handleChange = () => {
  onChecked(!checked);
}

const handleSubmit = () => {
  onSubmit();
  if (checked) onSubmitWithExtraAction();
}

//  <Dialog onClose={onClose} open={open} fullScreen={isSmallScreen ? true : false}></Dialog>  
  return (
    <Dialog onClose={onClose} open={open} className={classes.dialog} >
      <Speech text={title}>
      <DialogTitle className={classes.popupTitle}>{title}</DialogTitle>
      </Speech>
     
      <div>
      <Container variant="buttons">
        <Button
        label={buttonLabel}
        onClick={handleSubmit}
        href={url}
        startIcon={isSmallScreen ? undefined : <LogIcon/>}
        className={isSmallScreen ? classes.smallButton : classes.button}
        endIcon={isSmallScreen ? undefined : <NextIcon/>}>
        </Button>
        </Container>
        {explanationLabel ? <Text className={isSmallScreen ? classes.smallText : classes.text}>{explanationLabel}</Text> : undefined}
        {extraActionLabel ? 
        <div>
        <Speech text={extraActionLabel} onClick={handleChange}>
        <FormControlLabel checked={checked} control={<Checkbox /> } label={extraActionLabel} />  
        </Speech>
        </div> : null }
      </div>
    </Dialog>
  );
  }

export default Popup;