import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        app: {
            height: "100%",
            width: "100%",
            position: "relative"
        }
    })
})