import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {StyleProps} from "types/styles"

export default makeStyles((theme: Theme) => {
  return createStyles({
    mainText: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexWrap: "wrap",
      paddingBottom: "12px",
    },
    header: {
      fontSize: ".95rem",
      textTransform: "capitalize",
      color: theme.palette.primary.light,
      opacity: ".9",
    },
    boldText: {
      font: theme.typography.fontFamily,
      fontWeight: "bolder",
      whiteSpace: "nowrap",
      flex: 1,
    },
    text: {
      font: theme.typography.fontFamily,
      fontSize: ".9rem",
      color: (props: StyleProps) => `${props.color ? props.color : theme.palette.secondary.contrastText}`,
      flex: 1,
      fontWeight: 500,
      wordWrap: "break-word", 
      textAlign:"justify"
    },
    contentBoldText: {
      color: (props: StyleProps) => `${props.color ? props.color : theme.palette.secondary.contrastText}`,
      fontSize: ".9rem"
    },
    flex :{
      display: "flex",
      alignItems: "center",
      alignContent: "center",
      columnGap: "0.5rem",
      paddingBottom: "0.5rem"
    },
    button :{
      fontSize: "0.875rem",
    }, 
    map :{
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      rowGap: "0.5rem"
    }
  })
})