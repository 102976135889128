import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        text: {
            font: theme.typography.fontFamily,
            fontSize: "1rem",
            color: theme.palette.secondary.contrastText,
            textAlign: "center",
            minWidth: "16.875rem",
            padding: "0.625rem"
        },
        smallText: {
            minWidth: "unset"
        },
        title: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.secondary.contrastText,
            textAlign: "center",
            minWidth: "16.875rem",
            padding: "0.625rem",
            fontSize: "2rem",
            fontWeight: 700,
        },
        question: {
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.secondary.contrastText,
            textAlign: "center",
            minWidth: "16.875rem",
            padding: "0.625rem",
            fontSize: "1.5rem",
            fontWeight: 700,
        }
    })
})