import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"
import React from "react"

const CoursesSVG: React.FC<SVGPropsInterface> = ({width, height,fill, stroke}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles(); 

  return (
    <svg aria-hidden="true" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 0 151 172" xmlns="http://www.w3.org/2000/svg">
<path d="M102.695 172H22.0589C14.2438 172 7.88567 164.314 7.88567 154.867C7.88567 145.42 14.2438 137.734 22.0589 137.734H102.695C103.977 137.734 105.017 138.991 105.017 140.542C105.017 142.092 103.977 143.349 102.695 143.349H22.0589C16.8051 143.349 12.5309 148.516 12.5309 154.867C12.5309 161.218 16.8051 166.385 22.0589 166.385H101.995L137.117 138.254C138.187 137.398 139.628 137.752 140.336 139.044C141.045 140.337 140.752 142.079 139.683 142.935L103.978 171.533C103.597 171.838 103.151 172 102.695 172Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M97.94 171.491C96.6573 171.491 95.6173 170.234 95.6173 168.683V149.217C95.6173 147.666 96.6573 146.409 97.94 146.409C99.2227 146.409 100.263 147.666 100.263 149.217V168.683C100.263 170.234 99.2227 171.491 97.94 171.491Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M89.8884 114.215C88.6057 114.215 87.5658 112.958 87.5658 111.407V91.9408C87.5658 90.3899 88.6057 89.1332 89.8884 89.1332C91.1711 89.1332 92.211 90.3899 92.211 91.9408V111.407C92.211 112.958 91.1711 114.215 89.8884 114.215Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M14.1856 114.805C6.35808 114.805 0 107.119 0 97.6719C0 88.2248 6.35808 80.539 14.1732 80.539H94.4496L136.621 57.255C137.788 56.6115 139.166 57.2314 139.699 58.6424C140.232 60.0525 139.718 61.7184 138.551 62.3627L95.9199 85.9004C95.6171 86.0674 95.2879 86.1543 94.955 86.1543H14.1732C8.91946 86.1543 4.64523 91.3211 4.64523 97.6719C4.64523 104.023 8.91946 109.19 14.1732 109.19L94.3415 108.675L136.139 80.287C137.257 79.5275 138.673 80.0078 139.301 81.3596C139.93 82.7117 139.532 84.4232 138.414 85.1828L96.0927 113.926C95.7487 114.16 95.3616 114.283 94.9674 114.286L14.1856 114.805Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M115.943 143.403H34.6761C26.861 143.403 20.5029 135.717 20.5029 126.269C20.5029 116.822 26.861 109.136 34.6761 109.136H115.319L147.514 86.6141C148.624 85.8373 150.045 86.2955 150.687 87.6376C151.33 88.9797 150.95 90.6976 149.84 91.474L117.106 114.374C116.753 114.621 116.351 114.752 115.943 114.752H34.6761C29.4223 114.752 25.1481 119.919 25.1481 126.269C25.1481 132.62 29.4223 137.787 34.6761 137.787H115.272L147.44 113.304C148.524 112.478 149.959 112.871 150.642 114.184C151.326 115.496 151 117.229 149.915 118.056L117.18 142.971C116.81 143.253 116.381 143.403 115.943 143.403Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M110.327 143.04C109.045 143.04 108.005 141.783 108.005 140.232V120.766C108.005 119.215 109.045 117.958 110.327 117.958C111.61 117.958 112.65 119.215 112.65 120.766V140.232C112.65 141.783 111.61 143.04 110.327 143.04Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M10.2085 86.978C9.26736 86.978 8.38198 86.2817 8.03297 85.1534C7.58331 83.701 8.19277 82.0834 9.39433 81.5402L63.252 57.1826C64.4536 56.6394 65.7917 57.3761 66.2411 58.8286C66.6908 60.2807 66.081 61.8987 64.8797 62.4419L11.022 86.7991C10.7538 86.9208 10.4788 86.978 10.2085 86.978Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M126.684 62.7413C125.401 62.7413 124.362 61.424 124.361 59.8738C124.361 58.3232 125.401 57.098 126.683 57.0976L137.586 57.126C138.868 57.126 139.908 58.3199 139.909 59.8704C139.909 61.421 138.87 62.7076 137.587 62.708L126.684 62.7413Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M61.8685 18.7725L95.1711 31.7865L128.474 18.7725L95.1711 5.75819L61.8685 18.7725ZM95.1711 37.5448C94.9301 37.5448 94.6889 37.4991 94.4566 37.4085L53.6034 21.4443C52.6446 21.0696 51.9952 19.9903 51.9952 18.7725C51.9952 17.5548 52.6446 16.4755 53.6034 16.1008L94.4566 0.136171C94.9211 -0.0453902 95.421 -0.0453902 95.8855 0.136171L136.739 16.1008C137.697 16.4755 138.347 17.5548 138.347 18.7725C138.347 19.9903 137.697 21.0696 136.739 21.4443L95.8855 37.4085C95.6532 37.4991 95.412 37.5448 95.1711 37.5448Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M95.0876 68.9478C91.814 68.9478 88.54 68.5083 85.3583 67.6297L72.3232 64.0311C71.2669 63.7398 70.5178 62.604 70.5178 61.2942V27.2506C70.5178 25.7 71.5577 24.443 72.8404 24.443C74.1231 24.443 75.163 25.7 75.163 27.2506V59.0552L86.3927 62.1556C92.0447 63.7159 98.0215 63.7264 103.677 62.1844L115.179 59.0492V27.2506C115.179 25.7 116.219 24.443 117.502 24.443C118.785 24.443 119.824 25.7 119.824 27.2506V61.2942C119.824 62.607 119.072 63.7443 118.013 64.033L104.699 67.6619C101.554 68.5192 98.3207 68.9478 95.0876 68.9478Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M56.9071 47.7674C56.0521 47.7674 55.3587 46.9296 55.3587 45.8956V20.0653C55.3587 19.0313 56.0521 18.1935 56.9071 18.1935C57.7622 18.1935 58.4556 19.0313 58.4556 20.0653V45.8956C58.4556 46.9296 57.7622 47.7674 56.9071 47.7674Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M56.9739 48.0354C56.221 48.0354 55.6088 48.7758 55.6088 49.6855C55.6088 50.5956 56.221 51.3357 56.9739 51.3357C57.7264 51.3357 58.3389 50.5956 58.3389 49.6855C58.3389 48.7758 57.7264 48.0354 56.9739 48.0354ZM56.9739 55.0792C54.5134 55.0792 52.512 52.6597 52.512 49.6855C52.512 46.7117 54.5134 44.2918 56.9739 44.2918C59.434 44.2918 61.4358 46.7117 61.4358 49.6855C61.4358 52.6597 59.434 55.0792 56.9739 55.0792Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M42.9715 95.31H14.4807C13.6257 95.31 12.9323 94.4722 12.9323 93.4382C12.9323 92.4043 13.6257 91.5664 14.4807 91.5664H42.9715C43.8265 91.5664 44.5199 92.4043 44.5199 93.4382C44.5199 94.4722 43.8265 95.31 42.9715 95.31Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M67.4363 103.92H14.4807C13.6257 103.92 12.9323 103.082 12.9323 102.048C12.9323 101.014 13.6257 100.177 14.4807 100.177H67.4363C68.2914 100.177 68.9847 101.014 68.9847 102.048C68.9847 103.082 68.2914 103.92 67.4363 103.92Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M63.4106 124.135H34.6101C33.7551 124.135 33.0617 123.297 33.0617 122.263C33.0617 121.229 33.7551 120.392 34.6101 120.392H63.4106C64.2656 120.392 64.959 121.229 64.959 122.263C64.959 123.297 64.2656 124.135 63.4106 124.135Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M51.0233 132.371H34.6101C33.7551 132.371 33.0617 131.533 33.0617 130.499C33.0617 129.465 33.7551 128.627 34.6101 128.627H51.0233C51.8783 128.627 52.5717 129.465 52.5717 130.499C52.5717 131.533 51.8783 132.371 51.0233 132.371Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M39.2553 152.586H21.2938C20.4387 152.586 19.7453 151.748 19.7453 150.714C19.7453 149.68 20.4387 148.842 21.2938 148.842H39.2553C40.1103 148.842 40.8037 149.68 40.8037 150.714C40.8037 151.748 40.1103 152.586 39.2553 152.586Z" fill={fill ? fill : defaultTheme.fill}/>
<path d="M47.6167 161.196H21.2938C20.4387 161.196 19.7453 160.358 19.7453 159.324C19.7453 158.29 20.4387 157.452 21.2938 157.452H47.6167C48.4717 157.452 49.1651 158.29 49.1651 159.324C49.1651 160.358 48.4717 161.196 47.6167 161.196Z" fill={fill ? fill : defaultTheme.fill}/>
</svg>
  )
}

export default CoursesSVG
