import clsx from "clsx";
import { Speech } from "components";
import React from "react";
import parse from 'html-react-parser';
import useStyles from "./ParsedContent_Styles";

//This component handle the parsers, parse content like text video or image
interface ParsedContentProps {
    htmlString: string
    className?: string
}

const ParsedContent: React.FC<ParsedContentProps> = ({ htmlString, className }) => {
    const classes = useStyles()

    const renderParsedContent = () => {
        const parsedContent = parse(htmlString)

        const isElement = (item: string | JSX.Element): item is JSX.Element => {
            return (item as JSX.Element).props !== undefined
        }

        const getElementVal = (element: string | JSX.Element, buff?: string): string => {      
            if (buff == null) buff = "";

            if (isElement(element)) {
                if (element.type === "strong") {
                    if (Array.isArray(element.props.children)) {
                        return buff.concat (", ", element.props.children
                            .map((val: string | JSX.Element) => {
                                return isElement(val) ? getElementVal(val || "", buff) : val
                            }).join(", ") // comma is used for have a pause in speech for list elements
                        )}
                    else {
                        return buff.concat (", ", getElementVal(element.props.children || ""))
                    }
                }
                if (Array.isArray(element.props.children)) {
                    return buff.concat (", ", element.props.children
                        .map((val: string | JSX.Element) => {
                            return isElement(val) ? getElementVal(val || "", buff) : val
                        }).join(", ") // comma is used for have a pause in speech for list elements
                    )}
                else {      
                    return buff.concat (", ", getElementVal(element.props.children || ""))
                }
            } else return buff.concat (", ", element)
        }

        const getText = (element: JSX.Element): string => {
            if (!element || !element.props || element.type === "figure") {
                return ""
            }
            if (element.type === "strong") {
                return getElementVal (element.props.children)
            } 
            if (Array.isArray(element.props.children)) {
                return element.props.children
                    .map((val: string | JSX.Element) => {
                        return isElement(val) ? getElementVal(val || "") : val
                    }).join(", ") // comma is used for have a pause in speech for list elements
            }

            return getElementVal(element)

        }

        if (Array.isArray(parsedContent)) {
            return (parsedContent as Array<JSX.Element>)
                .map((element: JSX.Element, index) => {
                    if (element && element.props) {
                        // in case of a video, change the element definition to prevent donwload
                        // by cloning the video element and adding it the controlsList property
                        // then leverage this tweaked children to replace the returned element 
                        if ((element.type === "figure") && (element.props.children.type === 'video')) {
                                const customChild = React.cloneElement(element.props.children, { controlsList: "nodownload",  crossOrigin: "anonymous"  });
                                const customElement = React.createElement(element.type, element.props, customChild);
                                element= customElement;
                        }
                        return (
                            <Speech key={index} text={getText(element)}>
                                {element}
                            </Speech>)
                    }
                    return ""
                })
        }

        return ""

    }

    return (
        <div className={clsx(classes.content, className)}>
            {renderParsedContent()}
        </div>
    )
}

export default ParsedContent