import React, { useEffect, useState } from 'react';
import useStyles from "./Welcome_Styles"
import {useSelector } from "react-redux";
import { selectLanguageObject } from 'pages/Login/LoginSlice';
import WelcomeButton from './WelcomeButton';
import { MenuOption } from './Welcome';
import { selectMyCourses} from 'pages/MyCourses/MyCourses.slice';
import { Typography } from '@material-ui/core';
import {Dialog} from 'components';
import { Popup, Speech } from 'components';
import { chatGroupDetails } from 'types';

const ButtonContainer : React.FC<{ menuOptions: Array<MenuOption> }> = ({ menuOptions }) => {
  const classes = useStyles()
  const [openChatDialog, setOpenChatDialog] = useState(false);
  const [openNoChatDialog, setOpenNoChatDialog] = useState(false);
  const [chatGroup, setChatGroup] = useState({} as chatGroupDetails);
  const myCourses = useSelector(selectMyCourses)
  const language = useSelector(selectLanguageObject);

  useEffect(() => {
    if (myCourses.length > 0) {
      const coursesChatAvailable =  myCourses.filter((course)=> course.chatGroup !== undefined)
      setChatGroup(coursesChatAvailable[0]?.chatGroup)
    }
  }, [myCourses]);

  const handleChatClick = () => {
    if ((chatGroup !== undefined) && chatGroup?.url) {
      setOpenChatDialog(true);
    } else {
      setOpenNoChatDialog(true);
    }
  }
  
  const handleCloseChatDialog = () => {
    setOpenChatDialog(false);
  }
  


  const renderChatDialogContent = () => {
    const title = `${language.redirectToChat} ${chatGroup.provider}`
    const explanation = `${language.chatRedirectDisclaimer} ${chatGroup.provider}. ${language.chatRedirectConsent}`

    return (
      <Popup title={title}
        buttonLabel={language.goChat}
        explanationLabel={explanation}
        open={openChatDialog}
        onClose={handleCloseChatDialog}
        onSubmit={() => {
          window.open(chatGroup.url)
          handleCloseChatDialog()
        }}
        onSubmitWithExtraAction={() => { }} />
    )
  }

  const handleCloseNoChatDialog = () => {
    setOpenNoChatDialog(false);
  }



  const renderNoChatDialogContent = () => {
    return (
      <Dialog open={openNoChatDialog} onClose={handleCloseNoChatDialog} className={classes.dialogContent}>
        <Speech text={language.noChat}>
          <>
            <Typography>{language.noChat}</Typography>
          </>
        </Speech>
      </Dialog>
    )
  }


  return (


      <div className={classes.welcomeButtonContainer}>
      {menuOptions.map((option) => (
        option.path === undefined ?
        <div className={classes.welcomeButton} onClick={handleChatClick}>
        <WelcomeButton title={option.title} iconUrl={option.iconUrl} key={option.title} />
        </div>
       : <WelcomeButton title={option.title} path={option.path} iconUrl={option.iconUrl} key={option.title} />

      ))}

{
            (chatGroup !== undefined && chatGroup.provider)
              ? renderChatDialogContent()
              : renderNoChatDialogContent()
          }
      </div>



  );
};

export default ButtonContainer;