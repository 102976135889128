import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"
import {StyleProps} from "types/styles"
import {CSSProperties} from "@material-ui/core/styles/withStyles"
import {style} from "../../style";

export default makeStyles((theme: Theme) => {
  const flexStyles = {
    display: "flex",
    flexDirection: "column",
  } as CSSProperties
  
  return createStyles({
    form: {
      ...flexStyles,
      background: style.palette.mainDegraded(theme.palette.primary.main),
      alignItems: (props: StyleProps) => props.align,
      justifyContent: "space-between",
      overflowY: "auto",
      padding: style.size.containerPadding,
      paddingTop: 0,
      height: style.size.containerWithFixedHeaderHeight
    },
    contentWithFixedHeader: {
      ...flexStyles,
      alignItems: (props: StyleProps) => props.align,
      overflowY: "auto",
      height: style.size.containerWithFixedHeaderHeight,
      padding: `0 ${style.size.containerPadding} ${style.size.containerPadding}`,
    },
    content: {
      ...flexStyles,
      alignItems: (props: StyleProps) => props.align,
      padding: `0 ${style.size.containerPadding}`,
      "&>*:last-child": {
        padding: `${style.size.containerPadding} 0`
      }
    },
    buttons: {
      display: "flex",
      justifyContent: "space-evenly",
      margin: (props: StyleProps) => props.margin
    },
    title: {
      display: "flex",
      background: theme.palette.primary.dark
    }
  })
})
