import React from 'react';
import useStyles from "./Welcome_Styles"
import { useProgram } from 'program';
import { useSelector } from 'react-redux';
import { selectLanguageObject, selectLoggedUser } from 'pages/Login/LoginSlice';
import {getImpactPath, getLoginPath, getMessagesPath, getMyCoursesPath, getProfilePath, getServicesPath, getSettingsPath, getTransactionsPath } from 'routes';
import { transactionsCollectIdParam } from 'routes';
import logo from "../../resources/icons/sutti.svg"
import { useSmallScreen, useMediumWidthScreen } from 'hooks';



// SVG 
import CoursesSVG from 'components/SVG/CoursesSVG';
import ImpactSVG from 'components/SVG/ImpactSVG';
import TransactionsSVG from 'components/SVG/TransactionsSVG';
import SettingsSVG from 'components/SVG/SettingsSVG';
import ProfileSVG from 'components/SVG/ProfileSVG';
import { SVGPropsInterface } from 'components/SVG/SVG';
import MessageSVG from 'components/SVG/MessageSVG';
import ChatSVG from 'components/SVG/ChatSVG';
import ServicesSVG from 'components/SVG/ServicesSVG';
import { Link } from 'react-router-dom';
import { Settings } from '@material-ui/icons';
import ButtonContainer from './ButtonContainer';



export interface MenuOption {
  title: string
  path?: string
  iconUrl: React.FC<SVGPropsInterface>
  key : string
}
const Welcome = () => {
  const classes = useStyles()
  const program = useProgram()
  const language = useSelector(selectLanguageObject)
  const loggedUser = useSelector(selectLoggedUser)
  const icons =["messages", "chatroom","services", ...program.configs?.modules.filter((el)=> el !== "settings")]
  const isSmallScreen = useSmallScreen()
  const isMediumScreen = useMediumWidthScreen()


  // Array of menu options
  const menuOptions: Array<MenuOption> = icons.map(
    (module): MenuOption => {
      switch (module) {
        case "courses":
          // returns an object with the path, svg component and title of the adequate module
          return {
            path: (loggedUser ? getMyCoursesPath(program.name) : getLoginPath(program.name)),
            iconUrl: CoursesSVG,
            title: language.myCourses,
            key:"courses"
          };
        case "transactions":
          return {
            path: (loggedUser ? getTransactionsPath(program.name, transactionsCollectIdParam) : getLoginPath(program.name)),
            iconUrl: TransactionsSVG,
            title: language.transactions,
            key:"transactions"

          };
        case "impact":
          return {
            path: (loggedUser ? getImpactPath(program.name) : getLoginPath(program.name)),
            iconUrl: ImpactSVG,
            title: language.impact,
            key:"impact"

          };
        case "profile":
          return {
            path: (loggedUser ? getProfilePath(program.name) : getLoginPath(program.name)),
            iconUrl: ProfileSVG,
            title: language.profile,
            key:"profile"

          };
        case "settings":
          return {
            path: (loggedUser ? getSettingsPath(program.name) : getLoginPath(program.name)),
            iconUrl: SettingsSVG,
            title: language.settings,
            key:"settings"

         };
         case "messages":
          return {
            path: (loggedUser ? getMessagesPath(program.name) : getLoginPath(program.name)),
            iconUrl: MessageSVG,
            title: language.messages,
            key:"messages"

         };
         case "chatroom":
          return {
            iconUrl: ChatSVG,
            title: language.chat,
            key:"chatroom",

         };
         case "services":
          return {
            path: (loggedUser ? getServicesPath(program.name) : getLoginPath(program.name)),
            iconUrl: ServicesSVG,
            title: language.services,
            key:"services"

         };
        default:
          return {
            path: (loggedUser ? getSettingsPath(program.name) : getLoginPath(program.name)),
            iconUrl: SettingsSVG,
            title: language.settings,
            key:"autre"

         };
      }
    }
  );


  return (
    <div className={classes.container}>
      <img src={logo} className={isMediumScreen ? classes.mediumLogo : isSmallScreen ? classes.smallLogo : classes.logo} alt="logo"/>


    <ButtonContainer menuOptions={menuOptions}/>

      <div className={classes.settingsContainer}>
        <Link to={getSettingsPath(program.name)} role="button" aria-label={language.settings}>
        <Settings className={isSmallScreen ? classes.smallSettings : classes.settings} />
        </Link>
        </div>
    </div>
  );
};

export default Welcome;