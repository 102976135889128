import { isAndroid } from "utils/utils";
import { TtsInitalization } from "./initTTS";
import { useEffect, useState, useMemo, useCallback } from "react";

//This page handle hooks : the voice synthesis and getting param between component
/**
 * Returns a speech synthesis voice
 * @param {Language} language language key as that of app env config
 * languages object
 */
export const useGetVoice = (language: string): SpeechSynthesisVoice | null => {
    const [voices, setVoices] = useState<SpeechSynthesisVoice[]>([]);

    // Return available voices
    useEffect(() => {
        const processVoices = (voiceOptions: SpeechSynthesisVoice[]) => {
            setVoices(voiceOptions);
        };

        // Retrieve available voices
        const getVoices = () => {
                let voiceOptions : SpeechSynthesisVoice[] = window.speechSynthesis.getVoices();
                
                if (voiceOptions.length > 0) {
                    processVoices(voiceOptions);
                    return;
                }

                // Initialize TTS
                TtsInitalization({
                    maxTimeout: 5000,
                    interval: 250,
                }).then(() => {
                    voiceOptions = window.speechSynthesis.getVoices();
                    if (voiceOptions.length > 0) {
                        processVoices(voiceOptions);
                    }
                }).catch(error => {
                    console.error('Initialization failed:', error);
                });
        };

        // initial call to get voices
        getVoices();

        // Listener to get voices
        const voicesChanged = () => {
            getVoices();
        };

        if (typeof window !== 'undefined' && window.speechSynthesis) {
            window.speechSynthesis.addEventListener('voiceschanged', voicesChanged);
        }

        // remove listener
        return () => {
            if (typeof window !== 'undefined' && window.speechSynthesis) {
                window.speechSynthesis.removeEventListener('voiceschanged', voicesChanged);
            }
        };
    }, []);

    // Get voice base on user language
    const findVoice = useCallback((languages: string[]): SpeechSynthesisVoice | undefined => {
        let transformedLanguages = languages.map(lang => lang.toLowerCase());
        var index = transformedLanguages.indexOf("id-id" || "id_id");
        // If id_id or id-id  and it's android, replace id-ID to in-ID
        if(index !== -1 && isAndroid()){
            languages[index] = "in-ID";
        }
        let finalVoice = voices.find(voice => transformedLanguages.includes(voice.lang.toLowerCase()));
        if (!finalVoice) {
            transformedLanguages = languages.map(lang => lang.toLowerCase().replace("-", "_"));
            finalVoice = voices.find(voice => transformedLanguages.includes(voice.lang.toLowerCase()));
        }
        return finalVoice;
    }, [voices]);

    // Retrieve english language
    const getEnglishVoice = useCallback((): SpeechSynthesisVoice | null => {
        const ukVoice = findVoice(["en-GB", "en_GB"]);
        if (ukVoice) return ukVoice;

        const usVoice = findVoice(["en-US", "en_US"]);
        if (usVoice) return usVoice;

        return findVoice(["en"]) || null;
    }, [findVoice]);

    // Get selected voice
    const selectedVoice = useMemo(() => {
        if (language.startsWith("en")) {
            return getEnglishVoice();
        }
        return findVoice([language]) || null;
    }, [language, getEnglishVoice, findVoice]);

    return selectedVoice;
};
