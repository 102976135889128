import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {StyleProps} from "types/styles"
import {style} from "../../../style"

export default makeStyles((theme: Theme) => {
  const avatarWidth = "10vw"
  const avatarMaxWidth = "10rem"
  const defaultPadding = "10px"
  
  return createStyles({
    avatar: {
      fontSize: `calc(${avatarWidth} / 2)`,
      height: avatarWidth,
      width: avatarWidth,
      maxWidth: avatarMaxWidth,
      maxHeight: avatarMaxWidth,
      margin: "auto 0",
    },
    body: {
      padding: `${defaultPadding} 0`,
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      width: (props: StyleProps) => props.width,
      maxWidth: (props: StyleProps) => props.maxWidth,
    },
    date: {
      color: theme.palette.primary.main,
    },
    main: {
      display: "flex",
      padding: `${defaultPadding} 0`,
      width: (props: StyleProps) => props.width,
      maxWidth: (props: StyleProps) => props.maxWidth,
    },
    mainInfo: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-around",
      width: (props: StyleProps) => `calc(${props.width} - ${avatarWidth} - 5%)`,
      maxWidth: (props: StyleProps) => `calc(${props.maxWidth} - ${avatarWidth} - 5%)`,
      padding: `${defaultPadding} 0 ${defaultPadding} 5%`,
    },
    sender: {
      color: theme.palette.primary.main,
      textTransform: "capitalize",
      padding: 0,
      margin: 0,
    },
    title: {
      color: style.palette.black,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "none",
      margin: 0,
    }
  })
})