import localforage from 'localforage';
import { AppThunkDispatch } from 'redux/store';
import { clearResults } from './SettingsSlice';

const clearWorkboxCache = async () => {
  const cacheNames = await caches.keys();
  const workboxCacheNames = cacheNames.filter(name => name.startsWith('workbox') || name === 'images' || name === 'videos');
  await Promise.all(
    workboxCacheNames.map(name => caches.delete(name))
  );
};


const ClearCache = async (dispatch: AppThunkDispatch) => {
  try {
    dispatch(clearResults());
    await clearWorkboxCache()
    if (typeof localStorage !== 'undefined') {
      localStorage.clear();
    }
    if (typeof sessionStorage !== 'undefined') {
      sessionStorage.clear();
    }
    if (localforage) {
      localforage.removeItem('persist:root').then(function() {
        console.log('cache cleared');
    }).catch(function(err) {
        console.log(err);
    });

    localforage.clear().then(function() {
      // Run this code once the database has been entirely deleted.
      console.log('Database is now empty.');
  }).catch(function(err) {
      // This code runs if there were any errors
      console.log(err);
  });

    }

    const DBDeleteRequest = window.indexedDB.deleteDatabase('localforage')

    DBDeleteRequest.onerror = (event) => {
      console.error("Error deleting database.");
    };
    
    DBDeleteRequest.onsuccess = (event) => {
      console.log("Database deleted successfully");
    
      console.log(event); // should be undefined
    };

    


    if ('serviceWorker' in navigator) {
      await caches.keys().then(function(cacheNames) {
        cacheNames.forEach(function(cacheName) {
          caches.delete(cacheName);
        });
      });
    }
      navigator.serviceWorker.getRegistrations().then( (registrations) => {
        for(let registration of registrations) {
           registration.unregister().then(()=>(
            console.log('Unregistered service worker')
           )).catch((err)=>console.log(err));
        }
      });
    if (typeof window !== 'undefined') {
      window.location.reload();
    }
  } catch (error) {
    console.error(error);
  }
};

export default ClearCache;