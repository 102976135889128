import React, {useState} from "react";
import { useSelector } from "react-redux";
import { Notification } from "types/notifications";
import { selectLanguageObject } from "pages/Login/LoginSlice";
import { EmailCard, ErrorComponent } from "components";
import TabComponent from "../MessageTab";
import MessageSearchBar from "../MessageSearchBar/MessageSearchBar";
import { StyleProps } from "types/styles";
import useStyles from "./Mailbox.styles";

export interface MailboxProps {
  messages: Notification[];
  onClick: (notification: Notification) => void;
  style?: StyleProps;
  activeTab: "messages" | "archives"; // Add activeTab prop
}

const Mailbox: React.FC<MailboxProps> = (props) => {
  const { messages, onClick, style = { width: "90vw", maxWidth: "60rem" } } = props;

  const classes = useStyles(style);

  const dictionary = useSelector(selectLanguageObject);

  const [activeTab, setActiveTab] = useState<"messages" | "archives">("messages");

  const [searchItem, setSearchItem] = useState('')

  const handleTabChange = (tab: "messages" | "archives") => {
    setActiveTab(tab);
  };


  // Multiple query filter
  const filteredMessages = messages.filter((message) => {
    // Check if the user use the search bar
    if(searchItem.length > 0){
      const isArchived = message.archived;
      const queryItem = searchItem.toLowerCase();
      // Check if the sender field contains query string
      const QuerySender = message.sender.toLowerCase().includes(queryItem.toString())
      // Check if the body field contains query string
      const QueryMessage = message.body.toLowerCase().includes(queryItem.toString())
      const isQuery = QuerySender || QueryMessage
      // Check if the status of the message match the tab 
      const isMessage = activeTab === "messages" ? !isArchived : isArchived
      return isMessage && isQuery;
    }else{
      const isArchived = message.archived;
      return activeTab === "messages" ? !isArchived : isArchived;
    }
  });

  const handleInputChange = (e : React.ChangeEvent<HTMLInputElement>) => {
    setSearchItem(e.target.value)
}

  return (
    <div className={classes.container}>
      <MessageSearchBar handleInputChange={handleInputChange} />
      <TabComponent activeTab={activeTab} handleTabChange={handleTabChange} />
      {filteredMessages.length > 0 ? (
        filteredMessages.map((message: Notification, index: number) => (
          <EmailCard key={index} message={message} onClick={() => onClick(message)} />
        ))
      ) : (
        <ErrorComponent message={dictionary.noMessages} isError={false} />
      )}
    </div>
  );
};


export default Mailbox;
