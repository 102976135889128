import { Typography } from '@material-ui/core'
import { Close as CloseIcon } from "@material-ui/icons"
import {
    Button, Container, ErrorComponent,
    HeaderContainer, IconButton, Loader,
    Offline, Speech, Text, Title, Snackbar
} from 'components'
import { useToken } from 'cookies'
import { useServerErrorHandler } from 'hooks'
import {
    selectOnlineState
} from 'pages/Impact/Impact.slice'
import { selectLanguageObject } from 'pages/Login/LoginSlice'
import {
    fetchTransactionSurveyByCollect,
    selectTransactionSurveyByCollect,
    setCurrentQuestion
} from 'pages/Transactions/Transactions.slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import { AppThunkDispatch } from 'redux/store'
import suttiIcon from "resources/icons/sutti.svg"
import { getTransactionPath, getWelcomePath, transactionsCollectIdParam } from 'routes'
import useStyles from "./Transactions_Styles"
import { handleErrorCodes } from 'utils/httpErrorCodes'
import { useProgram } from '../../program'
import { ErrorDetails } from 'types'


interface TransactionsParams {
    collectId: string;
}

type TransactionsProps = RouteChildrenProps<TransactionsParams>;


//this page handle the transaction module wich is a survey to collect data will later have other fonctionnality
const Transactions: React.FC<TransactionsProps> = (props) => {
    const { match } = props
    if (!match) {
        throw new Error("Undefined match");
    }
    const { collectId } = match.params

    const program = useProgram()
    const classes = useStyles()
    const dispatch = useDispatch<AppThunkDispatch>()
    const language = useSelector(selectLanguageObject)
    const handleServerError = useServerErrorHandler()
    const { token } = useToken()
    const transactionSurveyState = useSelector(selectTransactionSurveyByCollect)
    const history = useHistory()
    const isOnline = useSelector(selectOnlineState)
    const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

    const handleCloseSnackbarSlowConnection = () => {
      setSnackbarState(false)
    }

    const { loading, error, survey, currentQuestionId } = transactionSurveyState

    React.useEffect(() => {
        if (survey && isOnline) {
            if (currentQuestionId) {
                history.push(getTransactionPath(program.name, transactionsCollectIdParam, survey.cmsId, currentQuestionId))
            }
        }
    }, [program, survey, history, isOnline, currentQuestionId])

    const fetchTransactionSurvey = React.useCallback(async () => {
        const programId = program.id
        await handleServerError(dispatch(
            fetchTransactionSurveyByCollect({ token, programId})
        ))
    }, [token, handleServerError, dispatch, program.id])


    React.useEffect(() => {
        if (isOnline) {
            fetchTransactionSurvey()
        }
    }, [isOnline, fetchTransactionSurvey])

    
    React.useEffect(() => {
    if(error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, language.connectionError))
    }
  }, [error, language.connectionError]);

  if (loading) {
      return <Loader />
  }

    if (!survey) {
        const message = language.surveyNotFound.replace("$param", collectId)
        return <ErrorComponent message={message} snackMsg={errorDetails.slowConnexionError ? language.slowConnection : undefined}/>
    }

    const { submissionDate, submitted } = transactionSurveyState

    const { title, description } = survey

    const handleClose = () => {
        history.push(getWelcomePath(program.name))
    }

    const handleStartSurvey = () => {
        const { cmsId, questions } = survey
        const surveyId = cmsId
        const questionId = currentQuestionId
            ? currentQuestionId
            : questions[0].cmsId

        dispatch(setCurrentQuestion(questionId));

        history.push(getTransactionPath(program.name, transactionsCollectIdParam, surveyId, questionId))
    }

    return (
        errorDetails.message 
        ? <ErrorComponent message={errorDetails.message} />
        :
        <Offline content={survey}>
                <HeaderContainer className={classes.header}>
                    <Speech text={title} className={classes.title}>
                        <Title title={title} />
                    </Speech>
                    <IconButton onClick={handleClose}>
                        <CloseIcon fontSize="large" />
                    </IconButton>
                </HeaderContainer>
                <Container>
                    {submitted &&
                        <Text>
                            {`${language.lastSubmitted} ${submissionDate}`}
                        </Text>
                    }
                    <Container variant="content">
                        <img src={suttiIcon} alt="logo" />
                        <Typography className={classes.survey}>
                            {language.transactions}
                        </Typography>
                    </Container>
                    <Text>
                        {description}
                    </Text>
                    <Button
                        label={language.start}
                        onClick={handleStartSurvey}
                        className={classes.button} />
                </Container>
                {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
            </Offline>
    )
}

export default Transactions
