import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    const columnCenter ={
        display:"flex",
        flexDirection:"column",
        alignItems:"center",
    }
    return createStyles({
        container:{
            ...columnCenter,
            height: "100vh",
            backgroundColor: theme.palette.primary.main,
            justifyContent: "center",
            rowGap:"4em",
            padding: "20px 20px",
            boxSizing: "border-box",
          },
        welcomeButtonContainer:{
            display:"flex",
            flexDirection:"row",
            flexWrap:"wrap",
            justifyContent:"center",
            columnGap:"0.75em",
            rowGap:"0.25em",
            },
        welcomeButton:{
            ...columnCenter,
            width:"30%"
        },
        title:{
            textAlign:"center",
            color: theme.palette.secondary.light,
            fontSize:"0.75em",
        },
        smallTitle:{
            textAlign:"center",
            color: theme.palette.secondary.light,
            fontSize:"0.725em"
        },
        button:{
            ...columnCenter,
            borderWidth:"2px",
            borderStyle:"solid",
            borderRadius:"50%",
            borderColor:"white",
            width:'5em',
            height:"5em",
            justifyContent:"center",
        },
        mediumButton:{
            ...columnCenter,
            borderWidth:"2px",
            borderStyle:"solid",
            borderRadius:"50%",
            borderColor:"white",
            width:'4em',
            height:"4em",
            justifyContent:"center", 
        },
        smallButton:{
            ...columnCenter,
            borderWidth:"2px",
            borderStyle:"solid",
            borderRadius:"50%",
            borderColor:"white",
            width:'3em',
            height:"3em",
            justifyContent:"center", 
        },
        settings:{
            fontSize:"3em",
            color:theme.palette.secondary.light
        },
        smallSettings:{
            fontSize:"2em",
            color:theme.palette.secondary.light
        },
        settingsContainer:{
            width:"100%",
            display:"flex",
            justifyContent:"end",
        },
        logo:{
            width:"8em",
        },
        mediumLogo:{
            width:"6em",
        },
        smallLogo:{
            width:"4em",
        },
        dialogContent: {
            textAlign: "center",
            }
    })
})