import { PushNotificationsInfos, UserPermissionAnswer } from '../types/userProfile'
import { publicVAPKey } from 'api/config';

const urlBase64ToUint8Array = (base64String: string) => {
  const padding = '='.repeat((4 - base64String.length % 4) % 4),
        base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

  const rawData = window.atob(base64),
        outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

export const registerForPushNotifications = async (): Promise<PushNotificationsInfos | false> => {
  if('PushManager' in window) {
    const registration = await navigator.serviceWorker.ready,
          vapidKey = publicVAPKey ?? '';

    const subscriptionParams = {
      userVisibleOnly: true,
      applicationServerKey: urlBase64ToUint8Array(vapidKey),
    };

    const subscription = await registration.pushManager.subscribe(subscriptionParams),
          subscribeInfos = {
            optedIn: 1,
            pushInfos: subscription
          };

    return subscribeInfos
  }
  return false
}

const requestUserPermission = async(): Promise<any> => {
  try {
    return await Notification.requestPermission(permission => permission)
  } catch(error: any) {
    return error
  }
}

export const checkUserPermission = async (): Promise<UserPermissionAnswer> => {
  let answer: UserPermissionAnswer = { 
    alreadyAnswered: false,
    success: false, 
    denied: false,
    pushNotificationsInfos: null
  }

  if(Notification.permission === 'default') {
    const permission = await requestUserPermission()

    if(permission !== 'default') {
      if (permission === 'granted') {
        const subscription =  await registerForPushNotifications()

        if(!!subscription) {
          answer.success = true
          answer.pushNotificationsInfos = subscription
          return answer
        }
      } 
      answer.success = true
      answer.denied = true
      return answer
    }
  }
  
  answer.alreadyAnswered = true 
  return answer
}

export const generateDummyNotification = async(title: string, body: string) => {
  const icon = `${process.env.PUBLIC_URL}/favicon.ico`,
        registration = await navigator.serviceWorker.ready;

  registration.showNotification(title,{
    body: body,
    badge: icon,
    icon: icon,
  })
}