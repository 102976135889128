import { makeStyles, Theme, createStyles } from '@material-ui/core'
import { style } from "../../../style"
import { StyleProps } from "types"

export default makeStyles((theme: Theme) => {
  return createStyles({
    headContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    icon: {
      "&:hover": {
        cursor: "pointer"
      }
    },
    button: {
      background: style.palette.white,
      marginTop: "1.5rem",
      color: theme.palette.primary.main,
      display: "flex-start",
      justifyContent: "space-between",
      maxWidth: (props: StyleProps) => props.maxWidth,
      width: (props: StyleProps) => props.width,
      height: (props: StyleProps) => props.height
    },
    smallButton: {
      background: style.palette.white,
      color: theme.palette.primary.main,
      left: "auto",
      right:"auto",
      fontSize: "0.70rem",
      display: "flex-start",
      justifyContent: "space-between",
      maxWidth: (props: StyleProps) => props.maxWidth,
      width: (props: StyleProps) => props.width,
      height: (props: StyleProps) => props.height
    },
    select: {
      position: "relative",
      right:"auto",
      left: "auto",
      top:"2rem",
      paddingRight: "0px !important"
    },
    smallSelect: {
      position: "relative",
      left: "auto",
      right:"auto",
      top:"1rem",
      paddingRight: "0px !important"
    },
  })
})