import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        button: {
            background: theme.palette.secondary.contrastText,
            textAlign: "left",
            color: theme.palette.primary.main,
            width: "15.5rem",
            height: "4rem",
            marginBottom: "0.938rem"
        },
        selected: {
            background: theme.palette.primary.light
        }
    })
})