import { Avatar, Divider, Typography } from '@material-ui/core'
import { Close as CloseIcon } from '@material-ui/icons'
import {
  Button,
  Container,
  HeaderContainer,
  IconButton,
  Loader,
  Offline,
  ParsedContent,
  Speech,
  Title
} from 'components'
import { useToken } from 'cookies'
import { useServerErrorHandler } from 'hooks'
import { selectOnlineState } from 'pages/Impact/Impact.slice'
import { selectLanguageObject, selectLoggedUser } from 'pages/Login/LoginSlice'
import {
  fetchInstructorDetails,
  selectCourseBySlug,
  selectInstructorBySlug,
  selectIsLoading,
  setAccessed
} from 'pages/MyCourses/MyCourses.slice'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import { AppState } from 'redux/rootReducer'
import { AppThunkDispatch } from 'redux/store'
import { getCoursePath, getMyCoursesPath } from 'routes'
import useStyles from './CourseSummary.styles'
import { useProgram } from '../../program'
import { capitalize } from '../../utils/utils'

interface CourseSummaryParams {
  courseSlug: string;
}

type CourseSummaryProps = RouteChildrenProps<CourseSummaryParams>;

//This is the page that displays the course summary/objective and the of the instructor details (in case of an instructor-led course). It is only shown on the first opening of a course.
const CourseSummary: React.FC<CourseSummaryProps> = (props) => {
  const { match } = props;

  if (!match) {
    throw new Error("Undefined match");
  }

  const program = useProgram()
  const { courseSlug } = match.params;
  const classes = useStyles();
  const { token } = useToken()
  const dispatch = useDispatch<AppThunkDispatch>();
  const language = useSelector(selectLanguageObject);
  const loggedUser = useSelector(selectLoggedUser);
  const isLoading = useSelector(selectIsLoading);
  const history = useHistory();
  const course = useSelector((state: AppState) =>
    selectCourseBySlug(state, courseSlug)
  );

  const handleServerError = useServerErrorHandler();
  const instructor = useSelector((state: AppState) =>
    selectInstructorBySlug(state, courseSlug)
  )
  const isOnline = useSelector(selectOnlineState)

  const fetchInstructor = React.useCallback(async () => {
    if (loggedUser && isOnline) {
      const userId = loggedUser.userId;
      await handleServerError(dispatch(fetchInstructorDetails({
        userId, courseSlug, token
      })));
    }
  }, [handleServerError, loggedUser, dispatch, isOnline, token, courseSlug])

  React.useEffect(() => {
    fetchInstructor()
  }, [fetchInstructor]);

  if (isOnline && isLoading) {
    return <Loader />;
  }

  const handleStartClick = () => {
    dispatch(setAccessed(courseSlug));
    history.push(getCoursePath(program.name, courseSlug));
  };

  const { title, imageUrl, summary } = course;

  const welcomeText = `${language.welcome
    }, ${capitalize(loggedUser?.firstName as string)}`;

  const handleClose = () => {
    history.push(getMyCoursesPath(program.name));
  }

  const renderAvatar = (imageUrl: string | undefined, title: string) => {
    return imageUrl
      ? <Avatar
        className={classes.avatar}
        src={imageUrl}
        alt={title}
      />
      : <Avatar className={classes.avatar}>
        {title.charAt(0).toUpperCase()}
      </Avatar>
  }

  return (
    <Offline content={course}>
      <HeaderContainer className={classes.header}>
        <Speech text="" className={classes.headerTitle}>
          <Title title={title} />
        </Speech>
        <IconButton onClick={handleClose}>
          <CloseIcon fontSize="large" />
        </IconButton>
      </HeaderContainer>
      <Container>
        <Container variant="content" className={classes.summaryContent}>
          <Speech text={welcomeText} className={classes.speech}>
            <Typography className={classes.title}>{welcomeText}</Typography>
          </Speech>
          {renderAvatar(imageUrl, title)}
          <ParsedContent htmlString={summary} className={classes.summary} />
          {instructor &&
            <>
              <Speech text={language.yourInstructor} className={classes.instructor}>
                <Typography >
                  {language.yourInstructor}
                </Typography>
              </Speech>
              <Divider className={classes.divider} />
              {renderAvatar(instructor.photo, instructor.name)}
              <Speech text={instructor.name} className={classes.name}>
                <Typography className={classes.instructor}>
                  {instructor.name}
                </Typography>
              </Speech>
            </>
          }
          <Button
              label={ language.start }
              onClick={ handleStartClick }
              className={ classes.button }
          />
        </Container>
      </Container>
    </Offline>
  );
};

export default CourseSummary;
