import { makeStyles, Theme, createStyles } from '@material-ui/core'

export default makeStyles((theme: Theme) => {
  return createStyles({
    headContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    icon: {
      "&:hover": {
        cursor: "pointer"
      }
    }
  })
})