import React, { useState, useEffect } from "react"
import { Card } from "components";
import useStyles from "./ChapterButton.styles";
import { StyleProps } from "types/styles";
import placeholderSrc from "../../resources/icons/suttiLogo192.png";

export interface IChapterButton {
  title: string
  description?: string
  imageUrl?: string
  completed?: boolean
  disabled?: boolean
  totalSections?: number
  chapterProgression?: number
  percentageCompletion?: number
  sectionProgression?: string
  style?: StyleProps
  onClick: () => void
}

const ChapterButton: React.FC<IChapterButton> = (props) => {
  const {
    title,
    imageUrl,
    percentageCompletion,
    onClick,
    disabled,
    chapterProgression,
    totalSections,
    completed = false,
    description
  } = props

  const classes = useStyles(props);
  const [imgSrc, setImgSrc] = useState(placeholderSrc || imageUrl);

  useEffect(() => {
    if (imageUrl !== undefined) {
      const img = new Image();
      img.src = imageUrl;
      img.onload = () => {
        setImgSrc(imageUrl);
      }
    }
  }, [imageUrl]);

  return (
    <div className={classes.container}>
      <Card onClick={() => onClick()}
            icon={imgSrc}
            title={title}
            description={description || ""}
            checkIconClass={classes.checkIcon}
            percentageCompletionClass={classes.percentageCompletion}
            completed={completed}
            percentageCompletion={percentageCompletion}
            percentContainer= {classes.percentageContainer}
            percentFiller={classes.percentageFiller}
            isChapter={true}
            disabled={disabled}
            chapterProgression={chapterProgression}
            totalSections={totalSections}
            sectionProgression={classes.sectionProgression}
            style={{maxWidth: "40rem", height: '100%', width: '70vw'}} />
    </div>
  );
};

export default ChapterButton;
