import React from "react"
import {CheckCircle as CheckCircleClass} from '@material-ui/icons'
import { useSelector } from "react-redux"
import { selectLanguageObject } from "pages/Login/LoginSlice"
import { CardProps } from "../../Card/Card"

const ChapterProgression:React.FC<CardProps> = (props) => {
  const {
    sectionProgression,
    totalSections,
    percentContainer,
    percentageCompletion,
    percentFiller,
    chapterProgression,
    completed,
    checkIconClass
  } = props

  const dictionary = useSelector(selectLanguageObject)

  return (
    <div className={sectionProgression}>
      {
        percentageCompletion === 0
        ? <p>{dictionary.startChapter}</p>
        :
          <>
              <p>{dictionary.section} {chapterProgression} / {totalSections}</p>
              <div className={percentContainer}>
                  <div className={percentFiller} />

                  {
                    percentageCompletion === 100 && completed &&
                      <CheckCircleClass className={checkIconClass} />
                  }
              </div>
          </>
      }
    </div>
  )
}

export default ChapterProgression