

import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"
import React from "react"

const ProfileSVG: React.FC<SVGPropsInterface> = ({width, height,fill}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg aria-hidden="true" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 0 158 174" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M128.44 75.5063C129.001 73.0913 131.318 71.6112 133.615 72.2003C144.751 75.0557 149.266 84.4733 146.944 98.4653C144.688 112.061 140.003 119.082 134.082 120.997L133.818 121.077L133.4 122.515C132.859 124.362 132.211 126.528 131.673 128.18L131.383 129.05C129.177 135.499 126.394 141.506 122.671 147.205C115.993 157.428 106.959 165.541 95.1037 170.854C91.0356 172.677 87.7163 173.448 82.0095 173.883L80.2993 174L78.8708 173.848L73.5286 173.331L72.3575 173.21C68.2355 172.763 65.948 172.26 63.5026 171.121C51.9095 165.719 42.9335 157.729 36.1639 147.777C32.2212 141.981 29.2402 135.861 26.8717 129.327C26.2006 127.476 25.2873 124.592 24.5783 122.292L24.2082 121.086L23.9178 120.997C18.1007 119.115 13.4768 112.306 11.177 99.1749L11.056 98.4653C8.73425 84.4733 13.2495 75.0557 24.3849 72.2003C26.6824 71.6112 28.9993 73.0913 29.5598 75.5063C30.1203 77.9214 28.7121 80.3567 26.4146 80.9458C20.1188 82.5602 17.8838 87.2218 19.4928 96.9186C20.6893 104.129 22.5096 108.625 24.5887 110.945C25.6195 112.096 26.4669 112.492 27.2975 112.539L27.4752 112.544H30.4018L31.4648 115.41C31.587 115.74 33.7353 122.98 34.8745 126.122C36.998 131.98 39.6508 137.426 43.1304 142.541C49.0403 151.229 56.8337 158.167 66.973 162.891C68.4178 163.564 70.0311 163.913 73.3738 164.271L79.7111 164.889L80.4774 164.969L80.7479 164.952C85.7785 164.605 88.2956 164.073 91.3728 162.737L91.745 162.573C102.042 157.959 109.821 150.973 115.611 142.11C118.882 137.103 121.35 131.775 123.324 126.005L123.61 125.139C124.528 122.297 125.909 117.45 126.387 115.877L126.535 115.41L127.598 112.544H130.525C131.413 112.544 132.307 112.178 133.411 110.945C135.49 108.625 137.311 104.129 138.507 96.9186C140.116 87.2218 137.881 82.5602 131.586 80.9458C129.288 80.3567 127.88 77.9214 128.44 75.5063Z"  fill={fill ? fill : defaultTheme.fill}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M42.0074 12.9265L40.7759 13.7813L40.6194 15.2539C36.3197 55.7027 48.1796 79.6672 76.3606 85.763C103.813 91.7011 124.123 88.6168 135.881 71.7832V70.016L135.919 69.0736C136.976 42.3233 130.289 22.0875 115.261 11.2521L114.616 10.7953L114.413 10.6907C88.0747 -2.88026 63.7576 -2.17103 42.0074 12.9265Z"  fill={fill ? fill : defaultTheme.fill}/>
    <path fill-rule="evenodd" clip-rule="evenodd" d="M25.8973 25.09C22.2373 31.3646 20.4308 38.7728 19.9974 47.7921L19.9601 48.6399C19.7014 55.1258 20.0705 61.3744 21.1488 73.0029C21.1681 73.2115 21.2056 73.4179 21.2608 73.6195C22.6111 78.5492 26.6813 83.2525 33.3338 87.9473C34.2515 88.5949 35.419 88.7245 36.4473 88.2928C47.4722 83.665 54.1917 78.0331 56.3433 71.0784C56.608 70.2229 56.5418 69.2971 56.1647 68.4954L56.0542 68.28C56.0033 68.1886 55.8202 67.8681 55.5811 67.4495L55.5808 67.4489L55.5802 67.4479C55.0961 66.6004 54.3831 65.3522 54.0717 64.7887C53.1882 63.1902 52.4182 61.6783 51.6878 60.0661C48.4195 52.8524 46.5398 44.8967 46.5398 35.4297L46.5457 33.4361C46.577 28.2686 46.7332 24.292 47.0095 21.5333L47.046 21.1826C47.1696 20.0398 47.312 19.16 47.4595 18.5717L47.4903 18.453L47.551 18.3949C49.3284 16.5472 48.5308 12.9462 45.6218 12.5239C37.9424 11.4092 31.296 15.8347 25.8973 25.09Z"  fill={fill ? fill : defaultTheme.fill}/>
    <path d="M31.914 80.3076C33.8643 80.3076 35.4586 81.8327 35.57 83.7557L35.5762 83.9709V92.0881C35.5762 94.1113 33.9366 95.7514 31.914 95.7514C29.9636 95.7514 28.3693 94.2263 28.2579 92.3033L28.2517 92.0881V83.9709C28.2517 81.9477 29.8914 80.3076 31.914 80.3076Z"  fill={fill ? fill : defaultTheme.fill}/>
    <path d="M129.225 79.2781C131.176 79.2781 132.77 80.8031 132.881 82.7261L132.887 82.9414V91.0586C132.887 93.0817 131.248 94.7219 129.225 94.7219C127.275 94.7219 125.681 93.1968 125.569 91.2738L125.563 91.0586V82.9414C125.563 80.9182 127.203 79.2781 129.225 79.2781Z"  fill={fill ? fill : defaultTheme.fill}/>
    <path d="M132.364 64.8638C134.315 64.8638 135.909 66.3888 136.02 68.3118L136.027 68.5271V76.6442C136.027 78.6674 134.387 80.3076 132.364 80.3076C130.414 80.3076 128.82 78.7825 128.708 76.8595L128.702 76.6442V68.5271C128.702 66.5039 130.342 64.8638 132.364 64.8638Z"  fill={fill ? fill : defaultTheme.fill}/>
    </svg>
    
  )
}

export default ProfileSVG
