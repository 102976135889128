import store, { persistor } from 'redux/store';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from 'redux-persist/integration/react';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { MultiPrograms } from './program'
import { isDevelopment } from 'api/config';
import { Loader } from 'components';
import "core-js/stable";
import "regenerator-runtime/runtime";




//This page handle the rending when the participant is not loggin
const render = () => {

  const App = require('./app').default


  ReactDOM.render(
    <Provider store={store}>
      <React.StrictMode>
        <BrowserRouter>
          <PersistGate loading={<Loader />} persistor={persistor}>
            <MultiPrograms>
                <App />
            </MultiPrograms>
          </PersistGate>
        </BrowserRouter>
      </React.StrictMode>
    </Provider>,
    document.getElementById('root')
  );
}


render()

if (isDevelopment && module.hot) {
  module.hot.accept('./app', render)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
