import { Button } from 'components'
import clsx from "clsx"
import React from 'react'
import useStyles from "./SelectorButton_Styles"

export interface SelectorButtonProps {
    value: string
    onClick: () => void
    selected?: boolean
}

//custome button for Answers component
const SelectorButton: React.FC<SelectorButtonProps> = ({value, onClick, selected}) => {
    const classes = useStyles()

    return (
        <Button
            label={value}
            onClick={onClick}
            className={selected 
                ? clsx(classes.selected, classes.button)
                : classes.button}
        />
            
    )
}

export default SelectorButton
