import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import { getService, loginService } from 'api'
import {AppState} from 'redux/rootReducer'
import {handleError} from '../../utils/httpErrorCodes';
import {ErrorType, Language, LanguageObject, User} from 'types'
import { LanguagesList } from '../../resources/locales'
import { podDefaultLanguage } from 'api/config';

interface LoginParams {
  programId: string;
  code: string;
}
export const fetchUser: AsyncThunk<User, LoginParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'user/fetchUser',
  async ({programId, code}, {rejectWithValue}) => {
    try {
      const url = "/user/signIn"
      return await loginService(url, programId, code, navigator.userAgent)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface RefreshTokenParams {
  token: string;
}
export const fetchRefreshToken: AsyncThunk<User, RefreshTokenParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'user/refreshToken',
  async ({token}, {rejectWithValue}) => {
    try {
      const url = "user/refreshToken"
      return await getService(token, url)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface UserState {
  loggedUser: User | null
  language: Language
  firstAccess: boolean
  isLoading: boolean
  error: ErrorType | null
}

const initialState: UserState = {
  loggedUser: null,
  language: podDefaultLanguage as string,
  firstAccess: true,
  isLoading: false,
  error: null
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setLanguage(state, {payload}: PayloadAction<Language>) {
      state.language = payload
    },
    setFirstAccess(state, {payload}: PayloadAction<boolean>) {
      state.firstAccess = payload
    }
  },
  extraReducers: {
    [fetchUser.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchUser.fulfilled.type]: (state, {payload}: PayloadAction<User>) => {
      state.loggedUser = payload
      state.isLoading = false
      state.error = null
    },
    [fetchUser.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    },
    [fetchRefreshToken.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchRefreshToken.fulfilled.type]: (state, {payload}: PayloadAction<User>) => {
      state.loggedUser = payload
      state.isLoading = false
      state.error = null
    },
    [fetchRefreshToken.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    }
  }
})

export const {setLanguage, setFirstAccess} = userSlice.actions

export default userSlice.reducer

export const selectLoggedUser = (state: AppState) => {
  return state.user.loggedUser
}

export const selectLanguage = (state: AppState): Language => state.user.language

export const selectLanguageObject = (state: AppState): LanguageObject => {
  const {user} = state
  let filename;
  if (!LanguagesList.includes(user.language)) {
    const defaultLanguage = podDefaultLanguage as string;
    filename = `${defaultLanguage}.json`;
    user.language = defaultLanguage; // when the user's preferredLanguage will be available in frontend, use it instead
    setLanguage(user.language); // when the user's preferredLanguage will be available in frontend, use it instead
  } else {
    filename = `${user.language}.json`;
  }
  const languageObject = require(`resources/locales/${filename}`)
  return languageObject
}

export const selectFirstAccess = (state: AppState) => state.user.firstAccess
