import { ClickAwayListener, MenuItem, Popover } from "@material-ui/core";
import { AppThunkDispatch } from "redux/store";
import clsx from "clsx";
import { Icon, IconButton } from "components";
import React, { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from "react-redux";
import downIcon from "resources/icons/arrowDown.svg";
import upIcon from "resources/icons/arrowUp.svg";
import { Language } from "types";
import { selectLanguage, setLanguage } from "./LoginSlice";
import useStyles from "./Select_Styles";
import { useProgram } from '../../program'

interface Option {
  name: Language;
  value: string;
  html: string;
}

const getIcon = (language: Language): string => {
  return `${process.env.PUBLIC_URL}/flags/${language}.svg`
};

interface SelectProps {
  className?: string;
}

const Select: React.FC<SelectProps> = (props) => {
  const { className } = props;
  const program = useProgram();
  const classes = useStyles();
  const dispatch = useDispatch<AppThunkDispatch>();
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const language = useSelector(selectLanguage);
  const options: Array<Option> = program.configs?.languages?.map(
    (language) => ({
      name: language.code,
      value: getIcon(language.code),
      html: language.html
    })
  );
  const selectedOption = useMemo(() => options.find(option => option.name === language)
    , [options, language])

  useEffect(() => {
    if(!selectedOption){
      dispatch(setLanguage(program.configs.defaultLanguage))
    }
  }, [dispatch, selectedOption, program]);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClick = (option: Option) => {
    dispatch(setLanguage(option.name));
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={clsx(classes.root, className)}>
        <Icon className={classes.icon} imageUrl={getIcon(language)} />
        <IconButton
          className={classes.iconButton}
          onClick={open ? handleClose : handleClick}
        >
          {open ? (
            <Icon imageUrl={downIcon} className={classes.selectIcon} />
          ) : (
            <Icon imageUrl={upIcon} className={classes.selectIcon} />
          )}
        </IconButton>
        <Popover
          open={open}
          onClose={handleClose}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          classes={{
            paper: classes.paper,
          }}
        >
          {options.map((option, index) => {
            return (
              <MenuItem
                key={index}
                onClick={() => handleMenuItemClick(option)}
                className={classes.menuItem}
              >
                <Icon className={classes.icon} imageUrl={option.value} />
              </MenuItem>
            );
          })}
        </Popover>
      </div>
    </ClickAwayListener>
  );
};

export default Select;
