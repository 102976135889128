import React from "react"
import clsx from "clsx"
import useStyles from "./HeaderContainer.styles"
import { useH360Screen } from "hooks"
import { useMediumScreen } from "hooks"

interface HeaderContainerProps {
    children: React.ReactNode
    className?: string
}

const HeaderContainer: React.FC<HeaderContainerProps> = ({ children, className }) => {
    const classes = useStyles()

    const isH360Screen = useH360Screen()

    const isMediumScreen = useMediumScreen()

    if (isH360Screen) {
        const smallSizeContainer = isH360Screen
        ? clsx(classes.container, classes.smallContainer)
        : classes.container
        return (
            <div className={clsx(smallSizeContainer,className)}>
                {children}
            </div>
        )
    } else if (isMediumScreen) {
        const mediumSizeContainer = isMediumScreen
        ? clsx(classes.container, classes.mediumContainer)
        : classes.container
        return (
            <div className={clsx(mediumSizeContainer,className)}>
                {children}
            </div>
        )
    } else {
        return (
            <div className={clsx(classes.container,className)}>
                {children}
            </div>
        )
    }
}

export default HeaderContainer