import React from "react"
import { useSelector } from "react-redux"
import { Avatar } from "@material-ui/core"
import { NotificationTypes } from 'constants/notifications'
import { Notification } from "types/notifications"
import { selectLanguage, selectLanguageObject } from "pages/Login/LoginSlice"
import { ErrorComponent, Speech, MessageBody } from "components"
import { StyleProps } from "types/styles"
import { style as theme } from "../../../style"
import useStyles from "./MessageDetails.styles"


export interface MessageDetailsProps {
  message: Notification,
  style?: StyleProps,
}

const MessageDetails: React.FC<MessageDetailsProps> = (props) => {
  const {
    message,
    style={width: "90vw", maxWidth: "60rem"}
  } = props

  const classes = useStyles(style)

  const dictionary = useSelector(selectLanguageObject)
  const language = useSelector(selectLanguage)

  let displaySlowConnectionWarn = false

  const renderAvatar = (title: string) => {
    const initials = title.split(" ").map(word => word[0]).join("")
    return (
      <Avatar className={classes.avatar}>
        { initials ? initials.toUpperCase() : ""}
      </Avatar>
    )
  }

  const renderTitle = (type: string, title: string) => {
    switch (type) {
      case NotificationTypes.priceChange:
        return `${dictionary.priceNotificationTitle} ${JSON.parse(title).pointOfSale ?? ""}`
      case NotificationTypes.simpleMessage:
        return `${dictionary.newNotificationTitle} ${message.sender}`
      default:
        return ''
    }
  }

  const renderContent = (type: string, body: string) => {
    switch (type) {
      case NotificationTypes.priceChange:
        return <MessageBody body={body} type={type} style={{color: theme.palette.black}}/>
      case NotificationTypes.simpleMessage:
        return <MessageBody body={body} type={type} style={{color: theme.palette.black}}/>
      case NotificationTypes.newSession:
        return <MessageBody body={body} type={type} style={{color: theme.palette.black}}/>
      default:
        return ''
    }
  }

  const renderDate = (date: string) => {
    return date ? new Date(date).toLocaleDateString(language) : ""
  }

  const renderDateForSpeech = (date: string) => {
    const options : Intl.DateTimeFormatOptions = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' };
    return date ? new Date(date).toLocaleDateString(language, options) : ""
  }

  return (
    <div>
      {
        message
        ? <div className={classes.container}>
          <div className={classes.main}>
            {renderAvatar(message.sender)}
            <div className={classes.mainInfo}>
              <div>
                <Speech text={message.sender}>
                  <h3 className={classes.sender}>
                    {message.sender}
                  </h3>
                </Speech>
                <Speech text={renderTitle(message.type, message.title)}>
                  <p className={classes.title}>
                    { renderTitle(message.type, message.title) }
                  </p>
                </Speech>
              </div>
              <Speech text={renderDateForSpeech(message?.sentDate)}>
                <div className={classes.date}>
                  { renderDate(message?.sentDate) }
                </div>
              </Speech>
            </div>
          </div>
          <div className={classes.body}>
            { renderContent(message.type, message.body) }
          </div>
        </div>
        : <ErrorComponent message={dictionary.noMessages}
                          isError={false}
                          snackMsg={displaySlowConnectionWarn ? dictionary.slowConnection : undefined} />
      }
    </div>
  )
}

export default MessageDetails