import { Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import { AppState } from "redux/rootReducer";
import { AppThunkDispatch } from "redux/store";
import {
  ChapterButton, Dialog,
  ErrorComponent,
  HeaderContainer, Icon, Loader, Offline,
  Speech,
  Title,
  Snackbar
} from "components";
import { useToken } from "cookies";
import { useServerErrorHandler } from "hooks";
import { selectLanguageObject } from "pages/Login/LoginSlice";
import {
  fetchCourseBySlug, selectCourseBySlug,
  selectError,
  selectIsLoading, setCourseCompletion
} from "pages/MyTermsAndConditions/MyTermsAndConditionsSlice";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { RouteChildrenProps, useHistory } from "react-router";
import back from "resources/icons/back.svg";
import cross from "resources/icons/cross.svg";
import manDancing from "resources/icons/manDancing.svg";
import peacock from "resources/icons/peacock.svg";
import { getTermsAndConditionsAgreementPath, getSettingsPath, getWelcomePath } from "routes";
import useStyles from "./TermsAndConditions_Styles";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import {handleErrorCodes} from "utils/httpErrorCodes"
import { useProgram } from '../../program'
import { ErrorDetails } from "types";

interface CourseParams {
  courseSlug: string;
}

type CourseProps = RouteChildrenProps<CourseParams>;

const TermsAndConditions: React.FC<CourseProps> = (props) => {
  const { match } = props
  if (!match) {
    throw new Error("Undefined match");
  }
  const { courseSlug } = match.params

  const program = useProgram()
  const classes = useStyles();
  const { token } = useToken()
  const dispatch = useDispatch<AppThunkDispatch>()
  const isLoading = useSelector(selectIsLoading)
  const handleServerError = useServerErrorHandler()
  const language = useSelector(selectLanguageObject)
  const history = useHistory()
  const course = useSelector((state: AppState) => selectCourseBySlug(state, courseSlug))
  const error = useSelector(selectError)
  const isOnline = useSelector(selectOnlineState)
  const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

  const handleCloseSnackbarSlowConnection = () => {
    setSnackbarState(false)
  }

  React.useEffect(() => {
    if (isOnline) {
      handleServerError(dispatch(fetchCourseBySlug({ courseSlug, programId: program.id, token })))
    }
  }, [dispatch, courseSlug, token, handleServerError, isOnline, program])
  

  
  React.useEffect(() => {
    if(error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, language.connectionError))
    }
  }, [error, language.connectionError]);


  if (isOnline && isLoading) {
    return <Loader />
  }

  const { imageUrl, title, chapters } = course

  const handleClick = async (id: string) => {
    const currentSectionId = course.chapters![id].currentSection
    history.push(getTermsAndConditionsAgreementPath(program.name, courseSlug, id, currentSectionId))
  }

  const handleClose = () => {
    history.push(getSettingsPath(program.name))
  }

  const renderChapterContent = () => {
    const chaptersToMap = chapters
      ? Object.keys(chapters)
        .map(key => chapters[key])
        .sort((a, b) => a.order - b.order)
      : []

    if (chaptersToMap.length > 0) {
      return chaptersToMap.map(({ title, imageUrl, id,
        visitedSections, sections, completed }, index) => {
        const totalSections = sections ? Object.keys(sections).length : 0
        const percentageCompletion = visitedSections
          ? (visitedSections.length / totalSections) * 100
          : 0

        const isOddIndex = index % 3 === 0

        return (
          <Grid container
            justify="center"
            alignItems="center"
            className={isOddIndex ? classes.oddGrid : classes.evenGrid}
            key={id}
          >
            <ChapterButton
              title={title}
              imageUrl={imageUrl}
              percentageCompletion={percentageCompletion}
              completed={completed}
              onClick={() => handleClick(id)}
            />
          </Grid>
        )
      })
    }
    const textToDisplay = language.noChapters.replace("$param", course.title)
    return <Title title={textToDisplay} />

  }

  const handleCloseDialog = () => {
    dispatch(setCourseCompletion(courseSlug))
  }

  const handlequit = () => {
    history.push(getWelcomePath(program.name));
  }

  const allChapterCompleted = () => {
    const chapterKeys = chapters ? Object.keys(chapters) : []
    return (chapterKeys.length > 0)
      ? chapterKeys.every(key => chapters![key].completed)
      : false
  }

  const showDialog = !course.shownCompletionDialog && allChapterCompleted()

  const renderDialogContent = () => {
    const customMessage = language.congratsMessage.replace("$param", course.title)
    const congratsMessage = language.congratulations
    const congratsInfo = language.congratsInfo
    const speechText = `${congratsMessage} ${customMessage} ${congratsInfo}`
    return (
      <div className={classes.dialogContent}>
        <Speech text={speechText}>
          <>
            <Typography>{congratsMessage}</Typography>
            <Typography>{customMessage}</Typography>
            <Typography>{congratsInfo}</Typography>
          </>
        </Speech>
        <div className={classes.icons}>
          <Icon imageUrl={manDancing} />
          <Icon imageUrl={peacock} />
        </div>
      </div>
    )
  }

  return (
    errorDetails.message 
    ? <ErrorComponent message={errorDetails.message}  path={getWelcomePath(program.name)}/>
    :
    <Offline content={chapters}>
      <HeaderContainer className={classes.headContainer}>
        <Icon onClick={handleClose} className={classes.icon} imageUrl={back} />
        <div className={classes.titleContainer}>
          <img src={imageUrl} alt={imageUrl} className={classes.image} />
          <Title title={title} />
        </div>
        <Icon onClick={handlequit} className={classes.icon} imageUrl={cross} />
      </HeaderContainer>
      <Grid container
        className={classes.root}
        justify="center"
        alignItems="center"
      >
        {renderChapterContent()}
      </Grid>
      <Dialog open={showDialog} onClose={handleCloseDialog}>
        {renderDialogContent()}
      </Dialog>
      {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
    </Offline>
  )
};

export default TermsAndConditions;
