

import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"
import React from "react"

const ImpactSVG: React.FC<SVGPropsInterface> = ({width, height,stroke}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg aria-hidden="true" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 0 183 180" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M129.161 129.715C115.881 142.394 97.8021 150.182 77.855 150.182C37.004 150.182 4 117.52 4 77.0925C4 36.665 37.004 4 77.855 4C118.706 4 151.713 36.665 151.713 77.0925C151.713 90.788 147.922 103.591 141.329 114.536M127.195 102.604C131.237 94.984 133.522 86.304 133.522 77.0925C133.522 46.7417 108.706 22.1818 78.0385 22.1818C47.3712 22.1818 22.5551 46.7417 22.5551 77.0925C22.5551 107.443 47.3712 132 78.0385 132C93.1391 132 106.819 126.046 116.819 116.383M103.315 103.544C96.7077 109.69 87.8139 113.455 78.0385 113.455C57.6577 113.455 41.1102 97.0786 41.1102 76.909C41.1102 56.7363 57.6577 40.3636 78.0385 40.3636C98.4225 40.3636 114.967 56.7363 114.967 76.909C114.967 81.54 114.095 85.9727 112.503 90.0508M77.4927 76.7273L150.258 146.545L179 147.636M133.522 130.909V159.636M133.522 128.364L162.264 129.818M150.258 147.636V176M59.3015 77.0909C59.3015 87.1675 67.5706 95.2727 77.855 95.2727C82.6689 95.2727 87.0472 93.4924 90.3366 90.5688C94.0713 87.2503 96.4116 82.4508 96.4116 77.0909C96.4116 67.0143 88.1426 58.9091 77.855 58.9091C67.5706 58.9091 59.3015 67.0143 59.3015 77.0909Z" stroke={stroke ? stroke : defaultTheme.stroke} strokeWidth="7" strokeMiterlimit="10" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
    
  )
}

export default ImpactSVG
