import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            flexGrow: 1,
            padding: "1.563rem 0.625rem 1.563rem 0.625rem",
            overflowX: "hidden",
            width: "100%",
            margin: 0,
            height: "calc(100% - 122px)"
        },
        oddGrid: {
            alignSelf: "end",
            marginBottom: "0.938rem",
        },
        evenGrid: {
            alignSelf: "end",
            marginBottom: "0.938rem",
            width: "50%"
        },
        image: {
            background: "none",
            height: "2.188rem",
            width: "2.188rem"
        },
        headContainer: {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between"
        },
        titleContainer: {
            display: "flex",
            alignItems: "center",
            "&>*:first-child": {
                marginRight: "0.313rem"
            }
        },
        icon: {
            "&:hover": {
                cursor: "pointer"
            }
        },
        dialogContent: {
            textAlign: "center",
            "&>*:not(:last-child)": {
                marginBottom: "0.625rem",
            },
            "&>*:not(:first-child)": {
                fontFamily: theme.typography.fontFamily,
                fontSize: "0.875rem",
                color: theme.palette.secondary.main
            },
            "&>*:first-child": {
                fontSize: "1.6875rem",
                fontWeight: 700
            }
        },
        icons: {
            marginTop: "1.25rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }
    })
})
