import {
    KeyboardArrowLeftOutlined as PrevIcon,
    KeyboardArrowRightOutlined as NextIcon
} from "@material-ui/icons";
import {
    Answers, Button, Container, Header, ErrorComponent,
    Loader, Offline, Question, Snackbar, SuccessPage
} from 'components';
import { useToken } from "cookies";
import {
    addAnsweredQuestionId, AnswerValue,
    removeAnsweredQuestionId,
    selectBusyState,
    selectError, selectLoading,

    selectSurveyByCollect,
    sendSurveyResults,
    setAnswerValues, setCurrentQuestion
} from "pages/Impact/Impact.slice";
import { selectLanguageObject, selectLoggedUser } from 'pages/Login/LoginSlice';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from "react-router-dom";
import { AppState } from "redux/rootReducer";
import { AppThunkDispatch } from "redux/store";
import { getSurveyPath, getWelcomePath } from "routes";
import { ErrorDetails, Value } from "types";
import useStyles from "./Survey_Styles";
import { selectLanguage } from "pages/Login/LoginSlice" ;
import { handleErrorCodes } from "utils/httpErrorCodes";
import { useProgram } from '../../program'

interface SurveyProps {
    collectId: string
}
//This page handle the survey that collect data from the participant
const Survey: React.FC<SurveyProps> = (props) => {
    const { collectId } = props
    const program = useProgram()
    const classes = useStyles()
    const language = useSelector(selectLanguageObject)
    const dispatch = useDispatch<AppThunkDispatch>()
    const history = useHistory()
    const { token } = useToken();
    const loggedUser = useSelector(selectLoggedUser)
    const error = useSelector(selectError)
    const loading = useSelector(selectLoading)
    const selectedSurvey = useSelector((state: AppState) =>
        selectSurveyByCollect(state, collectId))
    const isBusy = useSelector(selectBusyState)
    const [showCompletion, setShowCompletion] = React.useState(false)
    const [openSnackbar, setOpenSnackbar] = React.useState(false)
    const locale = useSelector(selectLanguage)
    const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

    const handleCloseSnackbarSlowConnection = () => {
      setSnackbarState(false)
    }

    React.useEffect(() => {
        if (selectedSurvey && !selectedSurvey.answerValues) {
            const { survey, collect } = selectedSurvey
            const initialAnswerValues: Array<AnswerValue> = survey.questions
                .map(({ cmsId, answerType }) => {
                    return {
                        questionId: cmsId,
                        inputDate: new Date().toUTCString(),
                        value: answerType === "checkbox"
                            ? []
                            : ""
                    }
                })

            dispatch(setAnswerValues({
                answerValues: initialAnswerValues,
                collectId: collect
            }))
        }
    }, [dispatch, selectedSurvey])

    
    React.useEffect(() => {
        if(error && error.httpStatus !== 503) {
            setErrorDetails(handleErrorCodes(error, language.connectionError))
        }
    }, [error, language.connectionError]);

    if (loading || isBusy) {
        return <Loader />
    }

    if (!selectedSurvey) {
        const message = language.surveyNotFound.replace("$param", collectId)
        return <ErrorComponent message={message} snackMsg={errorDetails.slowConnexionError ? language.slowConnection : undefined} />
    }

    const { survey, currentQuestionId, answeredQuestionIds,
        pending, collect, answerValues } = selectedSurvey

    const { cmsId, questions } = survey

    const renderOptions = () => {
        return questions
            .map(({ cmsId, title, optional }) => (
                {
                    id: cmsId,
                    value: title,
                    isChecked: answeredQuestionIds?.includes(cmsId) || optional
                }))
    }

    const questionIds = questions.map(question => question.cmsId)

    const currentQuestionIndex = questionIds.findIndex(questionId =>
        questionId === currentQuestionId?.toString())
    const currentIndex = currentQuestionIndex !== -1 ? currentQuestionIndex : 0

    const message = pending ? language.dataSaved : language.dataSent

    let thousandSeparator = Intl.NumberFormat(locale).format(11111).replace(/\p{Number}/gu, '');
    let decimalSeparator = Intl.NumberFormat(locale).format(1.1).replace(/\p{Number}/gu, '');

    const handleSubmit = () => {
        if (loggedUser) {
            const surveyAnswers = {
                collect: collect.toString(),
                userId: loggedUser.userId.toString(),
                surveyCmsId: survey.cmsId,
                inputs: answerValues.map(({ questionId, inputDate, value }, index) => ({
                    questionCmsId: questionId,
                    inputDate,
                    answer: (value ?   ( survey.questions[index].answerType === "number"  ? value.toString().replace(new RegExp('\\' + thousandSeparator, 'g'), '').replace(new RegExp('\\' + decimalSeparator), '.') : value.toString()) : "")
                }))
            }
            dispatch(sendSurveyResults(token, surveyAnswers))
            setShowCompletion(true)
            setOpenSnackbar(true)
        } else {
            throw new Error(language.userNotFound)
        }
    }

    const handleChangeQuestion = (currentQuestionId: string | undefined) => {
        dispatch(setCurrentQuestion({ currentQuestionId, collectId: collect }))
        history.push(getSurveyPath(program.name, collect, cmsId, currentQuestionId))
    }

    const handleScroll = () => {
        document.getElementById("form")!.scrollTo(0,0);
}

    const renderButtonContent = () => {

        const isDisabled = !renderOptions().every(option => option.isChecked)

        const handleNextClick = () => {
            if (currentIndex < questions.length - 1) {
                const idToSet = questionIds[currentIndex + 1]
                handleChangeQuestion(idToSet);
                handleScroll();
            }
        }

        const handlePrevClick = () => {
            if (currentIndex > 0 && currentIndex <= questionIds.length - 1) {
                const idToSet = questionIds[currentIndex - 1];
                handleChangeQuestion(idToSet);
                handleScroll();
            }
        }

        return (
            <Container variant="buttons">
                {currentIndex > 0 && (
                    <Button
                        label={language.previous}
                        onClick={handlePrevClick}
                        className={classes.button}
                        startIcon={<PrevIcon />}
                    />
                )}
                {currentIndex === renderOptions().length - 1
                    ? (
                        <Button
                            label={language.submit}
                            onClick={handleSubmit}
                            disabled={isDisabled}
                            className={classes.button}
                        />
                    ) : (
                        <Button
                            label={language.next}
                            onClick={handleNextClick}
                            className={classes.buttonNext}
                            endIcon={<NextIcon />}
                        />
                    )}
            </Container>
        )
    }

    const handleCloseSurvey = () => {
        history.push(getWelcomePath(program.name))
    }

    const handleChange = (newValue: Value) => {
        const updatedValues = answerValues.map(answer => {
            if (answer.questionId === currentQuestionId) {
                return {
                    ...answer,
                    value: newValue,
                    inputDate: new Date().toUTCString()
                }
            }
            return answer
        })

        dispatch(setAnswerValues({ answerValues: updatedValues, collectId: collect }))

        if (newValue && newValue.toString().length > 0) {
            dispatch(addAnsweredQuestionId({
                questionId: currentQuestionId!,
                collectId: collect
            }))
        } else {
            dispatch(removeAnsweredQuestionId({
                questionId: currentQuestionId!,
                collectId: collect
            }))
        }
    }

    const { answerType, answers, label, title } = questions[currentIndex]

    const selectedId = renderOptions()[currentIndex].id

    const renderAnswerValue = () => {
        return answerValues?.find(answer =>
            answer.questionId === currentQuestionId)?.value
    }

    const handleDone = () => {
        setShowCompletion(false)
        handleChangeQuestion(undefined)
        history.push(getWelcomePath(program.name))
    }

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false)
    }

    return (
        errorDetails.message 
        ? <ErrorComponent message={errorDetails.message} />
        :
        <Offline content={survey}>
                {showCompletion
                    ? <SuccessPage onDone={handleDone} message={message} />
                    : (
                        <>
                            <Header menuOptions={renderOptions()}
                                selectedId={selectedId}
                                onChange={handleChangeQuestion}
                                onClose={handleCloseSurvey} />
                            <Container>
                                <Container variant="content">
                                    <Question title={title} question={label} />
                                    <Answers answers={answers}
                                        type={answerType}
                                        onChange={handleChange}
                                        initialValue={renderAnswerValue()}
                                    />
                                </Container>
                                {renderButtonContent()}
                            </Container >
                        </>
                    )
                }
                <Snackbar open={openSnackbar}
                    message={message}
                    onClose={handleCloseSnackbar} />
                {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
            </Offline >
    )
}

export default Survey
