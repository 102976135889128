import {
    HeaderContainer, IconButton,
    Roulette, Title, Option
} from 'components'
import React from 'react'
import {
    Close as CloseIcon
} from "@material-ui/icons";
import useStyles from "./Header_Styles"
import { useSmallScreen } from "hooks"
import { useSelector } from "react-redux";
import { selectLanguageObject } from "pages/Login/LoginSlice";

export interface HeaderProps {
    menuOptions: Array<Option>
    onClose: () => void
    selectedId: string
    onChange: (id: string) => void
    isSection?: boolean
}

const Header: React.FC<HeaderProps> = (props) => {

    const classes = useStyles()

    const { menuOptions, onClose, selectedId, onChange } = props

    const selectedIndex = menuOptions.findIndex(option => option.id === selectedId)!

    const isSmallScreen = useSmallScreen()
    
    const language = useSelector(selectLanguageObject)
    const counterTitle = `${language.section} ${selectedIndex + 1} / ${menuOptions.length}`
    const length = 13
    const visibleLength = 15

    const titleToDisplay = (isSmallScreen && counterTitle.length > length)
        ? `${counterTitle.substring(8, visibleLength)}`
        : counterTitle

    return (
        <HeaderContainer className={classes.header}>
            <Roulette
                    options={menuOptions}
                    selectedId={selectedId}
                    onChange={onChange}
            />
            <div className={classes.counter}>
                <Title
                    title={titleToDisplay}
                    className={classes.index}
                    isSection
                />
            </div>
            <IconButton onClick={onClose}>
                <div aria-label={language.previous}>
                <CloseIcon />
                </div>
            </IconButton>
        </HeaderContainer>
    )
}

export default Header
