import { Grid, Typography } from "@material-ui/core";
import {
  ErrorComponent,
  Container,
  CourseItemButton,
  Loader,
  Offline,
  Title,
  Snackbar,
  HeaderContainer,
  Icon
} from "components";
import { useToken } from "cookies";
import { useServerErrorHandler } from "hooks";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import { selectLanguage, selectLanguageObject, selectLoggedUser } from "pages/Login/LoginSlice";
import React from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { AppThunkDispatch } from "redux/store";
import { getCoursePath, appTutorial, getSettingsPath, getWelcomePath  } from "routes";
import { Details, ErrorDetails } from "types";
import useStyles from "./AppTutorials_Styles";
import { fetchMyCourses, selectError,
  selectIsLoading,
  selectMyCourses } from "pages/MyCourses/MyCourses.slice";
import { handleErrorCodes } from "utils/httpErrorCodes";
import { useProgram } from '../../program'
import cross from "resources/icons/cross.svg";


export interface Course extends Details {
  type: "item";
}

const AppTutorials: React.FC<{}> = () => {
  const program = useProgram();
  const classes = useStyles();
  const history = useHistory();

  const dispatch = useDispatch<AppThunkDispatch>();
  const loggedUser = useSelector(selectLoggedUser);
  const { token } = useToken();
  const lang = useSelector(selectLanguage)
  const language = useSelector(selectLanguageObject);
  const handleServerError = useServerErrorHandler();
  const courses = useSelector(selectMyCourses);
  const isLoading = useSelector(selectIsLoading);
  const error = useSelector(selectError);
  const isOnline = useSelector(selectOnlineState)
  const [snackbarState, setSnackbarState] = React.useState(true)
  const [errorDetails, setErrorDetails] = React.useState({} as ErrorDetails)

  const handleCloseSnackbarSlowConnection = () => {
    setSnackbarState(false)
  }
  React.useEffect(() => {
    if (isOnline && loggedUser) {
      handleServerError(dispatch(fetchMyCourses({ token })));
    }
  }, [dispatch, handleServerError, isOnline, loggedUser, token]);

 React.useEffect(() => {
    if(error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, language.connectionError))
    }
  }, [error, language.connectionError]);

  const hasCourses = courses.filter((course) => course.slug.startsWith(appTutorial)).length > 0
  let found = false;

  // Comment out the following line to display the App Tutorials in ALL languages
  if (hasCourses)
  {
    courses.forEach(element => {
      if (element.slug.startsWith(appTutorial) && element.slug.endsWith(lang.toLowerCase()))
      {
        history.push(getCoursePath(program.name, element.slug))
        found = true;
      }
    });
    if (!found)
    {
      // if language not found, default to the default Program language
      const defaultSlug = courses.find(element => element.slug.startsWith(appTutorial) && element.slug.endsWith(program.configs.defaultLanguage.toLowerCase()));
      if (defaultSlug) {
        history.push(getCoursePath(program.name, defaultSlug.slug))
      }
    }

    // if all fails, display tutorials in the language they're in
  }

  if (isOnline && isLoading) {
    return <Loader />;
  }

  const handleCourseClick = (slug: string) => {
      history.push(getCoursePath(program.name, slug))
  };


  const handleClose = () => {
    history.push(getWelcomePath(program.name));
  }

  const renderNoCourseContent = () => {
    if (loggedUser) {
      const welcomeTitle = `${language.hello} ${loggedUser.firstName}, ${language.congratulations}`
      return (

        <Container variant="content" className={classes.welcomeContainer}>
          <Title title={welcomeTitle} className={classes.title} />
          <Typography className={classes.welcomeMessage}>{language.welcomeMessage}</Typography>
        </Container>

      )
    }
  }

  if (isOnline && isLoading) return <Loader/>
  
  return (
    errorDetails.message 
    ? <ErrorComponent message={errorDetails.message} path={getSettingsPath(program.name)} />
    :
    <Offline content={courses}>
        <HeaderContainer>
        <Title title={language.appTutorial} />
        <Icon onClick={handleClose} imageUrl={cross} />
      </HeaderContainer>
      <Grid container className={classes.container}>
        {(!hasCourses)
          ? renderNoCourseContent()
          : (
            <>
              {hasCourses
                ? courses.map((course) => (
                  course.slug.startsWith(appTutorial) ? <CourseItemButton
                    key={course.id}
                    {...course}
                    onClick={() => handleCourseClick(course.slug)}
                  />
                  : null) )
                : null
              }
            </>
          )
        }
      </Grid>
      {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
    </Offline>
  );
};

export default AppTutorials;
