import { TextField, Typography } from '@material-ui/core'
import { FC, useEffect, useState} from 'react'
import { Dispatch, SetStateAction } from "react";
import { UserProfile } from 'types/userProfile';
import useStyles from './ProfileEdit.styles';
import { selectLanguageObject } from 'pages/Login/LoginSlice'
import { useSelector } from 'react-redux';
import { Speech } from 'components';


export interface InputProps {
    profileData : UserProfile,
    setProfileData: Dispatch<SetStateAction<UserProfile>>,
    validateField: (key: string, val: string) => void,
    phoneError: boolean,
    formErrors: any,
    phonePrefix: string,
    size: "small" | "medium" | undefined,
    className: string,
    variant: "filled" | "outlined" | "standard" | undefined,
    label: string,


}


const PhoneNumberInput: FC<InputProps> = (props: InputProps) => {
    const { 
        profileData,
        setProfileData,
        validateField,
        phoneError,
        formErrors,
        phonePrefix,
        size,
        className,
        variant,
    } = props
    const [phoneNumber, setPhoneNumber] = useState(profileData.phoneNumber)
    const classes = useStyles()
    const language = useSelector(selectLanguageObject)


    useEffect(() => {
        validateField('phoneNumber', phoneNumber)
        setProfileData(prev => ({...prev, phoneNumber: phoneNumber}))
        
    }, [phoneNumber, setProfileData, validateField])

    useEffect(() => {
        // phone prefix without the +
        const phonePrefixWithoutPlus = phonePrefix.replace('+', '')
        let phone
        // If there is a phone prefix (ex : 33), we need to remove it
        if (phoneNumber.startsWith(phonePrefixWithoutPlus)) {
            phone = phoneNumber.replace(phonePrefixWithoutPlus, '')
            setPhoneNumber(phone)
        // If there is no phone prefix but a +, we need to remove it
        } else if (phoneNumber.startsWith('+')) {
            phone = phoneNumber.replace('+', '')
            setPhoneNumber(phone)
        }
        // If there is a phone prefix we need to remove it
        else if (phoneNumber.startsWith(phonePrefix)) {
            phone = phoneNumber.replace(phonePrefix, '')
            setPhoneNumber(phone)
        }
    }, [phoneNumber, phonePrefix])


    return (
        <div className={classes.inputContainer}>
        <Speech text={language.phone + ' '  + phonePrefix + ' ' + phoneNumber}>
            <div className={classes.inputLabel}>
                {language.phone}
            </div>
        </Speech>

        
        <TextField 
        name="phoneNumber" 
        type='string'
        value={phoneNumber} 
        onChange={(e) => setPhoneNumber(e.target.value)} 
        error={phoneError}
        size={size}
        variant={variant}
        className={className}
        InputProps={{
            startAdornment: (
                <Typography className={classes.inputAdornment}>
                    {phonePrefix}
                </Typography>
            ),
        }}
/>
        {formErrors.phoneNumber ?
         <div className={classes.inputErrorContainer}>
              <div className={classes.inputError}>
            {formErrors.phoneNumber} 
            </div>
                </div>
 : null}
        </div>

    )
}

export default PhoneNumberInput