import { makeStyles, Theme, createStyles } from '@material-ui/core'


export default makeStyles((theme: Theme) => {
    return createStyles({
        headerContainer: {
            display: "flex",
            alignItems: "center"
        },
        titleContainer: {
            display: "flex",
            alignItems: "center",
            margin: "auto"
        },
        icon: {
            "&:hover": {
                cursor: "pointer"
            }
        },
        profileContainer: {
            padding: "15px 30px"
        },
        profileHeader: {
            display: "flex",
            justifyContent: "center"
        },
        avatarContainer: {
            marginRight: "15px"
        },
        avatar: {
            height: "5.35rem",
            width: "5.45rem",
            borderWidth: "4px",
            borderStyle: "solid",
            borderColor: theme.palette.primary.light
        },
        sectionContainer: {
            padding: "15px 0",
            textAlign: "left"
        },
        sectionTitleContainer: {
            marginTop: "5px"
        },
        sectionTitle: {
            fontFamily: theme.typography.fontFamily,
            fontSize: ".95rem",
            color: theme.palette.primary.main,
            fontWeight: 600,
            lineHeight: "20px",
            '&::first-letter': {
                textTransform: "capitalize"
            }
        },
        profileForm: {
            height: "unset",
            padding: "unset",
            background: "none",
            margin: "15px 0"
        },
        inputContainer: {
            width: "100%",
            margin: "5px 0"
        },
        inputLabel: {
            flex: 1,
            color: theme.palette.primary.main,
            textAlign: "left",
            lineHeight: "20px",
            '&::first-letter': {
                textTransform: "capitalize"
            }
        },
        inputAdornment: {
            marginRight: "8px",
            color: theme.palette.primary.main,
            fontSize: "15px"
        },
        input: {
            width: "100%",
            color: theme.palette.primary.main,
            marginTop: "5px",
            fontSize: "15px",
            '& input[type=number]': {
                '-moz-appearance': 'textfield'
            },
            '& input[type=number]::-webkit-outer-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            },
            '& input[type=number]::-webkit-inner-spin-button': {
                '-webkit-appearance': 'none',
                margin: 0
            }
        },
        button: {
            background: theme.palette.primary.light,
            color: theme.palette.primary.main,
            fontSize: "1rem",
            borderRadius: "4px",
            height: "42px"
        },
        editButton: {
            background: theme.palette.primary.light,
            color: theme.palette.primary.main,
            fontSize: "10px",
            height: "22px",
            width: "unset",
            borderRadius: "4px",
            margin: "5px 0 0",
            marginRight:"auto"
        },
        formCol: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start"
        },
        formContainer: {
            display: "flex",
            flexDirection: "column",
            paddingTop: "15px"
        },
        inputGps: {
            flex: 1,
            backgroundColor: theme.palette.secondary.light,
            color: theme.palette.primary.main,
            fontSize: "15px",
            borderRadius: "4px",
            padding: "8px 10px",
            marginBottom: "5px",
            minHeight: "19px"
        },
        error: {
            color: "#dc3545",
            paddingTop: "15px"
        },
        inputErrorContainer: {
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start"
        },
        inputError: {
            color: "#dc3545",
            fontSize: "13px",
            padding: "5px",
            textAlign: "left"
        }

    })
})