import React from "react"
import { IconButton as MUIIconButton, IconButtonProps } from "@material-ui/core"
import clsx from "clsx"
import useStyles from "./IconButton_Styles"

const IconButton: React.FC<IconButtonProps> = (props) => {
    const classes = useStyles()
    return (
        <MUIIconButton {...props}
            disableRipple
            className={clsx(classes.iconButton, props.className)} />
    )
}

export default IconButton