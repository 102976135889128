import {createStyles, makeStyles, Theme} from "@material-ui/core/styles"
import {style} from "../../style"
import {StyleProps} from "types/styles"

export default makeStyles((theme: Theme) => {
  return createStyles({
    button: {
      margin: "1.5rem 0rem",
      color: theme.palette.primary.main,
      display: "flex-start",
      justifyContent: "space-between",
      borderRadius: "0.625rem",
      textAlign: "left",
      textTransform: "capitalize",
      fontFamily: theme.typography.fontFamily,
      fontWeight: 300,
      background: style.palette.white,
      boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
      lineHeight: "unset",
      overflow: "hidden",
      maxWidth: (props: StyleProps) => props.maxWidth,
      width: (props: StyleProps) => props.width,
      height: (props: StyleProps) => props.height
    },
    disabled: {
      background: theme.palette.secondary.light
    },
    speech: {
      textAlign: "center",
      width: "100%",
      maxWidth: "100%"
    },
    smallButton: {
      margin: "0.2rem 0rem",
      background: style.palette.white,
      color: theme.palette.primary.main,
      justifyContent: "space-between",
      fontSize: "0.75rem",
      display: "flex-start",
      left: "auto",
      right: "auto",
      maxWidth: (props: StyleProps) => props.maxWidth,
      width: (props: StyleProps) => props.width,
      height: (props: StyleProps) => props.height
    },
    title: {
      fontWeight: 600,
      boxSizing: "border-box",
      maxHeight: "inherit",
      padding: 0,
      overflow: "hidden",
      marginBottom: "10px",
      fontSize: "16px",
      color: "black"
    },
    description: {
      boxSizing: "border-box",
      maxHeight: "inherit",
      overflow: "hidden",
      color: "black",
      margin: 0,
    },
    divImg: {
      maxWidth: "25%",
      margin: "auto"
    },
    image: {
      height: "6rem",
      width: "100%",
      objectFit: "contain",
      maxWidth: "100%"
    },
    text: {
      width: "75%",
      margin: "0px 8px 0px 8px"
    }
  })
})
