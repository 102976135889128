import React, { useState, useEffect } from "react";
// import { useToken } from "cookies";
import { useHistory } from "react-router-dom";
import { getWelcomePath } from "routes";
import { useDispatch, useSelector } from "react-redux";
import { AppThunkDispatch } from "redux/store";
import { selectLanguageObject, setFirstAccess } from "pages/Login/LoginSlice";
// import { sendOptedInState } from '../Profile/ProfileSlice';
// import { checkUserPermission, generateDummyNotification } from "../../utils/registerOptin";
// import { PushNotificationsInfos, UserPermissionAnswer } from '../../types/userProfile';
import { LanguageObject } from "types";
import packageJson from "../../../package.json";
import { Dialog, Divider, Slide, Typography } from "@material-ui/core";
import { TransitionProps } from "@material-ui/core/transitions/transition";
import MobileStepper from "@material-ui/core/MobileStepper";
import { Button, Icon, Text } from "components";
import clsx from "clsx";
import { useSmallScreen } from "hooks";
import useStyles from "./SplashPage.styles";
import splashCourses from "resources/icons/splashCourses.svg";
import splashImpact from "resources/icons/splashImpact.svg";
import longPress from "resources/icons/longPress.svg";
import press from "resources/icons/press.svg";
import splashProfile from "resources/icons/splashProfile.svg";
import scroll from "resources/icons/scroll.svg";
import pinch from "resources/icons/Pinch.svg"
import splashTransactions from "resources/icons/splashTransactions.svg";
// import splashWelcome from "resources/icons/splashLogo.svg"
import { useProgram } from '../../program'
import { fillTemplateString } from '../../utils/utils'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement<any, any> },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="right" ref={ref} {...props} />;
});
interface SplashItem {
  icon: string;
  text: string;
}

interface IconObject {
  courses: string,
  transactions: string,
  impact: string,
  profile: string,
  welcome: string,
  //another: string
}

const iconObj: IconObject = {
  'courses': splashCourses,
  'transactions': splashTransactions,
  'impact': splashImpact,
  'profile': splashProfile,
  'welcome': '',
  //'another': another,
}

//Quick action guide when you start the app
const SplashPage: React.FunctionComponent<{}> = () => {
  const program = useProgram();
  //  const { token } = useToken();
  const history = useHistory();
  const language:any = useSelector(selectLanguageObject);
  const dispatch = useDispatch<AppThunkDispatch>();

  const [activeStep, setActiveStep] = useState(0);
  const [showInfo, setShowInfo] = useState(false);

  const isSmallScreen = useSmallScreen();
  const classes = useStyles();

  const splashWelcome = ( program.name ? program.configs.welcomeSplashIcon : 'resources/icons/splashLogo.svg' )
  iconObj.welcome = splashWelcome;

  useEffect(() => {
    setTimeout(() => {
      setShowInfo(true)
    }, 700);
  }, [])

  const splashArray: Array<SplashItem> = program.configs?.splashScreens.map(
    (splash) => ({
      icon: iconObj[splash.img as keyof IconObject],
      text: fillTemplateString(language[splash.key as keyof LanguageObject], {programName: program.name})
    })
  );

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    if (activeStep !== 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  // const handleOptedIn = (optIn: PushNotificationsInfos) =>
  //   dispatch(sendOptedInState(token, optIn))

  // const getUserPermission = async () => {
  //   const userPermission: UserPermissionAnswer = await checkUserPermission()
  //   if(!userPermission.alreadyAnswered && !!userPermission.success) {

  //     if(userPermission.denied) {
  //       const optInValues = {
  //         optedIn: 0,
  //         pushInfos: null
  //       }
  //       handleOptedIn(optInValues)

  //     } else if(userPermission.success && !!userPermission.pushNotificationsInfos) {

  //       handleOptedIn(userPermission.pushNotificationsInfos)

  //       setTimeout(() => {
  //         const title = fillTemplateString(language.dummyNotificationTitle, {programName: program.name}),
  //               body = language.dummyNotificationBody
  //         generateDummyNotification(title, body)
  //       }, 5000)
  //     }
  //   }
  // }

  const handleCloseSplash = () => {
    dispatch(setFirstAccess(false));
    // getUserPermission()
    history.push(getWelcomePath(program.name));
  };

  const renderLastPage = () => {
    const renderItem = (imageUrl: string, title: string) => {
      return (
        <>
          <Icon imageUrl={imageUrl}
            className={isSmallScreen
              ? classes.smallScreenlastPageIcon
              : classes.lastPageIcon} />
          <Text>{title}</Text>
        </>
      );
    };
    const options = [
      {
        imageUrl: press,
        title: language.press,
      },
      {
        imageUrl: longPress,
        title: language.longPress,
      },
      {
        imageUrl: scroll,
        title: language.scroll,
      },
      {
        imageUrl:pinch,
        title: language.pinch
      }
    ];
    return (
      <div className={isSmallScreen
        ? classes.smallLastPageIconContainer
        : classes.lastPageIconContainer}>
        {options.map(({ imageUrl, title }, index) => (
          <React.Fragment key={index}>
            {renderItem(imageUrl, title)}
          </React.Fragment>
        ))}
        <Button
          label={language.ok}
          onClick={handleCloseSplash}
          className={classes.button}
        />
      </div>
    );
  };

  const handleOkClick = () => {
    setShowInfo(false);
  };

  const maxSteps = splashArray.length + 1;

  return (
    <div className={
      isSmallScreen
        ? clsx(classes.splashContainer, classes.smallSplashContainer)
        : classes.splashContainer}>
      {activeStep === splashArray.length ? (
        renderLastPage()
      ) : (
          <>
            <div className={isSmallScreen
               ? classes.smallItemContainer : classes.itemContainer} key={activeStep}>
              <Icon
                imageUrl={splashArray[activeStep].icon}
                className={isSmallScreen ? classes.smallIcon : classes.icon}
              />
              <Text className={isSmallScreen ? classes.text : undefined}>
                {splashArray[activeStep].text}
              </Text>
            </div>
            <MobileStepper
              className={isSmallScreen
                ? classes.smallStepper
                : classes.stepper}
              variant="dots"
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={<div />}
              backButton={<div />}
              classes={{
                dot: classes.dot,
                dotActive: classes.dotActive,
              }}
            />
            <div className={isSmallScreen
                ? classes.smallClickableArea
                : classes.clickableArea}>
              <div onClick={handleBack} className={isSmallScreen
                ? classes.smallLeftContainer
                : classes.leftContainer}></div>
              <div onClick={handleNext} className={isSmallScreen
                ? classes.smallLeftContainer
                : classes.leftContainer}></div>
            </div>
            <Typography className={classes.copyright}>SUTTI™ Participant App v{packageJson.version}.</Typography><Typography className={classes.copyright}>Copyright © KSAPA 2019-{new Date().getFullYear()}. All rights reserved.</Typography>
          </>
        )}
      <Dialog open={showInfo}
        onClose={handleOkClick}
        fullScreen
        TransitionComponent={Transition}
        classes={{
          paperFullScreen: classes.dialog
        }}>
        <div className={classes.infoScreen}>
          <Typography className={isSmallScreen
            ? clsx(classes.leftText, classes.smallLeftText)
            : classes.leftText}>
            {language.tapPrev}
          </Typography>
          <Divider orientation="vertical" className={classes.divider} />
          <Typography className={isSmallScreen
            ? clsx(classes.rightText, classes.smallRightText)
            : classes.rightText}>
            {language.tapNext}
          </Typography>
        </div>
        <Button
          label={language.ok}
          onClick={handleOkClick}
          className={clsx(classes.button, classes.lightButton)}
        />
      </Dialog>
    </div>
  );
};

export default SplashPage;
