import React from 'react'
import logo from "./../../resources/icons/sutti.svg";
import {Container, HeaderContainer, Title, Icon, Text, Link} from "components";
import {selectLanguageObject} from "pages/Login/LoginSlice";
import {useSelector} from "react-redux";
import { useHistory } from "react-router-dom";
import { getSettingsPath, getWelcomePath } from "routes";
import useStyles from "./Copyright_Styles";
import back from "resources/icons/back.svg";
import cross from "resources/icons/cross.svg";
import licenses from "../../licenses.json";
import { useSmallScreen } from "hooks";
import { Divider } from "@material-ui/core";
import { useProgram } from '../../program'

//Copyright page
const CopyrightPage: React.FunctionComponent<{}> = (props) => {
    const program = useProgram();
    const language = useSelector(selectLanguageObject);
    const history = useHistory();
    const classes = useStyles();
    const isSmallScreen = useSmallScreen();

    const handleBack= () => {
        history.push(getSettingsPath(program.name));
      }

    const handleLicenseClick = (url: string) => {
       window.open(url)
      }

      const handlequit = () => {
        history.push(getWelcomePath(program.name));
      }

    const renderLicences = (licences: any) => {
        let res = [];
        for (let key of Object.keys(licences)) {
            if (licences.hasOwnProperty(key)) {
               const text =  key + " - " + language.licence + ": " + licences[key].licenses;
               const Licenselink = licences[key].licenseUrl;
               const Repositorylink = licences[key].repository;
               res.push( <Text className={classes.content}>{text}</Text>);
               res.push( <Link className={classes.link} onClick={() => handleLicenseClick(Repositorylink)}>{language.homePage}</Link>)
               res.push( <Link className={classes.link} onClick={() => handleLicenseClick(Licenselink)}>{language.copyrightNotice}</Link>)
            }
        }
        return res;
    }

 return (
  <div>
        <HeaderContainer className={classes.headContainer}>
            <Icon onClick={handleBack} className={classes.icon} imageUrl={back} />
            <Title title={language.copyrights} />
            <Icon onClick={handlequit} className={classes.icon} imageUrl={cross} />
        </HeaderContainer>
        <Container variant="contentWithFixedHeader" className={classes.root}>
            <Icon imageUrl={logo} className={isSmallScreen ? classes.smallLogo : classes.logo} />

            <Text className={classes.content}>{language.copyrightNote}</Text>
            <Divider className={classes.divider} variant="middle"></Divider>
            <Text className={classes.content}>{language.copyrightThirdParty}</Text>
            {
                renderLicences(licenses)
            }
        </Container>
        </div>
);

}

export default CopyrightPage;
