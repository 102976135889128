import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {

    return createStyles({
        iconButton: {
            background: "none",
            padding: 0,
            "&:hover": {
                background: "none"
            }
        }
    })
})