import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import { style } from "style";
import { StyleProps } from "types";

export default makeStyles((theme: Theme) => {
    const titleColor = style.palette.white

    return createStyles({
        root: {
            "&:hover $button": {
                cursor: "pointer"
            },
            boxShadow: "1px 1px 2px 2px grey",
            borderRadius: "10px"
        },
        button: {
            maxWidth: (props: StyleProps)=>props.maxWidth,
            width: (props: StyleProps)=>props.width,
            height: "9rem",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",

        },
        smallButton: {
            maxWidth: (props: StyleProps)=>props.maxWidth,
            width: (props: StyleProps)=>props.width,
            height: "6rem",
            borderRadius: "20px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            position: "relative",
        },
        addButton: {
            color: theme.palette.secondary.main
        },
        icon:{
            fontSize: "3.125rem"
        },
        title: {
            color: titleColor,
            background: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
            textAlign: "center",
            "&:hover": {
                cursor: "pointer"
            },
            position: "absolute",
            bottom: "15px",
            left: "15px",
        },
        smallTitle: {
            color: titleColor,
            background: theme.palette.primary.main,
            fontFamily: theme.typography.fontFamily,
            fontWeight: 700,
            textAlign: "center",
            "&:hover": {
                cursor: "pointer"
            },
            position: "absolute",
            bottom: "10px",
            left: "15px"
        },
        container:{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            maxWidth: (props)=>props.maxWidth,
            boxShadow: "3",
        },
        image: {
            background: "none",
            borderRadius: "8px",
            height: "100%",
            width: "100%",
            objectFit: "cover"
        },
        text: {
            fontSize: "3rem",
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main
        }
    })
})