
import { FC, useState, useEffect, useCallback } from 'react'
import { useToken } from 'cookies'
import { useProgram } from '../../program'
import { useServerErrorHandler } from 'hooks'
import { RouteChildrenProps, useHistory } from 'react-router-dom'
import { 
  getTermsAndConditionsAgreementPath, 
  getTermsAndConditionsPath 
} from 'routes'
import { AppState } from 'redux/rootReducer'
import { AppThunkDispatch } from 'redux/store'
import { useDispatch, useSelector } from 'react-redux'
import { selectOnlineState } from 'pages/Impact/Impact.slice'
import { selectLanguageObject } from 'pages/Login/LoginSlice'
import {
  fetchChapterById,
  selectChapterById, selectCurrentSectionId,
  selectSection,
  selectSectionIds,
  selectError,
  selectIsLoading,
  setChapterCompleted, setCurrentSection, setSuccessSnackbarShown
} from 'pages/MyTermsAndConditions/MyTermsAndConditionsSlice'
import { handleErrorCodes } from 'utils/httpErrorCodes'
import { ErrorDetails } from "types/common";
import {
  Button,
  Container,
  ErrorComponent,
  Header,
  Loader,
  Offline, Option, ParsedContent,
  Snackbar, Speech
} from 'components'
import {
  KeyboardArrowLeftOutlined as PrevIcon,
  KeyboardArrowRightOutlined as NextIcon
} from '@material-ui/icons'
import useStyles from './Agreement_Styles'

interface ChapterParams {
  id: string
}
interface ChapterProps extends RouteChildrenProps<ChapterParams> {
  courseSlug: string
  chapterId: string
}

const Agreement: FC<ChapterProps> = (props) => {
  const { match, courseSlug, chapterId } = props
  if (!match) {
    throw new Error('Undefined match')
  }

  const classes = useStyles()
  const program = useProgram()
  const { token } = useToken()
  const history = useHistory()
  const handleServerError = useServerErrorHandler()

  const dispatch = useDispatch<AppThunkDispatch>()
  const language = useSelector(selectLanguageObject)
  const isOnline = useSelector(selectOnlineState)
  const isLoading = useSelector(selectIsLoading)
  const error = useSelector(selectError)

  const sectionId = match.params.id
  const section = useSelector((state: AppState) =>
    selectSection(state, courseSlug, chapterId)
  )
  const currentSectionId = useSelector((state: AppState) =>
    selectCurrentSectionId(state, courseSlug, chapterId)
  )
  const sectionIds = useSelector((state: AppState) =>
    selectSectionIds(state, courseSlug, chapterId)
  )
  const chapter = useSelector((state: AppState) =>
    selectChapterById(state, courseSlug, chapterId)
  )
  const { allMediaDownloaded, successSnackbarShown } = chapter

  const sectionTitle = section ? section.title : ''

  const [errorDetails, setErrorDetails] = useState({} as ErrorDetails)
  const [snackbarMessage, setSnackbarMessage] = useState('')
  const [snackbarState, setSnackbarState] = useState(true)

  const handleCloseSnackbarSlowConnection = () => {
    setSnackbarState(false)
  }

  const fetchChapter = useCallback(async () => {
    if (!currentSectionId || !allMediaDownloaded) {
      const res = (await handleServerError(
        dispatch(
          fetchChapterById({ id: chapterId, courseSlug, programId: program.id, token })
        )
      )) as any

      if (res.meta.requestStatus === 'fulfilled') {
        const sections = res.payload.sections
        const sectionId: string = sections.length > 0
          ? res.payload.sections[0].id
          : undefined
        const sectionIdToUpdate = currentSectionId ? currentSectionId : sectionId
        dispatch(
          setCurrentSection({
            courseSlug,
            chapterId,
            id: sectionIdToUpdate,
          })
        )
        history.push(getTermsAndConditionsAgreementPath(program.name, courseSlug, chapterId, sectionIdToUpdate))
        return
      }
    }
  }, [
    program,
    chapterId,
    courseSlug,
    dispatch,
    history,
    token,
    handleServerError,
    currentSectionId,
    allMediaDownloaded
  ])

  useEffect(() => {
    if (isOnline) {
      fetchChapter()
    }
  }, [fetchChapter, isOnline])

  useEffect(() => {
    if (!successSnackbarShown) {
      const getSnackbarMessage = () => {
        if (isOnline && allMediaDownloaded) {
          return language.downloadSuccessMessage
        }

        if (isOnline && !allMediaDownloaded) {
          return language.errorWhileDownload
        }

        if (!isOnline && !allMediaDownloaded) {
          return language.downloadFailedMessage
        }

        return ''
      }

      setSnackbarMessage(getSnackbarMessage())
    }
  }, [allMediaDownloaded, language, successSnackbarShown,
    dispatch, courseSlug, chapterId, isOnline])

 useEffect(() => {
    if(error && error.httpStatus !== 503) {
      setErrorDetails(handleErrorCodes(error, language.connectionError))
    }
  }, [error, language.connectionError])

  if (isOnline && sectionIds.length === 0) {
    const textToDisplay = language.noSections.replace('$param', chapter.title)
    return <ErrorComponent message={textToDisplay} isError={false} snackMsg={errorDetails.slowConnexionError ? language.slowConnection : undefined}
      path={getTermsAndConditionsPath(program.name, courseSlug)} />
  }

  const currentIndex = sectionIds
    .findIndex(id => id.toString() === sectionId)

  const handleChangeSection = (id: string) => {
    dispatch(
      setCurrentSection({
        courseSlug,
        chapterId,
        id
      })
    )

    history.push(getTermsAndConditionsAgreementPath(program.name, courseSlug, chapterId, id))
  }

  const handleClose = () => {
    history.push(getTermsAndConditionsPath(program.name, courseSlug))
  }

  const handleCloseChapter = () => {
    dispatch(
      setCurrentSection({
        courseSlug,
        chapterId,
        id: sectionIds[0],
      })
    )

    dispatch(
      setChapterCompleted({
        courseSlug,
        chapterId,
      })
    )

    handleClose()
  }


  const handleScroll = () => {
  document.getElementById('form')!.scrollTo(0,0)
}


  const renderButtonContent = () => {
    const isDisabled = chapter.visitedSections?.length !== sectionIds.length

    const handleNextClick = () => {
      if (currentIndex < sectionIds.length - 1) {
        const idToSet = sectionIds[currentIndex + 1]
        handleChangeSection(idToSet)
        handleScroll()
    }

    }

    const handlePrevClick = () => {
      if (currentIndex > 0 && currentIndex <= sectionIds.length - 1) {
        const idToSet = sectionIds[currentIndex - 1]
        handleChangeSection(idToSet)
        handleScroll()
      }

    }

    if (isOnline && isLoading && !errorDetails.slowConnexionError ) {
      return <Loader />
    }

    return (
      <Container variant='buttons'>
        {currentIndex > 0 && (
          <Button
            label={language.previous}
            onClick={handlePrevClick}
            startIcon={<PrevIcon />}
          />
        )}
        {currentIndex === sectionIds.length - 1
          ? (
            <Button
              label={language.acceptAgreementTerms}
              onClick={handleCloseChapter}
              disabled={isDisabled}
            />
          ) : (
            <Button
              label={language.next}
              onClick={handleNextClick}
              endIcon={<NextIcon />}
            />
          )}
      </Container>
    )
  }

  const renderHeaderContent = () => {
    if (currentSectionId) {
      const renderRouletteOptions = (): Array<Option> => {
        const sections = Object.keys(chapter.sections)
          .map(key => chapter.sections[key])
          .sort((a, b) => a.order - b.order)

        return sections.map(({ id, title }) => {
          const isChecked = chapter.visitedSections?.includes(id.toString())
          return { id, value: title, isChecked }
        })
      }

      return (
        <Header
          menuOptions={renderRouletteOptions()}
          selectedId={currentSectionId}
          onChange={handleChangeSection}
          onClose={handleClose}
        />
      )
    }
  }

  const openSnackbar = snackbarMessage.length > 0

  const handleCloseSnackbar = () => {
    if (!successSnackbarShown && allMediaDownloaded) {
      dispatch(setSuccessSnackbarShown({
        chapterId,
        courseSlug,
        successSnackbarShown: true
      }))
    }
    setSnackbarMessage('')
  }

  return (
    errorDetails.message 
    ? <ErrorComponent message={errorDetails.message} />
    :
    <Offline content={section} path={getTermsAndConditionsPath(program.name, courseSlug)}>
      {renderHeaderContent()}
      <Container className={classes.container}>
        {currentSectionId &&
          <>
            <Container variant='content' className={classes.content}>
              <Speech text={sectionTitle}>
                <h1 className={classes.sectionTitle}>{sectionTitle}</h1>
              </Speech>
              {section!.content
                ? <ParsedContent htmlString={section!.content} />
                : null}
            </Container>
            {renderButtonContent()}
            <Snackbar open={openSnackbar}
              message={snackbarMessage}
              onClose={handleCloseSnackbar} />
            {errorDetails.slowConnexionError ? ( <Snackbar open={snackbarState} message={language.slowConnection} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
          </>
        }
      </Container>
    </Offline >
  )
}

export default Agreement
