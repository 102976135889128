import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        item: {
            display: "flex",
            alignItems: "center",
            width: 320,
            marginLeft: "1.25rem",
            justifyContent: "space-between",
            "& > *": {
                marginRight: "2rem"
            }
        },
        button: {
            margin: "1rem",
            background: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            "&:hover": {
                background: theme.palette.secondary.light,
            }
        },
        content: {
        color: theme.palette.primary.main,
        textAlign: 'left',
        alignItems: 'flex-start',
        paddingTop: 10,
        paddingBottom: '0'
        },
        divider:{
            width: '50%',
            marginTop: 30,
            marginBottom: 20,
            textAlign: 'left',
            alignSelf: 'center',
        },
    })
})