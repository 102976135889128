import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        container: {
            background: theme.palette.secondary.contrastText
        },
        sectionTitle:{
            fontFamily: theme.typography.fontFamily,
            color: theme.palette.primary.main,
            fontSize: "1.6875rem"
        },
        header: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between'
        },
        title: {
            color: theme.palette.primary.main
        },
        index: {
            color: theme.palette.secondary.dark,
            marginRight: '0.625rem'
        },
        counter: {
            display: "flex",
            alignItems: "center"
        },
        content:{
            alignItems: "flex-start",
            paddingTop: 0,
            width: "100%",
            "&>*:last-child": {
                marginTop: 0,
                width: "100%"
            }
        }
    })
})