import { FC, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from "react-redux"
import { useHistory } from "react-router-dom"
import { AppThunkDispatch } from "redux/store"
import { useServerErrorHandler } from "hooks"
import { Close as CloseIcon } from '@material-ui/icons'
import { useToken } from "cookies"
import { getMessagesPath } from 'routes'
import { useProgram } from '../../../program'
import { Notification } from 'types/notifications'
import { selectLanguageObject } from 'pages/Login/LoginSlice'
import { NotificationTypes } from 'constants/notifications'
import { Button, IconButton, MessageBody, Speech } from 'components'
import {
  updateNotification,
  updateMessage
} from "components/Messages/NotificationPopUp"
import useStyles from './NotificationPopUp.styles'
import { style } from '../../../style'
import { useSmallScreen } from 'hooks';

export interface NotificationProps {
  message: Notification
  onClose: () => void
}

const NotificationPopUp: FC<NotificationProps> = (props) => {
  const {
    message,
    onClose
  } = props

  const history = useHistory()
  const {token} = useToken()
  const program = useProgram()
  const isSmallScreen = useSmallScreen()

  const handleServerError = useServerErrorHandler()

  const dispatch = useDispatch<AppThunkDispatch>()

  const language = useSelector(selectLanguageObject)

  const [formattedTitle, setFormattedTitle] = useState('')

  const classes = useStyles()
  

  const formatText = useCallback(() => {
    switch (message.type) {
      case NotificationTypes.priceChange:
        setFormattedTitle(`${language.priceNotificationTitle} ${JSON.parse(message.title).pointOfSale ?? ''}`)
        break
      case NotificationTypes.simpleMessage:
        setFormattedTitle(`${language.newNotificationTitle} ${message.sender} `)
        console.log(message.sender)
        break
      case NotificationTypes.newSession:
          setFormattedTitle(`${language.newSessionTitle} ${message.sender} `)
          console.log(message.sender)
          break
      default:
        break
    }
  }, [message, language])

  const onClick = () => {
    onClose()
    
      const id = message.id,
          body = {
            programId: program.id
          },
          key = "hasBeenRead",
          value = 1
      handleServerError(dispatch(updateNotification({token, id, body})))
      dispatch(updateMessage({id, key, value}))  
    history.push(getMessagesPath(program.name, message))
  }

  useEffect(() => {
    formatText()
  }, [formatText])
  
  return (
    <div className={isSmallScreen ? classes.smallContainer :  classes.container}>
      <div className={classes.closeContainer}>
        <IconButton onClick={onClose}>
            <CloseIcon htmlColor={style.palette.white}/>
        </IconButton>
      </div>

      <div className={classes.textContainer}>
        <div className={classes.headerContainer}>
          <div className={`${classes.titleText}`}>
            <Speech text={formattedTitle}>
              {formattedTitle}
            </Speech>
          </div>
        </div>

        <MessageBody type={message.type} body={message.body}/>
      </div>

      <Button className={classes.button} label={language.seeDetails} onClick={onClick} />
    </div>
  ) 
}

export default NotificationPopUp