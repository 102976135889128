import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
  return createStyles({
    container: {
      padding: "0 1.55rem 0 1.55rem",
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-around',
      paddingBottom: '5rem',
      textAlign: "center"
    },
    welcomeMessage: {
      color: theme.palette.primary.main,
      textAlign: "center"
    },
    welcomeContainer: {
      marginTop: "2rem"
    },
    title: {
      color: theme.palette.primary.main
    }
  })
})