
import { createSlice } from '@reduxjs/toolkit';
import localforage from 'localforage';

const SettingsSlice = createSlice({
  name: 'settingsSlice',
  initialState: {},
  reducers: {
    clearResults() {
      localforage.clear().then(function() {
        // Run this code once the database has been entirely deleted.
        console.log('Database is now empty.');
    }).catch(function(err) {
        // This code runs if there were any errors
        console.log(err);
    });
    },
  },
})

export const { clearResults } = SettingsSlice.actions
