import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
  return createStyles({
    root: {},
    image: {
      background: "none",
      height: "2.188rem",
      width: "2.188rem"
    },
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      maxWidth: "60rem",
    },
    headContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between"
    },
    titleContainer: {
      display: "flex",
      alignItems: "center"
    },
    icon: {
      "&:hover": {
        cursor: "pointer"
      }
    },
    dialogContent: {
      textAlign: "center",
      "&>*:not(:last-child)": {
        marginBottom: "0.625rem",
      },
      "&>*:not(:first-child)": {
        fontFamily: theme.typography.fontFamily,
        fontSize: "0.875rem",
        color: theme.palette.secondary.main
      },
      "&>*:first-child": {
        fontSize: "1.6875rem",
        fontWeight: 700
      }
    },
    icons: {
      marginTop: "1.25rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center"
    }
  })
})
