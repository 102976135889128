import React from "react"

const AddCoursePage:React.FC<{}> = () => {
    return(
        <h1>
            Add Course page
        </h1>
    )
}

export default AddCoursePage