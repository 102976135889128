import {createStyles, makeStyles, Theme} from '@material-ui/core'
import {CSSProperties} from 'react'
import {style} from "../../style";


export default makeStyles((theme: Theme) => {
  const flexContainer: CSSProperties = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  }

  return createStyles({
    splashContainer: {
      background: theme.palette.primary.main,
      height: 'calc(100% - 40px)',
      position: 'relative',
      paddingBottom: "2.5rem",
      ...flexContainer,
      justifyContent: "flex-end"
    },
    smallSplashContainer: {
      background: theme.palette.primary.main,
      height: '140%',
      position: 'relative',
      paddingBottom: "2.5rem",
      justifyContent: "flex-end"
    },
    stepper: {
      height: '1.25rem',
      padding: "4.375rem",
      background: "none",
    },
    dot: {
      marginRight: "0.625rem",
      background: theme.palette.secondary.main
    },
    dotActive: {
      background: theme.palette.secondary.dark
    },
    itemContainer: {
      ...flexContainer,
      justifyContent: "center",
      position: "absolute",
      height: '100%',
      "&>*:not(:last-child)": {
        marginBottom: "0.938rem",
      },
      "&>*": {
        color: theme.palette.secondary.light,
        fontSize: '1.125rem'
      },
    },
    smallItemContainer: {
      ...flexContainer,
      justifyContent: "center",
      position: "absolute",
      height: '70%',
      width:"100vw",
      top: '0',
      left: '0',
      "&>*:not(:last-child)": {
        marginBottom: "0.938rem",
      },
      "&>*": {
        color: theme.palette.secondary.light,
        fontSize: '1.125rem'
            },
    },
    icon: {
      width: '9.875rem',
      height: '10.875rem'
    },
    clickableArea: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      display: 'flex'
    },
    smallClickableArea: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      left: 0,
      display: 'flex'
    },
    leftContainer: {
      height: '100%',
      width: '50%',
      zIndex: 1200,
    },
    smallLeftContainer: {
      height: '100%',
      width: '50%',
      zIndex: 1200,
    },
    lastPageIcon: {
      width: '3.375rem',
      height: '4.313rem'
    },
    smallScreenlastPageIcon: {
      width: '1.375rem',
      height: '2.313rem'
    },
    smallLastPageIconContainer: {
      ...flexContainer,
      justifyContent: "center",
      top: 0,
      left: 0,
      height: '80%'
    },
    lastPageIconContainer: {
      ...flexContainer,
      justifyContent: "center",
      height: '100%',
      "&>*:not(:last-child)": {
        marginBottom: "0.938rem",
      }
    },
    copyright: {
      color: theme.palette.secondary.main,
      justifyContent: "center",
      textAlign: "center",
      alignItems: "center",
      fontSize: "0.75rem"
    },
    divider: {
      width: 0,
      height: "100%",
      margin: "auto",
      border: "dashed 3px",
      color: theme.palette.secondary.main,
    },
    infoScreen: {
      position: 'absolute',
      height: '100%',
      width: '100%',
      top: 0,
      background: theme.palette.secondary.main,
      opacity: 0.9,
      alignItems: "center",
      zIndex: theme.zIndex.snackbar
    },
    leftText: {
      color: "white",
      fontWeight: 600,
      textAlign: "center",
      position: "absolute",
      height: "3.125rem",
      width: "4.375rem",
      left: "3.375rem",
      top: "calc(50% - 50px / 2)"
    },
    rightText: {
      color: "white",
      fontWeight: 600,
      textAlign: "center",
      position: "absolute",
      height: "3.125rem",
      width: "4.375rem",
      right: "3.125rem",
      top: "calc(50% - 50px / 2)"
    },
    smallRightText: {
      right: "1.125rem"
    },
    smallLeftText: {
      left: "1.125rem"
    },
    button: {
      background: style.palette.mainDegraded(theme.palette.primary.main),
      width: "9.25rem",
      marginTop: "1rem",
      marginBottom: "1rem"
    },
    lightButton: {
      zIndex: theme.zIndex.snackbar,
      background: style.palette.lightDegraded(theme.palette.secondary.light),
    },
    dialog: {
      justifyContent: "flex-end",
      paddingBottom: "6.25rem",
      height: "100vh",
      opacity: 0.8
    },
    text: {
      minWidth: "unset"
    },
    smallIcon: {
      width: "5.875rem",
      height: "3.875rem"
    },
    smallStepper: {
      height: '1.25rem',
      padding: "0",
      background: "none",
    }
  })
})
