const containerPadding = '20px'
const headerPadding = '13px'
const headerHeight = '10vh'


export const style = {
  palette: {
    white: "#FFFFFF",
    black: "#000000",
    mainDegraded: (color: string) => `linear-gradient(180deg, rgba(73, 108, 107, 0.61) 8.8%, rgba(73, 108, 107, 0.31) 97.24%),
          radial-gradient(67.32% 67.32% at 52.78% 46.36%, rgba(73, 106, 108, 0.51) 0%, ${color})`,
    lightDegraded: (color: string) => `linear-gradient(180deg, rgba(202, 202, 202, 0.61) 8.8%, rgba(73, 108, 107, 0.31) 97.24%),
            radial-gradient(67.32% 67.32% at 52.78% 46.36%, rgba(202, 202, 202, 0.51) 0%, ${color})`
  },
  size: {
    containerPadding,
    containerWithFixedHeaderHeight: "100vh",
    headerHeight,
    headerPadding,
  }
}
