import {AnyAction, combineReducers, Reducer} from '@reduxjs/toolkit'
import ProgramsReducer from 'program/ProgramsSlice'
import impactReducer from "pages/Impact/Impact.slice"
import myCoursesReducer from "pages/MyCourses/MyCourses.slice"
import myTermsAndConditionsReducer from "pages/MyTermsAndConditions/MyTermsAndConditionsSlice"
import NotificationSliceReducer from "components/Messages/NotificationPopUp/NotificationPopUp.slice"
import servicesReducer from "pages/Services/Services.slice"
import transactionsReducer from "pages/Transactions/Transactions.slice"
import userReducer from "pages/Login/LoginSlice"
import userProfileReducer from "pages/Profile/ProfileSlice"
import localForage from "localforage";

//this page handle all the reducer and create the Store

const appReducer = combineReducers({
  programs: ProgramsReducer,
  myCourses: myCoursesReducer,
  myTermsAndConditions: myTermsAndConditionsReducer,
  impact: impactReducer,
  transactions: transactionsReducer,
  user: userReducer,
  userProfile: userProfileReducer,
  services: servicesReducer,
  messages: NotificationSliceReducer,
  notifications: NotificationSliceReducer
})

const rootReducer: Reducer = (state: AppState, action: AnyAction) => {
  if (action.type === 'Settings/clearResults') {
    // this applies to all keys defined in persistConfig(s)
    localForage.removeItem('persist:root')
    state = {} as AppState
  }
  return appReducer(state, action)
}

export type AppState = ReturnType<typeof appReducer>
export default rootReducer
