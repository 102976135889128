import Roulette, { Option } from 'components/Roulette'
import { useSmallScreen } from 'hooks'
import React from 'react'
import clsx from "clsx"
import useStyles from "./Select_Styles"

export interface SelectProps {
    menuOptions: Array<Option>
    selectedId?: string
    onChange: (id: string) => void
}

const Select: React.FC<SelectProps> = (props) => {
    const classes = useStyles()
    const { menuOptions, selectedId, onChange } = props
    const isSmallScreen = useSmallScreen()

    return (
        <Roulette
            showValue
            options={menuOptions}
            selectedId={selectedId}
            onChange={onChange}
            className={isSmallScreen
                ? clsx(classes.paper, classes.smallPaper)
                : classes.paper}
        />
    )
}

export default Select
