import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit';
import {putService} from 'api';
import {ErrorType} from 'types';
import {handleError} from '../../utils/httpErrorCodes'
import {FarmData } from "types/farm";

interface UpdateParams {
  token: string,
  farmData: FarmData
}

interface FarmDataState {
  farmData: FarmData | null,
  loading: boolean,
  error: ErrorType | null
}

const initialState: FarmDataState = {
  farmData: null,
  loading: false,
  error: null
}

export const updateFarmData: AsyncThunk<Notification, UpdateParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'farm/updateFarmData',
  async ({token, farmData}: UpdateParams, {rejectWithValue}) => {
    try {
      return await putService(token, `farm/update`, farmData)
    } catch (error: any) {
      return rejectWithValue(handleError(error))
    }
  }
)

const farmDataSlice = createSlice({
  name: 'farmData',
  initialState,
  reducers: {
  },
  extraReducers: {
    [updateFarmData.pending.type]: (state, _) => {
      state.loading = true
    },
    [updateFarmData.fulfilled.type]: (state, {payload}: PayloadAction<any>) => {
      state.loading = false
      state.error = null
    },
    [updateFarmData.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.loading = false
      state.error = payload
    }
  }
})

export default farmDataSlice.reducer