import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"

const SettingsSVG : React.FC<SVGPropsInterface> = ({width, height, stroke, fill}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg aria-hidden="true" width={width ? width : defaultTheme.width} height={height ? height : defaultTheme.height} viewBox="0 20 140 100" xmlns="http://www.w3.org/2000/svg">
<circle cx="34.125" cy="78" r="12.125" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : "none"} stroke-width="5"/>
<circle cx="78" cy="78" r="12.125" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : "none"} stroke-width="5"/>
<circle cx="121.875" cy="78" r="12.125" stroke={stroke ? stroke : defaultTheme.stroke} fill={fill ? fill : "none"} strokeWidth="5"/>
</svg>
  )
}

export default SettingsSVG
