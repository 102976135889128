import {createStyles, makeStyles, Theme} from "@material-ui/core/styles";
import {style} from "../../../style"
import {StyleProps} from "types/styles";

export default makeStyles((theme: Theme) => {
  
  const buttonPadding = "20px"
  const avatarWidth = "5vw"
  const avatarMaxWidth = "10rem"
  const senderWidth = "5vw"
  const senderMaxWidth = "90px"
  const dateWidth = "15vw"
  const dateMaxWidth = "90px"
  const padding = "10px"
  
  return createStyles({
    container: {
      justifyContent: "center",
      width: (props: StyleProps) => props.width,
      maxWidth: (props: StyleProps) => props.maxWidth,
    },
    button: {
      fontFamily: theme.typography.fontFamily,
      fontWeight: 300,
      fontSize: "1rem",
      textAlign: "left",
      lineHeight: "unset",
      color: theme.palette.secondary.main,
      background: style.palette.white,
      borderRadius: 0,
      boxShadow: "none",
      width: (props: StyleProps) => props.width,
      maxWidth: (props: StyleProps) => props.maxWidth,
      padding: `${buttonPadding} 0`,
    },
    smallButton: {
      fontSize: "4vw",
      background: style.palette.white,
      color: theme.palette.primary.main,
      width: (props: StyleProps) => props.width,
      maxWidth: (props: StyleProps) => props.maxWidth,
      padding: 0,
    },
    message: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "start",
    },
    sender: {
      width: senderWidth,
      padding: padding,
    },
    avatar: {
      fontSize: `calc(${avatarWidth} / 2)`,
      height: avatarWidth,
      maxHeight: avatarMaxWidth,
      width: avatarWidth,
      maxWidth: avatarMaxWidth,
      margin: "auto 0",
    },
    text: {
      display: "flex",
      flexDirection: "column",
      width: (props: StyleProps) => `calc(${props.width} - (${senderWidth} + ${dateWidth} + 4 * ${padding}))`,
      maxWidth: (props: StyleProps) => `calc(${props.maxWidth} - (${senderMaxWidth} + ${dateMaxWidth} + 4 * ${padding}))`,
    },
    title: {
      fontWeight: 600,
      color: style.palette.black,
      textTransform: "capitalize",
      padding: 0,
    },
    description: {
      color: style.palette.black,
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
      textTransform: "none",
      margin: 0,
    },
    bold: {
      fontWeight: "bold",
    },
    date: {
      textAlign: "right",
      width: dateWidth,
      maxWidth: dateMaxWidth,
      padding: padding,
    }
  })
})
