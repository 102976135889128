import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            height: "2rem",
            width: "2rem"
        },
        icon: {
            height: "100%",
            width: "100%"
        }
    })
})