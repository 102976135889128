import { InputAdornment, TextField, Typography } from "@material-ui/core";
import { Button, Dialog, Icon, Link, Speech } from "components";
import { useToken } from "cookies";
import { useQuery, useServerErrorHandler, useSmallScreen } from "hooks";
import { selectOnlineState } from "pages/Impact/Impact.slice";
import React, { useState, useMemo } from 'react'
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { AppThunkDispatch } from "redux/store";
import userIcon from "resources/icons/user.svg";
import { getSplashPath, getMyTermsAndConditionsPath, getWelcomePath } from "routes";
import { ErrorType, User } from "types";
import { fetchUser, selectFirstAccess, selectLanguageObject } from './LoginSlice'
import useStyles, { useOutlineStyles } from "./Login_Styles";
import Select from "./Select";
import { useProgram } from '../../program'
import { fillTemplateString } from '../../utils/utils'
import suttiLogo from '../../resources/icons/suttiLogo192.png'

const loginRouteParam = "participant-code";
interface LocationState {
  from: string;
}

interface LoginLocationState extends LocationState {
  reason: "expired" | "notLogged";
}

const Login: React.FC<{}> = () => {
  const program = useProgram()
  const logo = program.configs?.logoSmall
  const { setToken } = useToken();
  const classes = useStyles();
  const outlineClasses = useOutlineStyles();
  const handleServerError = useServerErrorHandler();
  const dispatch = useDispatch<AppThunkDispatch>();
  const query = useQuery();
  const [value, setValue] = useState<string>(query.get(loginRouteParam) || "");
  const history = useHistory<LoginLocationState | undefined>();
  const locationState = history.location.state
  const language: any = useSelector(selectLanguageObject);
  const firstAccess = useSelector(selectFirstAccess);
  const isOnline = useSelector(selectOnlineState)
  const [openForgotDialog, setOpenForgotDialog] = React.useState(false);
  const [openEnrollDialog, setOpenEnrollDialog] = React.useState(false);
  const [openCookieDialog, setOpenCookieDialog] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>(
    undefined
  );
  const [isTouched, setIsTouched] = useState(false);
  const isSmallScreen = useSmallScreen()

  const programForgotNumber = useMemo(() => fillTemplateString(language.forgotNumber, {programName: program.name})
    , [language, program])
  const programEnrollment = useMemo(() => fillTemplateString(language.enrollment, {programName: program.name})
    , [language, program])


  const handleClick = async () => {
    if (value.length === 0) {
      setErrorMessage(language.enterParticipantCode);
      return;
    }

    if (!isOnline) {
      setErrorMessage(language.internetConnection)
      return;
    }

    const res = await handleServerError(dispatch(fetchUser({programId: program.id, code: value})));
    if (res.meta.requestStatus === "fulfilled") {
      setToken((res.payload as User).token);
      const { from } = locationState || { from: getWelcomePath(program.name)};
      history.push(firstAccess ? getSplashPath(program.name) : from);
      return;
    }

    const httpStatus = (res.payload as ErrorType).httpStatus;
    if (httpStatus === 503) {
      setErrorMessage(language.serverUnavailable);
    } else if (httpStatus === 500) {
      setErrorMessage(language.connectionError)
    } else {
      const payloadMessage = (res.payload as ErrorType).payload.message
      if (payloadMessage.includes("Participant code not found")) {
        setErrorMessage(language.unknownUser);
      }
      else
        setErrorMessage((res.payload as ErrorType).payload.message);//
    }
    return;
  };

  const handleChange = (e: any) => {
    setValue(e.target.value);
  };

  const handleKeyDown = async (event: React.KeyboardEvent) => {
    if (event.key === "Enter") {
      await handleClick();
    }
  };

  const handleBlur = (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    setErrorMessage(undefined);
    setIsTouched(true);
  };

  const handleClose = () => {
    setOpenEnrollDialog(false);
    setOpenForgotDialog(false);
    setOpenCookieDialog(false);
  };

  const handleTermsAndConditionsOpen = () => {
    history.push(getMyTermsAndConditionsPath(program.name))
  }

  const handleForgotClick = () => setOpenForgotDialog(true);

  const handleEnrollClick = () => setOpenEnrollDialog(true);

  const handleCookie = () => {
  if(navigator.cookieEnabled) {
    return
  }
  else {
    setOpenCookieDialog(true);
  }
}
  const renderDialogCookie = () => {
    return (
      <>
        <Speech text={language.cookieText}>
          <Typography className={classes.info}>{language.cookieText}</Typography>
        </Speech>
      </>
    );
  };


  const renderDialogContent = () => {
    const url = openForgotDialog
      ? program.configs?.loginScreen?.forgotNumberUrl
      : program.configs?.loginScreen?.enrollmentUrl

    const intro = openForgotDialog ? programForgotNumber : programEnrollment
    const message = openForgotDialog
      ? fillTemplateString(language.contactForForgotNumber, {programName: program.name})
      : fillTemplateString(language.contactForEnroll, {programName: program.name})

    const clickOnLink = language.clickOnLink
    return (
      <>
        <Speech text={language.hello}>
          <Typography className={classes.dialogHeader}>{language.hello}</Typography>
        </Speech>
        <Speech text={intro}>
          <Typography className={classes.info}>{intro}</Typography>
        </Speech>
        <Speech text={message}>
          <Typography className={classes.info}>{message}</Typography>
        </Speech>
        <Speech text={clickOnLink}>
          <Typography className={classes.info}>{clickOnLink}</Typography>
        </Speech>
        <Button
          label={language.getHelp}
          onClick={() => { window.open(url); handleClose() }}
          className={classes.dialogButton}
        />
      </>
    );
  };

  return (
    <React.Fragment>
      <div className={isSmallScreen ? classes.smallScreenRoot : classes.root} >
        <div className={classes.select} >
        <Select />
        </div>
        <div>
        <img src={logo} alt={program.name + "program"} className={isSmallScreen ? classes.smallLogo : classes.logo} />
        <Speech text={program.name}>
          <Typography className={classes.title}>{(program.name).toUpperCase()}</Typography>
        </Speech>
        </div>
        <div className={isSmallScreen
          ? classes.smallContainer
          : classes.container}>
          <TextField
            variant="outlined"
            placeholder={language.memberNumber}
            fullWidth
            value={value}
            onChange={handleChange}
            onClick={handleCookie}
            error={isTouched && errorMessage !== undefined}
            onKeyDown={handleKeyDown}
            onBlur={handleBlur}
            InputProps={{
              classes: outlineClasses,
              startAdornment: (
                <InputAdornment position="start">
                  <Icon imageUrl={userIcon} />
                </InputAdornment>
              ),
            }}
            className={classes.textfield}
          />
          <div className={classes.flex}>
              <Link className={isSmallScreen ? classes.smallLink : classes.link} onClick={handleForgotClick}>{programForgotNumber}</Link>
              |
              <Link className={isSmallScreen ? classes.smallLink : classes.link} onClick={handleEnrollClick}>{programEnrollment}</Link>
          </div>
          <Button
          label={language.login}
          onClick={handleClick}
          className={isSmallScreen
            ? classes.smallButton
            : classes.button}
        />
        </div>
         {errorMessage ? 
          <Speech text={errorMessage}>
          <Typography className={classes.error}>{errorMessage}</Typography>
        </Speech> : null}
        <div className={isSmallScreen
          ? classes.smallContainer
          : classes.container}>
          <Speech text={language.termsAndConditionsConsent}>
            <Typography className={classes.termsAndConditionsConsent}>{language.termsAndConditionsConsent}</Typography>
          </Speech>
          <Link className={classes.smallLink} onClick={handleTermsAndConditionsOpen}>{language.more}</Link>
          </div>
          <img src={suttiLogo} alt={program.name + "program"} className={classes.suttiLogo} />

        
      </div>
      <Dialog
        open={openForgotDialog || openEnrollDialog}
        onClose={handleClose}
        className={isSmallScreen ? classes.smallScreenDialog : undefined}>
        {renderDialogContent()}
      </Dialog>
      <Dialog
        open={openCookieDialog}
        onClose={handleClose}
        className={isSmallScreen ? classes.smallScreenDialog : undefined}>
        {renderDialogCookie()}
      </Dialog>
      
    </React.Fragment>
  );
};

export default Login;
