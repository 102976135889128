import { createStyles, makeStyles, Theme } from "@material-ui/core"

export default makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            marginRight: 0,
            marginLeft: 0,
            justifyContent: "space-between",
            color: theme.palette.secondary.contrastText,
            width: "100%"
        },
        checkedIcon:{
            color: `${theme.palette.primary.light} !important`
        },
        unCheckedIcon: {
            color: theme.palette.secondary.contrastText
        }
    })
})