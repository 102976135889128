import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {
    return createStyles({
        root: {
            display: "flex",
            alignItems: "center"
        },
        icon: {
            width: "3rem",
            height: "1.8rem",
            marginRight: 10
        },
        paper: {
            boxShadow: "none",
            background: "transparent",
        },
        menuItem: {
            padding: 0,
            marginTop: 15,
            minHeight: 30
        },
        selectIcon: {
            width: "auto",
            height: "auto"
        },
        iconButton: {
            marginTop: -5
        }
    })
})