import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";

export default makeStyles((theme: Theme) => {

    return createStyles({
        link: {
            fontFamily: theme.typography.fontFamily,
            fontSize: "1rem",
            "&:hover": {
                textDecoration: "none",
                cursor: "pointer"
            }
        },
        smallLink: {
            fontFamily: theme.typography.fontFamily,
            fontSize: "0.75rem",
            "&:hover": {
                textDecoration: "none",
                cursor: "pointer"
            }
        }
    })
})