import {AsyncThunk, createAsyncThunk, createSlice, PayloadAction} from '@reduxjs/toolkit'
import {getService} from 'api'
import {AppState} from 'redux/rootReducer'
import {CourseDefinition, Details, ErrorType, Media, MyTermsAndConditions, Section} from 'types'
import {handleError} from '../../utils/httpErrorCodes'

interface MyCoursesParams {
  userId: string | null
  programId: string | null
  token: string | null
}

export const fetchMyTermsAndConditions: AsyncThunk<MyTermsAndConditions,
  MyCoursesParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myTermsAndConditions/fetchMyTermsAndCOnditions',
  async ({userId, programId, token}: MyCoursesParams, {rejectWithValue}) => {
    try {
      const url = `library/${programId}/termsAndConditions/termsAndConditions?user-id=${userId}`
      return await getService(token, url)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)


interface FetchCourseBySlugParams {
  courseSlug: string
  programId: string
  token: string
}

export const fetchCourseBySlug: AsyncThunk<CourseDefinition,
  FetchCourseBySlugParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myTermsAndConditions/fetchCourseBySlug',
  async ({courseSlug, programId, token}: FetchCourseBySlugParams, {rejectWithValue}) => {
    try {
      const url = `library/${programId}/termsAndConditions/getCourseStructure?course-slug=${courseSlug}`
      return await getService(token, url)
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

export interface ChapterThunkState {
  id: string
  courseSlug: string
  sections: Array<Section>
  allMediaDownloaded: boolean
}

export interface ChapterParams {
  id: string
  courseSlug: string
  programId: string
  token: string
}

interface MediaResult {
  status: number
  blob: Blob
}

const getMedia = async (url: string): Promise<MediaResult | undefined> => {
  try {
    const res = await fetch(url)
    const blob = await res.blob()
    return {
      status: res.status,
      blob
    }
  } catch (err) {
    console.log(err)
  }
  
}

export const fetchChapterById: AsyncThunk<ChapterThunkState,
  ChapterParams, { rejectValue: ErrorType }> = createAsyncThunk(
  'myTermsAndConditions/fetchChapterById',
  async ({id, courseSlug, programId, token}: ChapterParams, {rejectWithValue}) => {
    try {
      const sectionsUrl = `library/${programId}/termsAndConditions/getChapterSections?chapter-id=${id}`
      const fetchedSections: Array<Section> = await getService(token, sectionsUrl)
      const mediaUrl = `library/${programId}/termsAndConditions/getChapterMedias?chapter-id=${id}`
      const fetchedMedias: Array<Media> = await getService(token, mediaUrl)
      
      const downloadedMedias = await Promise.all(fetchedMedias.map(media => {
        return getMedia(media.mediaUrl)
      }))
      
      const filteredMedia = downloadedMedias.filter(isDefined)
      
      const allMediaDownloaded = filteredMedia.length === fetchedMedias.length
      
      return {
        id,
        courseSlug,
        sections: fetchedSections,
        allMediaDownloaded
      }
    } catch (err) {
      return rejectWithValue(handleError(err))
    }
  }
)

interface ChapterState extends Details {
  currentSection?: string
  visitedSections?: Array<string>
  completed?: boolean
  allMediaDownloaded: boolean
  successSnackbarShown: boolean
  sections: {
    [id: string]: Section
  }
}

interface CourseState extends Details {
  summary: string
  accessed: boolean
  shownCompletionDialog?: boolean
  chapters?: {
    [id: string]: ChapterState
  }
}

interface MyCoursesState {
  bySlug: {
    [slug: string]: CourseState
  },
  isLoading: boolean
  error: ErrorType | null
}

const initialState: MyCoursesState = {
  bySlug: {},
  isLoading: false,
  error: null
}

interface CurrentSection {
  id: string
  courseSlug: string
  chapterId: string
}

interface SuccessSnackbarShownPayload {
  courseSlug: string
  chapterId: string
  successSnackbarShown: boolean
}

const myTermsAndConditionsSlice = createSlice({
  name: 'myTermsAndConditions',
  initialState,
  reducers: {
    setAccessed(state, {payload}: PayloadAction<string>) {
      state.bySlug[payload].accessed = true
    },
    setCurrentSection(state, {payload}: PayloadAction<CurrentSection>) {
      const {id, courseSlug, chapterId} = payload
      const getVisitedSections = () => {
        const chapterToUpdate = state.bySlug[courseSlug].chapters![chapterId]
        const visitedSections = chapterToUpdate.visitedSections || []
        if (id) {
          const hasId = visitedSections.includes(id.toString())
          return hasId
            ? visitedSections
            : visitedSections.concat(id.toString())
        }
        
        return visitedSections
      }
      
      state.bySlug[courseSlug].chapters![chapterId] = {
        ...state.bySlug[courseSlug].chapters![chapterId],
        currentSection: id,
        visitedSections: getVisitedSections()
      }
    },
    setChapterCompleted(state, {payload}: PayloadAction<Omit<CurrentSection, "id">>) {
      const {courseSlug, chapterId} = payload
      state.bySlug[courseSlug].chapters![chapterId] = {
        ...state.bySlug[courseSlug].chapters![chapterId],
        completed: true
      }
    },
    setCourseCompletion(state, {payload}: PayloadAction<string>) {
      state.bySlug[payload].shownCompletionDialog = true
    },
    setSuccessSnackbarShown(state, {payload}: PayloadAction<SuccessSnackbarShownPayload>) {
      const {chapterId, courseSlug, successSnackbarShown} = payload
      state.bySlug[courseSlug].chapters![chapterId].successSnackbarShown = successSnackbarShown
    }
  },
  extraReducers: {
    [fetchMyTermsAndConditions.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchMyTermsAndConditions.fulfilled.type]: (state, action: PayloadAction<MyTermsAndConditions>) => {
      state.bySlug = action.payload.reduce((acc, course) => {
        return {
          ...acc,
          [course.slug]: {
            ...state.bySlug[course.slug],
            ...course
          }
        }
      }, {})
      state.isLoading = false
      state.error = null
    },
    [fetchMyTermsAndConditions.rejected.type]: (state, {payload}: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = payload
    },
    [fetchCourseBySlug.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchCourseBySlug.fulfilled.type]: (state, {payload}: PayloadAction<CourseDefinition>) => {
      state.isLoading = false
      const chapters = Array.isArray(payload.chapters) ? payload.chapters : []
      const hasChapters = state.bySlug[payload.slug].hasOwnProperty('chapters')
      state.bySlug[payload.slug] = {
        ...state.bySlug[payload.slug],
        chapters: chapters.reduce((acc, chapter) => {
          if (hasChapters) {
            const currentChapter = state.bySlug[payload.slug].chapters![chapter.id]
            return currentChapter !== undefined
              ? {
                ...acc,
                [chapter.id]: currentChapter
              }
              : {
                ...acc,
                [chapter.id]: chapter
              }
          }
          
          return {
            ...acc,
            [chapter.id]: chapter
          }
        }, {})
      }
      state.error = null
    },
    [fetchCourseBySlug.rejected.type]: (state, action: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = action.payload
    },
    [fetchChapterById.pending.type]: (state, _) => {
      state.isLoading = true
    },
    [fetchChapterById.fulfilled.type]: (state, {payload}: PayloadAction<ChapterThunkState>) => {
      const {courseSlug, id, sections, allMediaDownloaded} = payload
      state.isLoading = false
      state.error = null
      state.bySlug[courseSlug].chapters![id] = {
        ...state.bySlug[courseSlug].chapters![id],
        allMediaDownloaded,
        sections: sections.reduce((acc, section) => {
          return {
            ...acc,
            [section.id]: section
          }
        }, {})
      }
    },
    [fetchChapterById.rejected.type]: (state, action: PayloadAction<ErrorType>) => {
      state.isLoading = false
      state.error = action.payload
    }
  }
})

export const {
  setAccessed, setCurrentSection,
  setChapterCompleted, setCourseCompletion,
  setSuccessSnackbarShown
} = myTermsAndConditionsSlice.actions

export default myTermsAndConditionsSlice.reducer

const selectCourseSlugs = (state: AppState) => {
  return Object.keys(state.myTermsAndConditions.bySlug)
}

export const selectCourseBySlug = (state: AppState, courseSlug: string): CourseState => {
  return state.myTermsAndConditions.bySlug[courseSlug]
}

export const isDefined = <TValue>(value: TValue | null | undefined): value is TValue => {
  return value !== null && value !== undefined;
}

export const selectMyCourses = (state: AppState): Array<CourseState> => {
  return selectCourseSlugs(state)
    .map(courseSlug => selectCourseBySlug(state, courseSlug))
    .filter(isDefined)
}

export const selectIsLoading = (state: AppState): boolean => {
  return state.myTermsAndConditions.isLoading
}

export const selectError = (state: AppState): ErrorType | null => {
  const {error} = state.myTermsAndConditions
  return error
}

export const selectChapterById = (state: AppState, courseSlug: string, chapterId: string): ChapterState => {
  return selectCourseBySlug(state, courseSlug).chapters![chapterId]
}

export const selectSections = (state: AppState, courseSlug: string, chapterId: string) => {
  const sections = selectChapterById(state, courseSlug, chapterId).sections
  return sections
    ? Object.keys(sections).map(key => sections[key]).sort((a, b) => a.order - b.order)
    : []
}

export const selectCurrentSectionId = (
  state: AppState,
  courseSlug: string,
  chapterId: string): string | undefined => {
  return selectChapterById(state, courseSlug, chapterId).currentSection
}

export const selectSection = (state: AppState,
                              courseSlug: string,
                              chapterId: string): Section | undefined => {
  const currentSectionId = selectCurrentSectionId(state, courseSlug, chapterId)
  const sections = selectSections(state, courseSlug, chapterId)
  if (currentSectionId) {
    return sections.find(section => section.id.toString() === currentSectionId.toString())!
  }
  return;
}

export const selectSectionIds = (state: AppState, courseSlug: string, chapterId: string) => {
  const sections = selectSections(state, courseSlug, chapterId)
  return sections.map(section => section.id)
}



