import { SVGPropsInterface } from "./SVG"
import { SVGStyles } from "./SVG"

const Add2HomeSVG : React.FC<SVGPropsInterface> = ({width, height, stroke, fill}) => {
  // Retrieve the default theme
  const defaultTheme = SVGStyles()
  return (
    <svg  aria-hidden="true" viewBox="0 0 48 48"  height = {height ? height : defaultTheme.height} width={width ? width : defaultTheme.width} xmlns="http://www.w3.org/2000/svg">
      <g id="12-add,-new,-plus,-square" fill="none" fill-rule="evenodd">
        <path fill={stroke ? stroke : defaultTheme.stroke} id="Shape" d="m37 6c2.6887547 0 5 2.17929398 5 5v26c0 2.6887547-2.1694226 5-5 5h-26c-2.6887547 0-5-2.2121036-5-5v-26c0-2.6887547 2.16932778-5 5-5zm-26 2c-1.59768088 0-3 1.27598443-3 3v26c0 1.5976809 1.28873722 3 3 3h26c1.5976809 0 3-1.2618781 3-3v-26c0-1.59768088-1.2821228-3-3-3zm13 7c.5128358 0 1 .4039088 1 1v7h7c.5522847 0 1 .4477153 1 1 0 .5128358-.4385792 1-1 1h-7v7c0 .5522847-.4477153 1-1 1-.5128358 0-1-.4095355-1-1v-7h-7c-.5522847 0-1-.4477153-1-1 0-.5128358.4256658-1 1-1h7v-7c0-.5522847.4477153-1 1-1z" fill-rule="nonzero"/>
    </g>
    </svg>
  )
}

export default Add2HomeSVG
