import { useState } from "react"
import { useToken } from "cookies"
import { useDispatch, useSelector } from "react-redux"
import { selectLanguageObject } from "../../Login/LoginSlice"
import { AppThunkDispatch } from "redux/store"
import { useHistory } from "react-router-dom"
import {
  getAppTutorialPath,
  getCopyrightPath,
  getMyTermsAndConditionsPath,
  getLoginPath,
  getWelcomePath,
  getSplashPath,
  getProfilePath
} from 'routes'
import { useSmallScreen } from "hooks"
import cross from "resources/icons/cross.svg";
import {
  Copyright as CopyIcon,
  Description as TermsIcon,
  ExitToApp as LogIcon,
  KeyboardArrowRightOutlined as NextIcon,
  PermDeviceInformation as TutoIcon,
  SystemUpdate as UpdateIcon,
  Info as ProgramDescriptionIcon,
  AccountBox as ProfileIcon,
} from "@material-ui/icons"
//import SystemUpdateIcon from '@mui/icons-material/SystemUpdate'
import useStyles from "./Settings.styles"
import { 
    Button, 
    Container, 
    Popup, 
    HeaderContainer, 
    Icon, 
    Title} from "components"
import { useProgram } from '../../../program'
import { StyleProps } from "types"
import ClearCache from "./ClearCache"
import Select from "pages/Login/Select"
import PWAinstall from "components/PWAInstall/PWAinstall"

export interface SettingsbProps {
  style?: StyleProps
}


// TODO: This page will have more option to it later
const Settings: React.FC<SettingsbProps> = (props) => {
  const {
    style = {
      width:'60vw',
      maxWidth:'30rem',
      height:'100%'
    }
  } = props

  const program = useProgram()
  const dispatch = useDispatch<AppThunkDispatch>()
  const classes = useStyles(style)
  const isSmallScreen = useSmallScreen()
  const history = useHistory()
  const [open, setOpen] = useState(false)
  const language = useSelector(selectLanguageObject)
  const { removeToken } = useToken()

  const buttonClass = isSmallScreen ? classes.smallButton : classes.button

  const goTo = (url: string) => {
    history.push(url)
  }

  const checkUpdate = () => {
    window.location.reload()
  }

  const popUpLog = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handlequit = () => {
    history.push(getWelcomePath(program.name));
  }

  const logout = () => {
    // remove V1 and V2 cookies
    removeToken()
    history.push(getLoginPath(program.name))
  }

  const selectClass = isSmallScreen ? classes.smallSelect : classes.select

  return (
    <div>
    <HeaderContainer className={classes.headContainer}>

        <Title title={language.settings} />
        <Icon onClick={handlequit} className={classes.icon} imageUrl={cross} />
      </HeaderContainer>
      <Container variant="contentWithFixedHeader">
      <Select className={selectClass} />
      <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.objectives }
                  onClick={ () => goTo(getSplashPath(program.name)) }
                  startIcon={ <ProgramDescriptionIcon /> }
                  className={ buttonClass }
                  endIcon={ <NextIcon /> } />
        </Container>  
        <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.myProfile }
                  onClick={ () => goTo(getProfilePath(program.name)) }
                  startIcon={ <ProfileIcon /> }
                  className={ buttonClass }
                  endIcon={ <NextIcon /> } />
        </Container>
        <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.termsAndConditions }
                  onClick={ () => goTo(getMyTermsAndConditionsPath(program.name)) }
                  startIcon={ <TermsIcon /> }
                  className={ buttonClass }
                  endIcon={ <NextIcon /> } />
        </Container>
        <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.tutorial }
                  onClick={ () => goTo(getAppTutorialPath(program.name)) }
                  startIcon={ <TutoIcon /> }
                  className={ buttonClass }
                  endIcon={ <NextIcon /> } />
        </Container>
        <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.copyrights }
                  onClick={ () => goTo(getCopyrightPath(program.name)) }
                  startIcon={ <CopyIcon /> }
                  className={ buttonClass }
                  endIcon={ <NextIcon /> } />
        </Container>
        <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.update }
                  onClick={ checkUpdate }
                  startIcon={ <UpdateIcon /> }
                  className={ buttonClass }
                  endIcon={ <NextIcon /> } />
        </Container>
        <Container variant="buttons" style={ {  margin: "0.5rem 0" } }>
          <Button label={ language.logout }
                  onClick={ popUpLog }
                  startIcon={ <LogIcon /> }
                  endIcon={ <NextIcon /> }
                  className={ buttonClass } />
        </Container>
        <PWAinstall variant="button"/>
        <Popup title={ language.logoutDialog }
               buttonLabel={ language.logout }
               explanationLabel={ language.logoutWarning }
               open={ open }
               onClose={ handleClose }
               onSubmit={ logout }
               extraActionLabel={ language.clearCache }
               onSubmitWithExtraAction={() => ClearCache(dispatch)}
               />
      </Container>
    </div>
  )
}

export default Settings
