import { Close as CloseIcon } from "@material-ui/icons";
import { Container, ErrorText, IconButton, Snackbar } from 'components';
import { useSmallScreen } from "hooks";
import React from 'react'
import { useHistory } from "react-router-dom";
import notFoundImage from "resources/icons/notFound.svg";
import { getWelcomePath } from 'routes'
import useStyles from "./ErrorComponent_Styles";
import { useProgram } from '../../program'

interface ErrorComponentProps {
    message: string
    isError?: boolean
    path?: string
    snackMsg?: string
}
//Error component that redirect to courses
const ErrorComponent: React.FC<ErrorComponentProps> = (props) => {
    const program = useProgram()
    const { message, isError = true, path=getWelcomePath(program.name), snackMsg = "" } = props
    const history = useHistory()
    const classes = useStyles()
    const isSmallScreen = useSmallScreen()
    const [snackbarState, setSnackbarState] = React.useState(true)

    const handleCloseSnackbarSlowConnection = () => {
      setSnackbarState(false)
    }

    const displaySlowConnectionWarn = (snackMsg.length > 0);

    const handleClick = () => {
        history.push(path)
    }

    return (
        <Container variant={"content"}>
            <IconButton className={classes.button} onClick={handleClick}>
                <CloseIcon fontSize={"large"} />
            </IconButton>
            {isError && <img src={notFoundImage}
                alt={"errorImage"}
                className={isSmallScreen
                    ? classes.smallScreenImage
                    : classes.image} />}
            <ErrorText text={message + ": " + window.location.pathname} className={classes.text} />
            {displaySlowConnectionWarn ? ( <Snackbar open={snackbarState} message={snackMsg} onClose={handleCloseSnackbarSlowConnection}  /> ) : null }
        </Container>
    )
}

export default ErrorComponent
