import { Typography } from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import clsx from "clsx";
import { Speech, Container } from "components";
import React from "react";
import { useState, useEffect } from "react";
import useStyles from "./CourseItemButton_Styles";
import placeholderSrc from "../../resources/icons/suttiLogo192.png";
import { useSmallScreen } from 'hooks';
import { StyleProps } from "types";
export interface CourseItemButtonProps {
    type?: "course" | "add"
    title: string
    speechText?: string
    onClick: () => void
    imageUrl?: string
    style?: StyleProps
}

//Icon button that represent the courses lead to chapter
const CourseItemButton: React.FC<CourseItemButtonProps> = (props) => {
    const { 
        title, 
        speechText, 
        onClick, 
        imageUrl, 
        type = "course",
        style= {
            maxWidth: "30rem",
            width: "90vw"
        }
    } = props
    const classes = useStyles(style)
    const [imgSrc, setImgSrc] = useState(placeholderSrc || imageUrl);
    const isSmallScreen = useSmallScreen()

    const titleComponent = <Typography className={isSmallScreen ? classes.smallTitle : classes.title}>{title}</Typography>



    useEffect(() => {
        if (imageUrl !== undefined)
        {
        const img = new Image();
        img.src = imageUrl;
        img.onload = () => {
          setImgSrc(imageUrl);
        };
    }
      }, [imageUrl]);

    const renderButtonContent = () => {
        return (
            <Speech text={speechText || title} onClick={onClick}>
                <div className={classes.root}>
                    {type === "add"
                        ? (
                            <div className={clsx(classes.addButton, isSmallScreen ? classes.smallButton : classes.button)}>
                                <AddIcon className={classes.icon} />
                            </div>
                        ) : (
                            <Container  variant="buttons" 
                                        className={isSmallScreen ? classes.smallButton : classes.button}
                                        style={{margin: "0.5rem 0", align: "center"}}>
                                {imageUrl
                                    ? <img src={imgSrc} alt={imgSrc}  aria-hidden="true" className={classes.image} />
                                    : <Typography className={classes.text}>{title.charAt(0).toUpperCase()}</Typography>
                                }
                            {titleComponent}
                            </Container>
                        )
                    }
                </div>
            </Speech >
        )
    }

    return (
        <div className={classes.container}>
            {renderButtonContent()}
        </div >
    )
}

export default CourseItemButton